import qs from 'querystring';
import { v4 as uuidv4 } from 'uuid';

const ORG_ID = process.env['REACT_APP_THREAT_METRIX_ORG_ID'];

export function setupSession(): string {
  const script = document.createElement('script');
  const sessionId = uuidv4();
  const query = {
    org_id: ORG_ID,
    session_id: sessionId,
  };

  script.src = `https://content.koalafi.com/fp/tags.js?${qs.stringify(query)}`;
  document.body.appendChild(script);

  return sessionId;
}
