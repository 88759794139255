import React from 'react';
import Modal, { IconType } from '../modal';

export interface ErrorModalInfo {
  message: string;
  buttonText: string;
  redirectUrl: string;
}

const ErrorModal = (props: { info: ErrorModalInfo | null }) => {
  if (props.info) {
    const { message, buttonText, redirectUrl } = props.info;
    return (
      <Modal
        iconType={IconType.Warning}
        isOpen={true}
        message={message}
        actionBtnName={buttonText}
        action={() => window.location.replace(redirectUrl)}
      />
    );
  } else {
    return null;
  }
};

export default ErrorModal;
