import React, { useState } from 'react';
import { FieldProps } from 'formik';
import './form-toggle.scss';

interface IProps {
  ariaLabel: string;
  checked: boolean;
  readOnly: boolean;
  onClick: () => any;
  id: string;
}

const FormToggle: React.FC<FieldProps & IProps> = ({
  field,
  form,
  ...props
}) => {
  const { ariaLabel, onClick, checked, readOnly, id } = props;
  const [isChecked, setIsChecked] = useState(checked);

  const handleOnClick = () => {
    onClick();
    setIsChecked(!isChecked);
  };

  return (
    <>
      <input
        id={id}
        type="checkbox"
        className="checkbox"
        onClick={() => handleOnClick()}
        checked={isChecked}
        {...field}
        aria-label={ariaLabel}
        readOnly={readOnly}
      />
      <label
        htmlFor="toggle"
        className="switch"
        onClick={() => handleOnClick()}
      />
    </>
  );
};

export default FormToggle;
