export function setupSocureSession(): Promise<string | null> {
  return new Promise((resolve, reject) => {
    // Create script element to load script from Socure CDN.
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://js.dvnfo.com/devicer.min.js`;

    // Execute device session ID initialization when script loads.
    script.onload = () => {
      try {
        // Alex was not sure how best to tell TypeScript about 'devicer' which is loaded
        // into global scope by the dynamically-loaded Socure script.
        // @ts-ignore
        devicer.run(
          {
            // Public 'SDK' key from Socure admin portal for given environment.
            publicKey: process.env['REACT_APP_SOCURE_PUBLIC_KEY'],
            // Socure documentation mentions this field may be deprecated and removed in the
            // future. But, for the time being 'true' must be sent or a device session id
            // will not be generated.
            userConsent: true,
            // Socure recommends only using values from a specific list in their
            // documentation for this field. 'signup' is the closest to this use case from
            // that list.
            context: 'signup',
          },
          function(response: { result: string; sessionId: string }) {
            console.log('socure devicer.run result:', response.result);
            // Normalizing resolved value to null (as opposed to the empty string) if
            // session ID is not established for any reason.
            resolve(
              typeof response.sessionId === 'string' &&
                response.sessionId !== ''
                ? response.sessionId
                : null,
            );
          },
        );
      } catch (e) {
        console.log('socure devicer.run error', e);
        reject(e);
      }
    };

    // Append script to head.
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  });
}
