import { AxiosError, AxiosResponse } from 'axios';
import { ActionMapper, StateMapper } from 'easy-peasy';
import moment from 'moment';
import Numeral from 'numeral';
import React from 'react';
import { ActionsMapperType, Modules, store } from 'store';
import { CustomerFlowType } from 'store/models/flowType';
import { ApplicationStatus } from 'types/applications';
import { CustomerDetails } from 'types/customers';
import { HiProjectType } from 'types/projectType';
import { ErrorCode, instanceOfWcError } from 'utils/errors';
import { identify, track } from 'utils/segment';
import { Role } from '../../store/models/auth';
import { DeclineReason } from '../../types/decision';
import { processEmailValidation } from '../validations/email';
import { isITIN } from 'utils';

export interface ISubmitProps {
  // values
  customer: CustomerDetails;
  // formik functions
  setSubmitting: (isSubmitting: boolean) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string | undefined) => void;

  // react functions
  setErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSoftDeclineWarning: React.Dispatch<React.SetStateAction<boolean>>;
  setTransition: React.Dispatch<
    React.SetStateAction<
      | 'declined'
      | 'approved'
      | 'ineligible'
      | 'bank-information'
      | 'waterfall'
      | 'pending-homeownership'
      | undefined
    >
  >;

  // easy-peasy functions
  globalState: StateMapper<Modules>;
  globalActions: ActionMapper<Modules, ActionsMapperType>;
}

// See ISubmitProps for hints on formik/react/easy-peasy functions
export const handleApplicationSubmit = async (
  props: ISubmitProps,
): Promise<void> => {
  const {
    customer,
    setSubmitting,
    setFieldValue,
    setFieldError,
    setSoftDeclineWarning,
    setTransition,
    globalState,
    globalActions,
  } = props;
  setSubmitting(true);
  track('Application Submitted', {});

  setSoftDeclineWarning(false);

  // validate email input
  const isEmailValid = await processEmailValidation(
    setFieldError,
    customer.email,
  );
  if (!isEmailValid) {
    return;
  }

  // presence of existing applicationId implies application re-submission
  // absence implies new application
  const applicationId = globalState.applications.applicationId;
  const { setCustomer } = globalActions.customers;
  const {
    setApproved,
    setApplicationStatus,
    setApplicationId,
    setDebtFacility,
    setDecisionReason,
  } = globalActions.applications;
  const { setProducts } = globalActions.products;
  const { setCustomerFlow } = globalActions.flowType;

  const requestPayload = generateApplicationRequest(customer, globalState);

  // Detokenize in the UI isntead of the bff because it's used for a segment event.
  // We could detokneize in the bff when we get the customer, but we'd have to do it in the
  // getCustomer, searchCustomer, and getApplication calls, which adds a lot of complexity.
  // Putting the detokenize here exposes the plaintext SSN, but only to authorized users
  // since this page is behind OTP
  if (requestPayload.tokenizedTaxId) {
    const tokenResp = await globalState.auth.client.post('/detokenize-tax-id', {
      tokenizedTaxId: requestPayload.tokenizedTaxId,
    });

    if (!!tokenResp.data?.taxId) {
      requestPayload.ssn = tokenResp.data.taxId;
      delete requestPayload.tokenizedTaxId; // stops the bff from detokenizing this
    }
  }

  if (isITIN(requestPayload.ssn)) {
    track('ITIN Used', {});
  }

  const applicationSubmissionEndpoint = applicationId
    ? `/applications/${applicationId}`
    : '/apply';

  return globalState.auth.client
    .post(applicationSubmissionEndpoint, requestPayload)
    .then((response: AxiosResponse) => {
      const returnedCustomer = {
        ...customer,
        customerId: response.data.customerId,
        lpCustomerId: response.data.lpCustomerId,
        tokenizedTaxId: globalState.customers.customer?.tokenizedTaxId
          ? globalState.customers.customer?.tokenizedTaxId
          : customer.tokenizedTaxId,
      } as CustomerDetails;

      if (globalState.auth.role === Role.Customer) {
        identify(customer);
      }

      switch (response.data.status) {
        case ApplicationStatus.Approved:
          track('Loan Approved', {});
          // setSubmitting(false); // not doing this because the page will transition
          setApplicationId(response.data.applicationId);
          setApproved(response.data.isApproved);
          setDecisionReason(null);
          setProducts(response.data.offeredProducts);
          setCustomer(returnedCustomer); // This setCustomer hook MUST be firing after setApplicationId because of race condition in render check on Landing page
          setDebtFacility(response.data.debtFacility);
          setTransition('approved');
          // setApplicationStatus MUST be firing last to avoid component render race condition for "errors_found" apps
          setApplicationStatus(response.data.status);
          setCustomerFlow(CustomerFlowType.VerifiedCustomer);
          break;
        case ApplicationStatus.Declined:
          track('Loan Denied', {
            additionalProperties: {
              declineReason: response.data.declineReason,
            },
          });
          setCustomerFlow(CustomerFlowType.VerifiedCustomer);

          // setSubmitting(false); // not doing this because all of these cases should
          // transition the page and we want to prevent the button from being clickable

          switch (response.data.declineReason) {
            case DeclineReason.AllowLongAppLease:
              // LTO Waterfall
              processDecline(
                props,
                returnedCustomer,
                response.data.applicationId,
                'declined',
              );
              break;
            default:
              setTransition('declined');
              break;
          }
          break;

        case ApplicationStatus.ErrorsFound:
          track('Loan Soft Declined', {
            additionalProperties: {
              declineReason: response.data.declineReason,
            },
          });

          setApplicationId(response.data.applicationId);
          setSubmitting(false);
          setApplicationStatus(response.data.status);
          setProducts(response.data.offeredProducts);
          setCustomer(returnedCustomer); // This setCustomer hook MUST be firing after setApplicationId because of race condition in render check on Landing page
          setDecisionReason(response.data.declineReason);

          switch (response.data.declineReason) {
            case DeclineReason.IDVSoftDecline:
              // IDV2 decline retries remaining
              track('IDVSoftDecline', {});
              clearSensitiveFields(setFieldValue, setFieldError);
              setSoftDeclineWarning(true);
              setCustomerFlow(CustomerFlowType.SingleCustomer); // only set SingleCustomer for idv soft decline since
              // bav soft declines still have a verified identity
              // Jump to top of form and give focus to SSN/ITIN field which needs to be re-entered.
              // Potential bug: if we are on the wrong screen and somehow get this code, we will do nothing
              document
                .getElementById('personal-details--sub-header-name')
                ?.scrollIntoView();
              document.getElementById('personal-details--ssn')?.focus();
              break;

            case DeclineReason.BAVSoftDeclineInvalid:
              // BAV decline retries remaining
              track('BAVSoftDecline', {});
              setSoftDeclineWarning(true);
              // Jump to top of form and give focus to account number field which needs to be re-entered.
              // Potential bug: if we are on the wrong screen and somehow get this code, we will do nothing

              setFieldValue('accountNumber', '', false);
              setFieldError(
                'accountNumber',
                "We're sorry, but we were unable to validate your bank account. Please revise your banking information and try again.",
              );

              document
                .getElementById('bank-information--sub-header-name')
                ?.scrollIntoView();
              document
                .getElementById('bankInformation--accountNumber')
                ?.focus();
              break;

            case DeclineReason.BAVSoftDeclineSavings:
              // BAV decline retries remaining
              track('BAVSoftDecline', {});
              setSoftDeclineWarning(true);
              // Jump to top of form and give focus to account number field which needs to be re-entered.
              // Potential bug: if we are on the wrong screen and somehow get this code, we will do nothing

              setFieldValue('accountNumber', '', false);
              setFieldError(
                'accountNumber',
                "We're sorry, but the bank account you entered appears to be a savings account. Please revise your checking account information.",
              );

              document
                .getElementById('bank-information--sub-header-name')
                ?.scrollIntoView();
              document
                .getElementById('bankInformation--accountNumber')
                ?.focus();
              break;
            default:
              throw Error(
                `unexpected decline reason status '${response.data.declineReason}'`,
              );
          }
          break;

        case ApplicationStatus.DataRequested:
          switch (response.data.declineReason) {
            case DeclineReason.BAVDataRequested:
              setCustomer(returnedCustomer);
              setApplicationId(response.data.applicationId);
              setTransition('bank-information');
              break;
            case DeclineReason.HomeDataRequested:
              setCustomer(returnedCustomer);
              setApplicationId(response.data.applicationId);
              setTransition('pending-homeownership');
              break;
            default:
              throw Error(
                `unexpected data requested reason '${response.data.declineReason}'`,
              );
          }
          break;

        default:
          setSubmitting(false);
          throw Error(
            `unexpected application status '${response.data.status}'`,
          );
      }
    })
    .catch((error) => {
      handleApplicationError(error, props);
    });
};

// See ISubmitProps for hints on formik/readt/easy-peasy functions
// Note: the customer object here is the input customer, without any of the returned values from
// applying, such as customerId, lpCustomerId
export function handleApplicationError(error: AxiosError, props: ISubmitProps) {
  const {
    customer,
    setSubmitting,
    setFieldValue,
    setFieldError,
    setErrorModalOpen,
    setTransition,
  } = props;

  const globalState = store.getState();

  if (globalState.auth.role === Role.Customer) {
    identify(customer);
  }

  setSubmitting(false);
  const errBody = error.response?.data;
  if (errBody && instanceOfWcError(errBody)) {
    switch (errBody.errorCode) {
      case ErrorCode.Identity:
        clearSensitiveFields(setFieldValue, setFieldError);
        break;
      case ErrorCode.AgentEmail:
        setFieldError('email', 'Email is registered to a servicing agent');
        break;
      case ErrorCode.CustomerNotEligible:
        setTransition('ineligible');
        break;
      case ErrorCode.CustomerOnlyLeaseEligible:
        processDecline(props, customer, '', 'ineligible');
        break;
      default:
        track('Application Error', {});
        console.error(error);
        setErrorModalOpen(true);
    }
  } else {
    track('Application Error', {});
    console.error(error);
    setErrorModalOpen(true);
  }
}

// Clear sensitive fields, prompt to re-enter them, and to recheck other personal information such as after IDV declines.
// Expects formik helper functions as input
function clearSensitiveFields(
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  setFieldError: (field: string, message: string | undefined) => void,
) {
  setFieldValue('ssn', '', false);
  setFieldError('ssn', 'Please, re-enter your SSN');
}

/**
 * Helper function to generate an application request with the form field values
 * TODO: Define an Applications-Service request object
 */
export function generateApplicationRequest(
  customer: CustomerDetails,
  globalState: StateMapper<Modules>,
) {
  const stores = globalState.stores;
  const {
    campaignMedium,
    socureDeviceSessionId,
    source,
    tmxSessionId,
  } = globalState.auth;
  const projectType = globalState.projectType;

  const tmp: { [key: string]: any } = {
    // not using ...customer to be explicit
    firstName: customer.firstName,
    lastName: customer.lastName,
    address1: customer.address1.trim(),
    address2: customer.address2 ? customer.address2.trim() : '',
    city: customer.city,
    state: customer.state,
    zip: customer.zip,
    dateOfBirth: moment.utc(customer.dateOfBirth).format('YYYY-MM-DD'),
    ssn: customer.ssn, // This may be last four in the case of CustomerFlowType.VerifiedCustomer. That is handled below
    mobilePhone: customer.mobilePhone.replace(/\D+/g, ''),
    email: customer.email,
    monthlyIncome: Math.floor(Numeral(customer.monthlyIncome).value()),
    lpCustomerId: customer?.lpCustomerId ? customer?.lpCustomerId : undefined, // replace 0 with undefined
    dealerId: stores.store?.dealerId,
    vertical: stores.store?.vertical,
    subverticals: stores.store?.subverticals,
    merchantName: stores.store?.name,
    consent: true,
    socureDeviceSessionId,
    tmxSessionId,
    label: source,
    accountNumber: customer?.accountNumber, // undefined is acceptable
    routingNumber: customer?.routingNumber, // undefined is acceptable
    hiProjectType: projectType?.projectType,
    // we need to add this in case of ssn/dob change on resubmit. It is still a
    // new customer, but we are using customerId from returned app
    customerId: customer?.customerId ? customer?.customerId : undefined,
    campaignMedium: campaignMedium,
  };

  // We need to fetch full ssn with tokenizedTaxId. This currently only happens
  // if we had to get the customer from CustomerService, which only happens for
  // a returning customer
  if (globalState.flowType.customerFlow === CustomerFlowType.VerifiedCustomer) {
    tmp.tokenizedTaxId = globalState.customers.customer?.tokenizedTaxId;
  }

  return tmp;
}

/**
 * Helper function to determine whether we waterfall or send to decline screen
 */
function processDecline(
  props: ISubmitProps,
  customer: CustomerDetails,
  applicationId: string | '',
  nonWaterfallTransition: 'declined' | 'ineligible',
) {
  const {
    setTransition,
    globalState,
    globalActions,
    setErrorModalOpen,
  } = props;
  const stores = globalState.stores;
  const postTransitionLTO = globalActions.applications.postTransitionLTO;
  const auth = globalState.auth;

  if (
    stores.store?.financingType !== 'loan_only' &&
    (globalState.projectType?.projectType === HiProjectType.NewOrReplace ||
      stores.store?.vertical !== 'home_improvement')
  ) {
    try {
      setTransition('waterfall');
      postTransitionLTO({
        firstName: customer.firstName,
        lastName: customer.lastName,
        address: customer.address1,
        address2: customer.address2 ? customer.address2 : '',
        city: customer.city,
        state: customer.state,
        zip: customer.zip,
        email: customer.email,
        ssn: customer.ssn,
        mobilePhone: customer.mobilePhone.replace(/\D/g, ''),
        monthlyIncome: Math.floor(Numeral(customer.monthlyIncome).value()),
        dateOfBirth: moment.utc(customer.dateOfBirth).format('YYYY-MM-DD'),
        dealerPortalUrl: auth.dealerPortalUrl || '',
        publicStoreId: stores?.store?.publicId || '',
        applicationId: applicationId,
        sessionId: auth.tmxSessionId as string | null,
        socureDeviceSessionId: auth.socureDeviceSessionId as string | null,
        tokenizedTaxId: customer.tokenizedTaxId,
        accountNumber: customer?.accountNumber, // nullable
        routingNumber: customer?.routingNumber, // nullable
      });
    } catch (e) {
      track('Waterfall Error', {});
      console.error(e);
      setTransition(undefined);
      setErrorModalOpen(true);
    }
  } else {
    if (nonWaterfallTransition === 'declined') {
      track('LTONotOfferedDecline', {});
    }
    setTransition(nonWaterfallTransition);
  }
}
