/* eslint-disable @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ApplicationStatus } from 'types/applications';
import { useHistory } from 'react-router';
import { Modal } from 'components/shared';
import { IconType } from 'components/shared/modal';
import './landing.scss';
import { typedState } from 'store';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { ApplicationFlowType } from 'store/models/flowType';
import { OOBA } from 'store/models/auth';

/**
 * The Landing Component handles the following:
 * - Retrieval of information regarding dealers/applications
 * - Retrieval of customer data/eligibility
 * - Redirecting to the appropriate step based on application data
 * - Displaying the application form by default
 */
const Landing = () => {
  const history = useHistory();

  const auth = typedState((state) => state.auth);
  const configs = typedState((state) => state.configs);
  const app = typedState((state) => state.applications);
  const customer = typedState((state) => state.customers);
  const stores = typedState((state) => state.stores);
  const flowType = typedState((state) => state.flowType);

  const queries = qs.parse(history.location.search.slice(1)) as Queries;
  const { dealerId: dealerPublicId } = queries;

  const [generalErrorModalOpen, setGeneralErrorModalOpen] = useState(false);

  // TODO: remove forced lto check after shutdown
  const isLtoForced = process.env['REACT_APP_FORCE_LTO'] !== 'false';
  if (isLtoForced) {
    window.location.href = `https://${auth.dealerPortalUrl}/ApplicationForm/IntroScreen?publicStoreId=${dealerPublicId}&type=lto`;
  }

  // wait for configs become available and redirect to phone verification if
  // phone verification is enabled and there is no verified phone number
  // and there is no appID (resume app)
  useEffect(() => {
    if (
      configs.configs!.phoneVerificationEnabled &&
      !auth.verifiedPhone &&
      stores.store &&
      flowType.applicationFlow !== ApplicationFlowType.Resumed // cannot use flowType.applicationFlow as store update will be behind when this is run
    ) {
      if (stores.store && stores.store?.financingType === 'lto_only') {
        window.location.href = `https://${
          auth.dealerPortalUrl
        }/ApplicationForm/IntroScreen?publicStoreId=${
          stores.store!.publicId
        }&type=lto`;
      } else {
        history.replace(`/verification?${qs.stringify(queries)}`);
      }
    }
  }, [configs.loaded, auth, stores.store]);

  // Resuming app redirect
  useEffect(() => {
    // wait for a potential resumed application before redirecting
    switch (flowType.applicationFlow) {
      // Land from Unified app redirect
      case ApplicationFlowType.New:
        if (customer.customer || auth.verifiedPhone)
          return history.replace(`/apply?${qs.stringify(queries)}`);
        break;
      // Resumed app
      case ApplicationFlowType.Resumed:
        switch (app.applicationStatus) {
          case ApplicationStatus.Approved:
            if (app.homeOwnership !== null) {
              return history.replace(`/approved?${qs.stringify(queries)}`);
            } else {
              return history.replace(
                `/purchased-amount?${qs.stringify(queries)}`,
              );
            }
          case ApplicationStatus.DocsReady:
            // if OOBA is enabled, only allow access to signing page if user has OOBA token,
            //  is a privileged user, or OOBA has already been completed
            if (
              auth.source === OOBA ||
              auth.hasPrivilegedRole ||
              app.acknowledgedBy
            ) {
              return history.replace(
                `/payment-schedule?${qs.stringify(queries)}`,
              );
            } else {
              console.error(
                'only can perform OOBA as customer or privileged user',
              );
              setGeneralErrorModalOpen(true);
            }
            break;
          case ApplicationStatus.ErrorsFound:
            // customer is sourced by applicant which is sourced from the application (from the applicationId query prop)
            // if customer has bank info, then this is a bav soft decline
            if (
              customer?.customer?.accountNumber &&
              customer?.customer?.routingNumber
            ) {
              return history.replace(
                `/bank-information?${qs.stringify(queries)}`,
              );
            } else {
              return history.replace(`/apply?${qs.stringify(queries)}`);
            }
          case ApplicationStatus.DataRequested:
            switch (app.decisionReason) {
              case 'BAVDataRequested':
                return history.replace(
                  `/bank-information?${qs.stringify(queries)}`,
                );
              case 'HomeDataRequested':
                return history.replace(
                  `/pending-homeownership?${qs.stringify(queries)}`,
                );
              default:
                console.error(
                  `Unexpected decision reason: "${app.decisionReason}"`,
                );
                setGeneralErrorModalOpen(true);
            }
            break;
          case ApplicationStatus.Accepted:
            return history.replace(
              `/ready-for-delivery?${qs.stringify(queries)}`,
            );
          case ApplicationStatus.Delivered:
            return history.replace(
              `/ready-for-delivery?${qs.stringify(queries)}`,
            );
          case ApplicationStatus.Funded:
            return history.replace(
              `/ready-for-delivery?${qs.stringify(queries)}`,
            );
          case ApplicationStatus.Expired: // fallthrough is intentional for these statuses
          case ApplicationStatus.Draft:
            window.location.replace(`https://${auth.dealerPortalUrl}`);
            break;
          default:
            // TODO: I didn't test this
            console.error(
              `Unexpected Application Status "${app.applicationStatus}"`,
            );
            setGeneralErrorModalOpen(true);
        }
    }
  }, [
    auth.source,
    auth.hasPrivilegedRole,
    configs.loaded,
    flowType.applicationFlow,
  ]);

  return (
    <>
      <Modal
        isOpen={generalErrorModalOpen}
        message="Something went wrong."
        dismissBtnName="OK"
        dismissAction={() => setGeneralErrorModalOpen(false)}
        iconType={IconType.Warning}
      />
    </>
  );
};

export default Landing;
