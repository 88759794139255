import React from 'react';
import { Field, Form, Formik } from 'formik';
import { FormSelect, FormWrapper } from 'components/shared';
import { Button, Colors } from '@westcreek/react';
import FadeLoadSpinner from 'components/shared/spinner';
import { HIProjectTypeSchema, IHIProjectType } from 'utils/form-schemas';
import { useHistory } from 'react-router-dom';
import { typedActions, typedState } from 'store';
import { getProjectType } from 'utils/select-menus';
import './project-type.scss';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { track } from '../../../utils/segment';
import { HiProjectType } from 'types/projectType';
import { CustomerFlowType } from '../../../store/models/flowType';
import decisionEligibility from '../../../processors/eligibility';

const ProjectType = () => {
  const history = useHistory();

  // global state
  const customers = typedState((state) => state.customers);
  const flowType = typedState((state) => state.flowType);
  const stores = typedState((state) => state.stores);

  // global actions
  const { setProjectType } = typedActions((actions) => actions.projectType);
  const { setProcessWaterfall } = typedActions(
    (actions) => actions.applications,
  );

  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  let subverticals: string[];
  const name = localStorage.getItem('subV');
  if (typeof name === 'string') {
    subverticals = name
      .slice(1, name.length - 2)
      .replaceAll('"', '')
      .split(',');
  }

  return (
    <FormWrapper className={'project-type'}>
      <h2>Project Type</h2>
      <p>What type of project are you financing?</p>
      <Formik
        initialValues={{
          projectType: HiProjectType.Initial,
        }}
        validationSchema={HIProjectTypeSchema}
        onSubmit={(values: IHIProjectType) => {
          track('Project-Type Selected', {
            additionalProperties: {
              projectType: values.projectType,
              changedToLoansOnly: (
                values.projectType !== HiProjectType.NewOrReplace
              ).toString(),
            },
          });
          setProjectType(values.projectType);

          // check the customer flow type and redirect accordingly
          (async () => {
            switch (flowType.customerFlow) {
              case CustomerFlowType.SingleCustomer:
                await decisionEligibility({
                  customerId: customers.customer!.customerId, // it is set by "useCustomerInfo" hook for single customer found
                  dealerId: stores.store!.dealerId,
                }).then(async (eligibilityDecisionResult) => {
                  const {
                    redirectPath,
                    processWaterfall,
                  } = eligibilityDecisionResult;

                  // process waterfall
                  if (processWaterfall)
                    await setProcessWaterfall(processWaterfall);
                  // redirect to new page
                  if (redirectPath != null)
                    history.push(`${redirectPath}?${qs.stringify(queries)}`);
                });
                break;
              case CustomerFlowType.MultipleCustomers:
                // if multiple customers returned, figure out which current customer is if any
                history.replace(
                  `/identity-confirmation?${qs.stringify(queries)}`,
                );
                break;
              default:
                history.replace(`/apply?${qs.stringify(queries)}`);
                break;
            }
          })();
        }}
      >
        {({ errors, isSubmitting }) => (
          <Form
            noValidate
            autoComplete={process.env.REACT_APP_AUTOCOMPLETE || 'off'}
          >
            <Field
              className="project-type--type"
              id="project-type"
              name="projectType"
              component={FormSelect}
              options={getProjectType(subverticals)}
              label="Project Type"
              isSearchable={false}
              placeholder="Project Type"
            />
            )
            <Button
              id="project-type--submit"
              color={Colors.royalBlue}
              type="submit"
              dataLoading={isSubmitting}
            >
              {isSubmitting ? <FadeLoadSpinner /> : 'Next Step'}
            </Button>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export default ProjectType;
