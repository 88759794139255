import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import Numeral from 'numeral';
import classnames from 'classnames';
import { Field, Form, Formik, FormikProps } from 'formik';
import { FormRadio, FormWrapper, Modal } from 'components/shared';
import { Button, Colors, TextField } from '@westcreek/react';
import { IPurchasedAmount, PurchasedAmountSchema } from 'utils/form-schemas';
import './purchased-amount.scss';
import { IconType } from 'components/shared/modal';
import { createLoan } from './create-loan/create-loan';
import { CalcMinMonthly } from 'utils/calculations';
import { store, typedState } from 'store';
import { track } from '../../../utils/segment';
import { Product } from '../../../types/products';
import * as ColumnTemplates from './purchased-amount-columns';
import FadeLoadSpinner from 'components/shared/spinner';
import qs from 'querystring';
import { Queries } from '../../../types/queries';
import {
  OriginationFeesRequest,
  OriginationsFeesResponse,
} from '../../../types/request-response';

const PurchasedAmount = () => {
  const history = useHistory();
  const queries = qs.parse(history.location.search.slice(1)) as Queries;
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [generalErrorModalOpen, setGeneralErrorModalOpen] = useState(false);
  const [oobaSentModalOpen, setOobaSentModalOpen] = useState(false);
  const [diModalOpen, setDiModalOpen] = useState(false);
  const [mpModalOpen, setMpModalOpen] = useState(false);
  const [estimatedAPRModelOpen, setEstimatedAPRModelOpen] = useState(false);
  const [onChangeError, setOnChangeError] = useState(false);
  const [originationFees, setOriginationFees] = useState<
    OriginationsFeesResponse[] | undefined | null
  >();
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null,
  );
  const [finishedTypingTimer, setFinishedTypingTimer] = useState<
    NodeJS.Timeout
  >();
  const formikFormRef = useRef<FormikProps<IPurchasedAmount>>(null);
  const auth = typedState((state) => state.auth);
  const app = typedState((state) => state.applications);
  const stores = typedState((state) => state.stores);
  const offeredProducts = typedState((state) => state.products.products);

  const shouldRenderOrigFees = useMemo(() => {
    if (offeredProducts && offeredProducts.length) {
      return (
        offeredProducts.filter(
          (op) =>
            !!Numeral(op.origFeeMaxAmount).value() &&
            !!Numeral(op.origFeeRate).value(),
        ).length > 0
      );
    }
  }, [offeredProducts]);

  const fetchOrigFees = async (
    loanAmount: string,
    offeredProducts?: Product[],
  ) => {
    if (Numeral(loanAmount).value() && offeredProducts) {
      try {
        const origFeesRequest: OriginationFeesRequest[] = [];
        offeredProducts.forEach((product) => {
          origFeesRequest.push({
            productId: product.productId,
            loanAmount: String(loanAmount).replace(/[^0-9.]/g, ''),
            rate: product.origFeeRate,
            maxAmount: product.origFeeMaxAmount,
          });
        });

        const {
          data: origFees,
        }: {
          data: OriginationsFeesResponse[];
        } = await auth.client.post(
          '/calculations/origination-fee',
          origFeesRequest,
        );
        setOriginationFees(origFees);
      } catch (e) {
        console.error(`unable to calculate origination fees: '${e}'`);
        setGeneralErrorModalOpen(true);
      }
    }
  };

  //use effect with empty dependencies to fire only on load for segment tracking
  useEffect(() => {
    track('Loan Options Viewed', {});
  }, []);

  // backfill the data in case we don't need to calculate origination fees
  useEffect(() => {
    if (!shouldRenderOrigFees) {
      setOriginationFees(null);
      formikFormRef.current!.setFieldValue('originationFee', '0');
    }
  }, [shouldRenderOrigFees]);

  // fire useEffect on origFees being fetched and selectedProductId being set.
  // This handles the race condition in case the user types in the loan amount and
  // immediately clicks radio button
  useEffect(() => {
    if (shouldRenderOrigFees && originationFees && selectedProductId) {
      const selectedOf = originationFees.find(
        (of) => of.productId === selectedProductId,
      )!;
      formikFormRef.current!.setFieldValue(
        'originationFee',
        selectedOf.originationFee,
      );
    }
  }, [originationFees, selectedProductId, shouldRenderOrigFees]);

  if (!app.isApproved) return <Redirect to={`/?${qs.stringify(queries)}`} />;

  return (
    <FormWrapper>
      <Modal
        isOpen={generalErrorModalOpen}
        message="Something went wrong."
        dismissBtnName="OK"
        dismissAction={() => setGeneralErrorModalOpen(false)}
        iconType={IconType.Warning}
      />
      <Modal
        isOpen={oobaSentModalOpen}
        message="Please advise the customer to acknowledge the terms that have been sent to them by text message and email. Click the button below to view the customer's progress."
        dismissBtnName="View Application Profile"
        dismissAction={() =>
          window.location.replace(
            `https://${auth.applicationsMGMTUrl}/DealerCabinet/LoanDetails?applicationId=${app.applicationId}`,
          )
        }
      />
      <div className={'purchased-amount'}>
        <h2 id="purchased-amount">Purchase Amount</h2>
        <Formik
          innerRef={formikFormRef}
          enableReinitialize={true}
          initialValues={
            {
              loanAmount: '',
              productId: '',
              salesRep: '',
              orderNumber: '',
              lineAmount: 0,
              minimumSpend: 0,
              rate: '',
              deferredInterestPeriod: 0,
              deferredPaymentPeriod: 0,
              merchantDiscountRate: '',
              termLength: 0,
              originationFee: '',
            } as IPurchasedAmount
          }
          validationSchema={PurchasedAmountSchema}
          validateOnChange={onChangeError}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            if (!!Numeral(values.originationFee).value) {
              const selectedOp = offeredProducts?.find(
                (of) => of.productId === selectedProductId,
              )!;

              if (values.originationFee === selectedOp.origFeeMaxAmount) {
                track('Origination Fee Applied (Max)', {});
              } else {
                track('Origination Fee Applied (Percent)', {});
              }
            }

            createLoan({
              values,
              actions,
              setErrorModal,
              setGeneralErrorModalOpen,
              setOobaSentModalOpen,
              globalState: store.getState(),
              globalActions: store.getActions(),
            });
            store.getActions().applications.setRefresh(true);
          }}
        >
          {({ isSubmitting, values, errors }) => (
            <Form
              noValidate
              onChange={(e) => {
                // This event fires on 'loanAmount' input change. It fetches origination fees when user finishes typing
                // and input field is still focused.
                if (
                  shouldRenderOrigFees &&
                  // @ts-ignore-line
                  e.target.name === 'loanAmount' &&
                  // @ts-ignore-line
                  e.target.rawValue
                ) {
                  finishedTypingTimer && clearTimeout(finishedTypingTimer);
                  const newFinishedTypingTimer = setTimeout(async () => {
                    // @ts-ignore-line
                    await fetchOrigFees(e.target.rawValue, offeredProducts);
                  }, 500);
                  setFinishedTypingTimer(newFinishedTypingTimer);
                }
              }}
            >
              <Field
                id="purchased-amount--purchased-amount"
                name="loanAmount"
                component={TextField}
                label="Purchased Amount (Incl. Sales Tax)"
                autoFocus={true} // TODO: this errors on first render
                cleaveOptions={{
                  numeral: true,
                  numeralIntegerScale: 5,
                  prefix: '$',
                  noImmediatePrefix: true,
                  rawValueTrimPrefix: true,
                }}
              />
              <div className="intro">
                <p>Maximum Purchase Amount: </p>
                <span>
                  {values.lineAmount! > 0
                    ? `${Numeral(values.lineAmount).format('$0,0.00')}`
                    : '$0.00'}
                </span>
              </div>
              <div
                className={classnames({
                  'table-border': true,
                })}
              >
                <table>
                  <thead>
                    <tr>
                      <th>
                        <p>Select</p>
                      </th>
                      <th
                        className={'more-info'}
                        onClick={() => {
                          setDiModalOpen(true);
                        }}
                      >
                        <p>Deferred</p>
                        <p>Interest</p>
                        <p>Period /</p>
                        <p>Promo</p>
                        <p>Period</p>
                      </th>
                      <th>
                        <p>Length</p>
                        <p>of</p>
                        <p>Loan</p>
                      </th>
                      <th
                        className={`${shouldRenderOrigFees && 'more-info'}`}
                        onClick={() => {
                          shouldRenderOrigFees &&
                            setEstimatedAPRModelOpen(true);
                        }}
                      >
                        <p>Est.</p>
                        <p>APR (%)</p>
                      </th>
                      {shouldRenderOrigFees && (
                        <th>
                          <p>Origination</p>
                          <p>fee</p>
                        </th>
                      )}
                      <th>
                        <p>Estimated</p>
                        <p>Payment</p>
                        <p>Required</p>
                        <p>During</p>
                        <p>Promo</p>
                        <p>Period</p>
                      </th>
                      <th>
                        <p>Maximum</p>
                        <p>Purchase</p>
                        <p>Amount</p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {offeredProducts &&
                      offeredProducts.map((op: Product) => {
                        const of = originationFees?.find(
                          (fee) => fee.productId === op.productId,
                        );
                        return (
                          <tr key={op.deferredInterestPeriod}>
                            <td>
                              <Field
                                id={`${op.productId}`}
                                name="productId"
                                component={FormRadio}
                                error={errors.productId}
                                lineAmount={op.lineAmount}
                                ariaLabel={op.deferredInterestPeriod}
                                onClick={() => {
                                  setOnChangeError(true);
                                  setSelectedProductId(op.productId);
                                  values.lineAmount = op.lineAmount;
                                  values.minimumSpend = op.minimumSpend;
                                  values.deferredInterestPeriod =
                                    op.deferredInterestPeriod;
                                  values.merchantDiscountRate =
                                    op.merchantDiscountRate;
                                  values.rate = op.rate;
                                  values.termLength = op.termLength;
                                  values.deferredPaymentPeriod =
                                    op.deferredPaymentPeriod;
                                  // there is a race condition when the user types in a new loan amount and immediately
                                  // clicks the radio button. There is not enough time to fetch orig fees as a result the
                                  // formik "originationFee" remains a blank string. This case is handled in useEffect.
                                  if (of)
                                    values.originationFee = of.originationFee;
                                  track('Loan Option Selected', {
                                    additionalProperties: {
                                      promoPeriod: op.deferredInterestPeriod,
                                      deferredPaymentPeriod:
                                        op.deferredPaymentPeriod,
                                      termLength: op.termLength,
                                      lineAmount: op.lineAmount,
                                      apr: op.rate,
                                      productId: op.productId,
                                      originationFee: of?.originationFee,
                                    },
                                  });
                                }}
                              />
                            </td>
                            <ColumnTemplates.DeferredInterestTemplate
                              ap={op}
                              loanAmount={values.loanAmount as string}
                            />
                            <ColumnTemplates.TermLengthTemplate ap={op} />
                            <ColumnTemplates.RateTemplate ap={op} />
                            {shouldRenderOrigFees && (
                              <ColumnTemplates.OriginationFeeTemplate
                                ap={op}
                                of={of}
                              />
                            )}
                            <ColumnTemplates.MinimumPaymentBodyTemplate
                              ap={op}
                              loanAmount={values.loanAmount as string}
                              of={of}
                            />
                            <ColumnTemplates.MaximumPurchaseBodyTemplate
                              ap={op}
                            />
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {!values.productId && errors.productId && (
                <span className="selection-required">
                  Please, select an option
                </span>
              )}

              <div
                className="intro"
                onClick={() => {
                  if (values.deferredInterestPeriod) setMpModalOpen(true);
                }}
              >
                <div className="horizontal">
                  <p
                    className={
                      // tooltip contains the (?) scss, which only matters when there is a DI
                      values.deferredInterestPeriod ? 'more-info' : ''
                    }
                  >
                    Estimated Minimum Monthly Payment&nbsp;
                  </p>
                  <p>:</p>
                </div>
                <span>
                  {CalcMinMonthly(
                    values.loanAmount as string,
                    values.deferredInterestPeriod > 0 &&
                      values.deferredPaymentPeriod > 0,
                    values.deferredPaymentPeriod,
                    parseFloat(values.rate) / 100,
                    values.termLength,
                  )}
                </span>
              </div>

              <div style={{ paddingBottom: '2rem' }}>
                <Field
                  className="sales-rep"
                  id="purchased-amount--salesRep"
                  name="salesRep"
                  component={TextField}
                  label="Sales Rep Name or Email (Optional)"
                  cleaveOptions={{ blocks: [30], delimiter: '' }}
                />
                <Field
                  id="purchased-amount--orderNumber"
                  name="orderNumber"
                  component={TextField}
                  label="Order Number (Optional)"
                  cleaveOptions={{ blocks: [30], delimiter: '' }}
                />
              </div>
              <Button
                id="purchased-amount--submit"
                color={Colors.royalBlue}
                type="submit"
                dataLoading={isSubmitting}
                onClick={() => {
                  setOnChangeError(true);
                }}
              >
                {isSubmitting ? (
                  <FadeLoadSpinner />
                ) : (
                  'Submit for Customer Approval'
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        isOpen={diModalOpen}
        title={'Deferred Interest'}
        message={
          'To avoid paying interest, you must pay the entire loan amount ' +
          'before the promotion period ends. See the loan agreement at the next step ' +
          'for additional payoff options.'
        }
        dismissBtnName="OK"
        dismissAction={() => {
          setDiModalOpen(false);
        }}
      />
      <Modal
        isOpen={mpModalOpen}
        title={'Estimated Minimum Monthly Payment'}
        message={
          'Paying this amount will not payoff your loan before your promotion period ends.'
        }
        dismissBtnName="OK"
        dismissAction={() => {
          setMpModalOpen(false);
        }}
      />
      <Modal
        iconType={IconType.Warning}
        isOpen={errorModal}
        message={`This step of the application was already completed. Please resume the existing application.`}
        actionBtnName={'Continue'}
        action={() => {
          history.replace(
            `/?dealerId=${stores.store?.publicId}&applicationId=${app.applicationId}`,
          );
        }}
      />
      <Modal
        isOpen={estimatedAPRModelOpen}
        title={'Estimated APR'}
        message={
          'This is an estimated APR which does not include the origination fee. Origination fee ranges from 0.00% - 3.5% ' +
          'of your loan amount. Your origination fee will be calculated and shown to you prior to signing your agreement. ' +
          'See your loan agreement for specific terms and conditions.'
        }
        dismissBtnName="OK"
        dismissAction={() => {
          setEstimatedAPRModelOpen(false);
        }}
      />
    </FormWrapper>
  );
};

export default PurchasedAmount;
