/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { IconType } from 'components/shared/modal';
import './verify-phone-code.scss';
import { Button, Colors, TextField } from '@westcreek/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import FadeLoadSpinner from 'components/shared/spinner';
import { VerifyCodeSchema, VerifyCodeVals } from 'utils/form-schemas';
import { verifyCode } from './submit/handle-verify-phone-code';
import { useHistory } from 'react-router-dom';
import { S as rp } from 'components/application-flow/send-verification-code/send-verification-code';
import { resendVerificationCode } from 'components/application-flow/verify-phone-code/resend/handle-resend-verification-code';
import { store, typedActions, typedState } from 'store';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { FormWrapper, Hero, Modal } from 'components/shared';
import { track } from '../../../utils/segment';
import {
  ApplicationFlowType,
  CustomerFlowType,
} from '../../../store/models/flowType';
import decisionEligibility from '../../../processors/eligibility';

const VerifyPhoneCode: FC = () => {
  const history = useHistory();

  // global state
  const { dealerPortalUrl, verifiedPhone } = typedState((state) => state.auth);
  const flowType = typedState((state) => state.flowType);
  const customers = typedState((state) => state.customers);
  const stores = typedState((state) => state.stores);

  // global actions
  const { setVerifiedPhone } = typedActions((actions) => actions.auth);
  const { setProcessWaterfall } = typedActions(
    (actions) => actions.applications,
  );

  const query = qs.parse(history.location.search.slice(1)) as Queries;
  const { dealerId: dealerPublicId } = query;

  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [verificationId, setVerificationId] = useState<string | undefined>();
  const [callRequested, setCallRequested] = useState<boolean | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [status, setStatus] = useState<string | undefined>();
  const [attemptsRemaining, setAttemptsRemaining] = useState<number>(5);
  const [resentAttemptsRemaining, setResentAttemptsRemaining] = useState<
    number
  >(5);
  const [resentModalOpen, setResentModalOpen] = useState<boolean>(false);
  const [attemptsExceeded, setAttemptsExceeded] = useState<boolean>(false);

  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  useEffect(() => {
    const { verificationId, phone, callRequested } = history.location
      .state as rp;
    if (!verificationId || !phone) {
      setErrorModalOpen(true);
      return;
    }
    setVerificationId(verificationId);
    setPhone(phone);
    setCallRequested(callRequested);
  }, [history.location.state]);

  /* sets verified phone on global state in case of OTP is down;
  "setVerifiedPhone" action will trigger "useCustomerInfo" hook in routes.tsx which
  fetches all the customers associated with verified phone number*/
  useEffect(() => {
    if (status && status === 'approved' && !verifiedPhone) {
      setVerifiedPhone(phone!.replace(/[^0-9.]/g, ''));
    }
  }, [dealerPublicId, history, phone, status, setVerifiedPhone, query]);

  // waits for "useCustomerInfo" hook in routes.tsx response
  useEffect(() => {
    if (
      // shouldn't be a case since we only redirect here for new apps
      flowType.applicationFlow === ApplicationFlowType.New &&
      flowType.customerFlow &&
      customers.customers // wait for customers to be fetched
    ) {
      if (
        stores.store?.vertical === 'home_improvement' &&
        stores.store.financingType === 'full_spectrum'
      ) {
        localStorage.setItem(
          'subV',
          JSON.stringify(stores.store!.subverticals),
        );
        history.push(`/project-type?${qs.stringify(query)}`);
      } else {
        (async () => {
          switch (flowType.customerFlow) {
            case CustomerFlowType.SingleCustomer:
              await decisionEligibility({
                customerId: customers.customer!.customerId, // it is set by "useCustomerInfo" hook for single customer found
                dealerId: stores.store!.dealerId,
              }).then((eligibilityDecisionResult) => {
                const {
                  redirectPath,
                  processWaterfall,
                } = eligibilityDecisionResult;

                // process waterfall
                if (processWaterfall) setProcessWaterfall(processWaterfall);
                // redirect to new page
                if (redirectPath != null)
                  history.push(`${redirectPath}?${qs.stringify(queries)}`);
              });
              break;
            case CustomerFlowType.MultipleCustomers:
              // if multiple customers returned, figure out which current customer is if any
              history.replace(
                `/identity-confirmation?${qs.stringify(queries)}`,
              );
              break;
            default:
              history.replace(`/apply?${qs.stringify(queries)}`);
              break;
          }
        })();
      }
    }
  }, [customers.customers, flowType.customerFlow]);

  useEffect(() => {
    if (resentModalOpen) {
      setTimeout(() => setResentModalOpen(false), 3000);
    }
  }, [resentModalOpen]);

  return (
    <>
      <Hero />
      <FormWrapper className={'verify-phone-code'}>
        <Modal
          isOpen={resentModalOpen}
          message="New verification code has been sent to your phone."
        />
        <Modal
          isOpen={errorModalOpen}
          message="Something went wrong."
          dismissBtnName="OK"
          dismissAction={() => setErrorModalOpen(false)}
          iconType={IconType.Warning}
        />
        <Modal
          isOpen={attemptsRemaining === 0 || attemptsExceeded}
          message="We're sorry, but we were unable to validate your identity. Please wait at least 10 minutes and try again."
          dismissBtnName="OK"
          dismissAction={() => {
            window.location.replace(`https://${dealerPortalUrl}`);
          }}
          iconType={IconType.Warning}
        />
        <Modal
          isOpen={resentAttemptsRemaining === -1 || attemptsExceeded}
          message="We're sorry, but we were unable to validate your identity. You exceeded resend verification limit. Please wait at least 10 minutes and try again."
          dismissBtnName="OK"
          dismissAction={() => {
            window.location.replace(`https://${dealerPortalUrl}`);
          }}
          iconType={IconType.Warning}
        />
        <h2 id="verify-phone-code--header">Customer Phone Verification</h2>
        <p>
          {attemptsRemaining === 5 || status === 'approved' ? (
            `Enter the verification code you received for phone number (***) ***-**${phone?.slice(
              -2,
            )} in text below.`
          ) : (
            <span className="warning">
              {`It looks like submitted verification code for phone number (***) ***-**${phone?.slice(
                -2,
              )} may be incorrect. Please
                double-check your input and resubmit.`}
            </span>
          )}
        </p>
        <Formik
          initialValues={{
            code: '',
          }}
          validationSchema={VerifyCodeSchema}
          onSubmit={(
            values: VerifyCodeVals,
            actions: FormikHelpers<VerifyCodeVals>,
          ) => {
            verifyCode({
              values,
              actions,
              setErrorModalOpen,
              attemptsRemaining,
              setAttemptsRemaining,
              verificationId,
              setStatus,
              setAttemptsExceeded,
              globalState: store.getState(),
            }).catch((error) => {
              console.error(error);
              setErrorModalOpen(true);
            });
            actions.setSubmitting(false);
          }}
        >
          {({ isSubmitting, isValid }) => {
            return (
              <Form
                autoComplete={process.env.REACT_APP_AUTOCOMPLETE || 'off'}
                noValidate
              >
                <Field
                  id={'verify-phone-code--code'}
                  type={'tel'}
                  name="code"
                  component={TextField}
                  label="Verification Code"
                  autoFocus={true}
                  cleaveOptions={{
                    blocks: [4],
                  }}
                />
                <div className={'consent-links'}>
                  <Button
                    type="button"
                    onClick={() => {
                      track('Code Not Received', {});
                      if (!resentAttemptsRemaining) setAttemptsRemaining(-1);
                      resendVerificationCode({
                        phone,
                        callRequested,
                        setErrorModalOpen,
                        setVerificationId,
                        resentAttemptsRemaining,
                        setResentAttemptsRemaining,
                        setResentModalOpen,
                        setAttemptsExceeded,
                        globalState: store.getState(),
                      }).catch((error) => {
                        console.error(error);
                        setErrorModalOpen(true);
                      });
                    }}
                  >
                    {resentAttemptsRemaining > 2 || callRequested
                      ? 'Resend Code'
                      : "Haven't received the code? Have us call you."}
                  </Button>
                </div>
                <Button
                  data-testid="application-flow-verification-code-submit"
                  type="submit"
                  color={Colors.royalBlue}
                  disabled={isSubmitting || !isValid}
                >
                  {isSubmitting || status === 'approved' ? (
                    <FadeLoadSpinner />
                  ) : (
                    'Next Step'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </>
  );
};

export default VerifyPhoneCode;
