import React, { useState } from 'react';
import './header.scss';
import { Modal } from '..';
import { IconType } from '../modal';
import { Role } from 'store/models/auth';
import { typedState } from 'store';
import { ReactComponent as KoalafiLogo } from 'assets/icons/koalafi-logo.svg';
import { ReactComponent as KoalafiHome } from 'assets/icons/home-koalafi.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as KoalafiLogoReversed } from 'assets/icons/koalafi-logo-reverse.svg';

const logo = (
  currentLocation: string,
  isMobile: boolean,
): JSX.Element | null => {
  const logoId = 'header-company-logo';
  if (isMobile) return <KoalafiLogo id={logoId} />;
  switch (currentLocation) {
    case '/approved':
    case '/declined':
    case '/':
    case '/verification':
    case '/verify':
    case '/ineligible':
    case '/proceed-with-dealer':
    case '/apply':
      return null;
    case '/ready-for-delivery':
      return <KoalafiLogoReversed id={logoId} />;
    default:
      return <KoalafiLogo id={logoId} />;
  }
};

const Header = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const auth = typedState((state) => state.auth);
  const stores = typedState((state) => state.stores);

  const redirectHome = () => {
    if (auth.role === Role.Customer) {
      window.location.replace('https://www.koalafi.com');
    } else {
      window.location.replace(`https://${auth.dealerPortalUrl}`);
    }
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        iconType={IconType.Warning}
        message="You will lose all progress if you choose to abandon the application process. Are you sure you want to leave?"
        dismissBtnName="Cancel"
        actionBtnName="Yes, I want to leave."
        action={() => redirectHome()}
        dismissAction={() => setModalOpen(!modalOpen)}
      />
      <div className={'header'}>
        <div
          id={'logo-wrapper'}
          className={auth.role === Role.Customer ? 'right' : 'centered'}
          onClick={() => {
            // don't show "are you sure" modal when application is complete
            if (history.location.pathname !== '/ready-for-delivery') {
              setModalOpen(true);
            } else {
              redirectHome();
            }
          }}
        >
          {logo(history.location.pathname, auth.isMobile!)}
        </div>
        {auth.role === Role.Customer ? (
          <p className="dealer-name">{stores.store?.name}</p>
        ) : (
          <p
            className="nav-link exception-link"
            onClick={() => {
              // don't show "are you sure" modal when application is complete
              if (history.location.pathname !== '/ready-for-delivery') {
                setModalOpen(true);
              } else {
                redirectHome();
              }
            }}
          >
            <KoalafiHome />
          </p>
        )}
      </div>
    </>
  );
};

export default Header;
