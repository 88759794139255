import { Action, action } from 'easy-peasy';
import { HiProjectType } from 'types/projectType';

export interface ProjectTypeModel {
  projectType?: HiProjectType;

  setProjectType: Action<ProjectTypeModel, HiProjectType>;
}

export const initProjectTypeModel = (): ProjectTypeModel => ({
  projectType: undefined,

  // actions
  setProjectType: action((state, projectType) => {
    state.projectType = projectType;
  }),
});
