/* eslint-disable @typescript-eslint/no-unused-vars */
import { Action, action, computed, Computed, Thunk, thunk } from 'easy-peasy';
import { createAxios } from 'axios-client';
import { AxiosInstance } from 'axios';
import { JWT_COOKIE_SUFFIX } from 'utils/constants';
import { v1 as uuidv1 } from 'uuid';
import { decode, JwtPayload } from 'jsonwebtoken';
import { Queries } from 'types/queries';
import { ApplicationLabel, CampaignMedium } from '../../types/applications';

export enum AuthStatus {
  Loading,
  Authenticated,
  Unauthenticated,
  Failed,
}

export enum Role {
  Customer = 'Customer',
  Dealer = 'Dealer',
  Admin = 'Admin',
  CSR = 'CSR',
  DealerSupportRep = 'DealerSupportRep',
}

const useApplicationsMGMTUrl = (): string => {
  // Default to the production apps management URL
  const defaultApplicationsMGMTUrl = 'applications.koalafi.com';
  return process.env.REACT_APP_APPS_MGMT_URL || defaultApplicationsMGMTUrl;
};

// useDealerPortalUrl returns a URL according to the following precedence:
// 1: If environment variable is set, use it
// 2: Otherwise, fall back to the the domain name of a cookie with an appropriate suffix
// 3: Lastly, default to the production dealer portal URL
const decodeDealerPortalUrl = (useWcfAuth: boolean): string => {
  const defaultPortalUrl = useWcfAuth
    ? 'dealer.westcreekfin.com'
    : 'dealer.koalafi.com';
  let dealerPortalUrl = useWcfAuth
    ? process.env.REACT_APP_DEALER_PORTAL_URL_WCF
    : process.env.REACT_APP_DEALER_PORTAL_URL_KOALAFI;

  if (!dealerPortalUrl) {
    const [key, jwt] = getCookie(JWT_COOKIE_SUFFIX);
    dealerPortalUrl = key.split(JWT_COOKIE_SUFFIX)[0] || '';
  }

  return dealerPortalUrl || defaultPortalUrl;
};

/*
 Simple cookie reader function witch accepts a partial cookies names and returns
 the results in [cookieKey, cookieValue] format. If there are no cookies found that
 match passed partial cookie name the helper returns blank strings
*/
const getCookie = (partialName: string): [string, string] => {
  const matchedCookie = document.cookie
    .split(`; `)
    .find((cookie) => cookie.includes(partialName));
  if (matchedCookie) return matchedCookie.split('=') as [string, string];
  return ['', ''];
};

export interface verifyOobaRequest {
  [index: string]: string | boolean | number | undefined;
  oobaToken: string;
  applicationId: number;
  dealerPublicId: string;
}

export const OOBA = 'OOBA';
export interface AuthModel {
  client: AxiosInstance;
  authStatus: AuthStatus;
  dealerPortalUrl?: string;
  applicationsMGMTUrl?: string;
  socureDeviceSessionId?: string | null;
  tmxSessionId?: string | null;
  verifiedPhone?: string;
  googleApiKey?: string;
  isMobile?: boolean;
  source?: ApplicationLabel | 'OOBA';
  campaignMedium?: CampaignMedium;
  role?: Role;
  userName?: string;
  unifiedToken?: decodedUnifiedToken;

  setAxiosClient: Action<AuthModel, AxiosInstance>;
  setAuthStatus: Action<AuthModel, AuthStatus>;
  setDealerPortalUrl: Action<AuthModel, string>;
  setApplicationsMGMTUrl: Action<AuthModel, string>;
  setSocureDeviceSessionId: Action<AuthModel, string | null>;
  getAndSetSocureDeviceSessionId: Thunk<
    AuthModel,
    (() => Promise<string | null>) | null
  >;
  setTmxSessionId: Action<AuthModel, string | undefined | null>;
  setVerifiedPhone: Action<AuthModel, string>;
  setGoogleApiKey: Action<AuthModel, string | undefined>;
  setIsMobile: Action<AuthModel, boolean>;
  // OOBA will never submit an app so it's ok that it is not part of ApplicationLabel enum
  setSource: Action<AuthModel, ApplicationLabel | 'OOBA'>;
  setCampaignMedium: Action<AuthModel, CampaignMedium | undefined>;
  setRole: Action<AuthModel, Role>;
  setUserName: Action<AuthModel, string>;
  setUnifiedToken: Action<AuthModel, decodedUnifiedToken>;

  hasPrivilegedRole: Computed<AuthModel, boolean>;

  authenticate: Thunk<AuthModel, authenticateOptions>;
}

interface authenticateOptions {
  useWcfAuth: boolean;
  queryParams: Queries;
}

interface verifyResponse {
  sessionToken: string;
}

export enum IDVType {
  Full = 'Full', // Full" represents both OTP and last 4 + DOB check
  OTP = 'OTP',
}

interface decodedUnifiedToken {
  dealerPublicId: string;
  targetUi?: string; // url
  idvType: IDVType;
  customerId?: number; // can be null for new customer
  phoneNumber: string; // needed for when customerId is null
  applicationId?: number; // needed for app in progress and OTB
  exp: number; // unix timestamp, standard JWT field
  sessionToken: string; // not in the raw token, generated by the bff endpoint
}

const authenticateThunk = thunk<AuthModel, authenticateOptions>(
  async (actions, options) => {
    actions.setAuthStatus(AuthStatus.Loading);

    const dealerPortalUrl = decodeDealerPortalUrl(options.useWcfAuth);
    const applicationsMGMTUrl = useApplicationsMGMTUrl();

    // Default headers
    let headers: { [index: string]: string } = {
      // This Session-ID does not have to do with tmx-session-ID or Socure device session ID.
      // It is needed to group logs by user session as those are not set with every request.
      'Session-ID': uuidv1(),
    };

    // track campaign medium for analytics
    if (options.queryParams.cm) {
      // verify campaign medium value, otherwise leave as undefined
      if (
        Object.values(CampaignMedium).some(
          (cm: string) => cm === options.queryParams.cm.toLowerCase(),
        )
      ) {
        const cm: CampaignMedium = options.queryParams.cm.toLowerCase() as CampaignMedium;
        actions.setCampaignMedium(cm);
      }
    }

    // exchange for BFF token if TTA token exists, else use dealer auth
    let jwt = '';
    let source: ApplicationLabel | 'OOBA';
    let isJwtValid = false;
    if (options.queryParams.ttaToken) {
      try {
        const client = createAxios({ headers });
        const { data: response }: { data: verifyResponse } = await client.post(
          '/tta-link/verify',
          {
            ttaToken: options.queryParams.ttaToken,
            dealerPublicId: options.queryParams.dealerId,
          },
        );
        jwt = response.sessionToken;
      } catch (e) {
        actions.setAuthStatus(AuthStatus.Failed);
        return;
      }

      source = ApplicationLabel.TTA;
      actions.setSource(ApplicationLabel.TTA);
      actions.setRole(Role.Customer);
    } else if (options.queryParams.oobaToken) {
      try {
        const client = createAxios({ headers });
        const { data: response }: { data: verifyResponse } = await client.post(
          '/ooba-link/verify',
          {
            oobaToken: options.queryParams.oobaToken,
            dealerPublicId: options.queryParams.dealerId,
            applicationId: +options.queryParams.applicationId,
            customerId: +options.queryParams.customerId,
          },
        );
        jwt = response.sessionToken;
      } catch (e) {
        actions.setAuthStatus(AuthStatus.Failed);
        return;
      }

      source = OOBA;
      actions.setSource(OOBA);
      actions.setRole(Role.Customer);
    } else {
      source = ApplicationLabel.BM;
      actions.setSource(ApplicationLabel.BM);

      // Get auth cookie set by Dealer Portal
      [, jwt] = getCookie(dealerPortalUrl + JWT_COOKIE_SUFFIX);

      // fallback to just use suffix if issue with url config
      if (!jwt) {
        [, jwt] = getCookie(JWT_COOKIE_SUFFIX);
      }

      // determine role from token
      if (jwt) {
        const decodedToken = decode(jwt, { json: true, complete: true });
        const claims = decodedToken?.payload as JwtPayload;

        const nameKey =
          'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name';
        actions.setUserName(claims[nameKey]);

        const roleKey =
          'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
        const tokenRole = claims[roleKey];
        const curTimeInSeconds = Date.now() / 1000;
        isJwtValid = !!claims.exp && curTimeInSeconds < claims.exp;
        switch (tokenRole) {
          case Role.Admin:
          case Role.CSR:
          case Role.DealerSupportRep:
            actions.setRole(tokenRole);
            break;
          default:
            actions.setRole(Role.Dealer);
        }
      }
    }

    if (options.queryParams.unifiedToken) {
      try {
        const generateSessionToken = !jwt || !isJwtValid; // only generate a session token if there's no jwt or if the jwt is not valid

        const client = createAxios({ headers });
        const {
          data: response,
        }: { data: decodedUnifiedToken } = await client.post(
          '/unified-token/verify',
          {
            unifiedToken: options.queryParams.unifiedToken,
            source: source,
            generateSessionToken: generateSessionToken,
          },
        );

        if (generateSessionToken) {
          jwt = response.sessionToken;
        }

        actions.setUnifiedToken(response);
      } catch (e) {
        actions.setAuthStatus(AuthStatus.Failed);
        return;
      }
    }

    if (jwt) {
      headers['Authorization'] = `Bearer ${jwt}`;
    }

    const client = createAxios({ headers });

    // Initialize axios-client client and set Auth Status
    let authStatus: AuthStatus = AuthStatus.Loading;
    if (jwt) {
      try {
        await client.get('/authenticate');
        actions.setAxiosClient(client);
        authStatus = AuthStatus.Authenticated;
      } catch (e) {
        authStatus = AuthStatus.Failed;
      }
    } else {
      authStatus = AuthStatus.Unauthenticated;
    }

    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

    actions.setDealerPortalUrl(dealerPortalUrl);
    actions.setApplicationsMGMTUrl(applicationsMGMTUrl);
    actions.setGoogleApiKey(googleApiKey);
    actions.setAuthStatus(authStatus); // set authStatus LAST, it signals that axios client is configured
  },
);

export const initAuthModel = (): AuthModel => ({
  client: createAxios(),
  authStatus: AuthStatus.Loading,
  dealerPortalUrl: undefined,
  googleApiKey: undefined,
  isMobile: undefined,
  socureDeviceSessionId: undefined,
  tmxSessionId: undefined,
  source: undefined,
  role: undefined,
  userName: undefined,
  campaignMedium: undefined,
  unifiedToken: undefined,

  // actions
  setAxiosClient: action((state, client) => {
    state.client = client;
  }),
  setAuthStatus: action((state, status) => {
    state.authStatus = status;
  }),
  setDealerPortalUrl: action((state, url) => {
    state.dealerPortalUrl = url;
  }),
  setApplicationsMGMTUrl: action((state, url) => {
    state.applicationsMGMTUrl = url;
  }),
  setSocureDeviceSessionId: action((state, socureDeviceSessionId) => {
    state.socureDeviceSessionId = socureDeviceSessionId;
  }),
  setTmxSessionId: action((state, tmxSessionId) => {
    state.tmxSessionId = tmxSessionId;
  }),
  setVerifiedPhone: action((state, verifiedPhone) => {
    state.verifiedPhone = verifiedPhone;
  }),
  setGoogleApiKey: action((state, googleApiKey) => {
    state.googleApiKey = googleApiKey;
  }),
  setIsMobile: action((state, isMobile) => {
    state.isMobile = isMobile;
  }),
  setSource: action((state, source) => {
    state.source = source;
  }),
  setCampaignMedium: action((state, campaignMedium) => {
    state.campaignMedium = campaignMedium;
  }),
  setRole: action((state, role) => {
    state.role = role;
  }),
  setUserName: action((state, name) => {
    state.userName = name;
  }),
  setUnifiedToken: action((state, token) => {
    state.unifiedToken = token;
  }),

  // computed
  hasPrivilegedRole: computed((state): boolean => {
    switch (state.role) {
      case Role.Admin:
      case Role.CSR:
      case Role.DealerSupportRep:
        return true;
      default:
        return false;
    }
  }),

  // thunks
  authenticate: authenticateThunk,
  getAndSetSocureDeviceSessionId: thunk(async (actions, fn) => {
    // null may be passed in for fn in cases where we do not want to establish a
    // socure device session.
    if (fn) {
      actions.setSocureDeviceSessionId(await fn());
    }
  }),
});
