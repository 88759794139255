/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { typedActions, typedState } from '../store';
import { CustomerFlowType } from '../store/models/flowType';
import { IDVType } from 'store/models/auth';

const useCustomerInfo = (): [boolean | undefined] => {
  // global state
  const auth = typedState((state) => state.auth);
  const customers = typedState((state) => state.customers);

  // global actions
  const { fetchCustomers, getCustomerById, setCustomer } = typedActions(
    (actions) => actions.customers,
  );
  const { setCustomerFlow } = typedActions((actions) => actions.flowType);

  // local state
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<boolean | undefined>(undefined);

  // fetch customers data
  useEffect(() => {
    if (auth.verifiedPhone) {
      setLoading(true);
      try {
        if (auth.unifiedToken) {
          // get customer by id if the customer is there
          // dont do this if the application id was there, let the useExistingApplicantInformation resume instead
          if (
            auth.unifiedToken?.customerId &&
            !auth.unifiedToken?.applicationId
          ) {
            getCustomerById(auth.unifiedToken.customerId);
          }
        } else {
          fetchCustomers({
            includeTaxIdLast4: true,
            mobilePhone: auth.verifiedPhone,
            sort: 'dateUpdated',
          }); // dispatches setCustomers action
        }
      } catch (_) {
        // TODO: log something
        setLoading(false);
        setError(true);
      }
    }
  }, [auth.verifiedPhone]);

  // set customer flow
  useEffect(() => {
    // wait for customers to be set
    if (loading) {
      if (customers.customers) {
        switch (customers.customers.length) {
          case 0:
            setCustomerFlow(CustomerFlowType.NewCustomer);
            break;
          case 1:
            setCustomerFlow(CustomerFlowType.SingleCustomer);
            setCustomer(customers.customers[0]);
            break;
          default:
            setCustomerFlow(CustomerFlowType.MultipleCustomers);
            break;
        }
        setLoading(false);
      }

      if (auth.unifiedToken) {
        switch (auth.unifiedToken.idvType) {
          case IDVType.Full:
            setCustomerFlow(CustomerFlowType.VerifiedCustomer);
            break;
          case IDVType.OTP:
            setCustomerFlow(CustomerFlowType.NewCustomer);
            break;
          default:
          // This enum is checked in the BFF
        }
        setLoading(false);
      }
    }
  }, [customers.customers, loading]);

  return [error];
};

export default useCustomerInfo;
