/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormWrapper } from 'components/shared';
import './electronic-communications.scss';

const ElectronicCommunications = () => {
  return (
    <div className={'electronic-communications'}>
      <FormWrapper>
        <h2>Electronic Communications Notice</h2>
        <h6>E-Sign Disclosure, Consent Notice and E-Signature Agreement</h6>
        <p>
          {`This E-Sign Disclosure and Consent Notice ("Notice") applies to all
            communications, as defined below, for services provided by West Creek 
            Financial, Inc. dba Koalafi, and our affiliates (Koalafi or "We"). Under 
            this Notice, communications you receive in electronic form from us will 
            be considered "in writing”.`}{' '}
          <br />
          {`By agreeing to this Electronic Communications Notice on your
            application, you hereby consent to this Notice, affirm that you have
            access to the hardware and software requirements identified below and
            confirm that you are able to receive Communications using the Methods
            set forth in this Notice. In addition, you review and accept the terms
            of using Koalafi electronic and online services
            ("Electronic Services"). If you choose not to consent to this Notice
            or you withdraw your consent, you will be restricted from using
            Electronic Services.`}
        </p>
        <h6>Covered Communications</h6>
        <p>
          May include, but are not limited to, disclosures and communications we
          provide to you regarding our services such as: (a) Agreements, privacy
          policy or notices and any changes thereto; (b) payment disclosures,
          statements, transaction receipts and confirmations, default notices;
          and (c) customer service and collection communications
          ("Communications").
        </p>
        <h6>Methods Of Providing Communications</h6>
        <p>
          {`We may provide Communications to you by email or by making them
            accessible on the Koalafi websites, mobile applications,
            or mobile websites (including via "hyperlinks" provided online and in
            emails). Communications will be provided online and viewable using
            browser software or PDF files.`}
        </p>
        <h6>Hardware and Software Requirements</h6>
        <p>To access and retain electronic Communications, you must have:</p>
        <ul>
          <li>
            <p>A valid email address;</p>
          </li>
          <li>
            <p>
              A computer, mobile phone, tablet or similar device with internet
              access, current browser and software that can receive, access,
              display, and either print or store Communications received from us
              in electronic form;
            </p>
          </li>
          <li>
            <p>
              Sufficient storage space to save Communications (whether presented
              online, in emails or PDF) or the ability to print Communications.
            </p>
          </li>
        </ul>
        <h6>How to withdraw your consent</h6>
        <p>
          {`You may withdraw your consent to receive Communications under this
            Notice by either sending an email message with your name and mailing
            address to support@koalafi.com, or calling us at 844-937-8275 and 
            speaking to a Customer Service Representative, or writing to us at Koalafi, 
            PO Box 4887, Glen Allen, VA, 23058-4887, with your name and mailing 
            address. Be sure to provide your current mailing address and state 
            that you are withdrawing your consent to receive electronic Communications.`}{' '}
          <br />
          Your withdrawal of consent will cancel your agreement to receive
          electronic Communications, and therefore, your ability to use our
          Electronic Services.
        </p>
        <h6>Requesting Paper Copies of Electronic Communications</h6>
        <p>
          {`You may request a paper copy of any Communication and we will mail you
            a copy via U.S. Mail. To request a paper copy, contact us by sending
            an email message with your name and mailing address to
            support@koalafi.com, or call us at 844-937-8275 and speak to a
            Customer Service Representative, or write to us at Koalafi, 
            PO Box 4887, Glen Allen, VA, 23058-4887, with your name and
            mailing address. Be sure to state the name of the Communication you
            are requesting a paper copy of and provide your current mailing
            address.`}
        </p>
        <h6>Updating Your Contact Information</h6>
        <p>
          {`It is your responsibility to keep your primary email address and other
            information current so that Koalafi can communicate with
            you electronically. You understand and agree that if Koalafi sends you 
            a Communication but you do not receive it because your primary email 
            address on file is incorrect, out of date, blocked by your service 
            provider, or you are otherwise unable to receive electronic Communications, 
            Koalafi will be deemed to have provided the Communication to you.`}{' '}
          <br />
          If you use a spam filter or similar software that blocks or re-routes
          emails from senders not listed in your email address book, we
          recommend that you add the email addresses we use to your email
          address book so that you can receive Communications by email. <br />
          {`You can update your primary email address or other information by
            emailing us at support@koalafi.com, or calling us at 844-937-8275 
            and speaking to a Customer Service Representative, or writing to us 
            at Koalafi, PO Box 4887, Glen Allen, VA, 23058-4887. Be sure 
            to state what needs updating and provide the new information.`}
        </p>
        <h6>Federal Law</h6>
        <p>
          You acknowledge and agree that your consent to electronic
          Communications is being provided about a transaction affecting
          interstate commerce that is subject to the federal Electronic
          Signatures in Global and National Commerce Act, and that you and we
          both intend that the Act apply to the fullest extent possible to
          validate our ability to conduct business with you by electronic means.
        </p>
        <h6>Termination/Changes</h6>
        <p>
          We reserve the right, at our sole discretion, to discontinue the
          provision of your Communications, or to terminate or change the terms
          and conditions on which we provide Communications. We will provide you
          with notice of terminations or changes as required by law.
        </p>
        <h6>E-Signature Agreement</h6>
        <p>
          {`By submitting your acceptance of this Electronic Communications Notice
            in your application, you are electronically signing and agreeing to
            all the terms and conditions contained herein (your "e-signature").
            Upon submitting your e-signature, you acknowledge and consent to 
            Koalafi electronically capturing your e-signature, your IP
            address, and the date and time of your submission as evidence of your
            Agreement. By your use of the e-signature feature of this Agreement,
            you represent and warrant that you are at least 18 years old and have
            the legal right, power, and authority to make this Agreement. You
            further agree that your e-signature constitutes an "electronic
            signature" as defined by the Electronic Signatures in Global and
            National Commerce Act ("E-Sign"). You further acknowledge and agree
            that: 1) your e-signature constitutes your full agreement to this
            Agreement and all terms and conditions contained herein; and 2) such
            Agreement is an "electronic record" for purposes of E-Sign and the
            Act, and as such is completely valid, has legal effect, is
            enforceable, and is binding on, and non-refutable by you or any
            individual on whose behalf you are acting as if it were any other duly
            executed paper contract.`}
        </p>
      </FormWrapper>
    </div>
  );
};

export default ElectronicCommunications;
