/**
 * returns whether a number @param x is in the range
 * [ @param min, @param max ] (inclusive)
 */
const inRange = (x: number, min: number, max: number) => {
  return min <= x && x <= max;
};

/**
 * determines whether a user's SSN/ITIN input is an ITIN
 * https://www.irs.gov/irm/part3/irm_03-021-263r
 * @param ssn as input by the user, of the format '123456789'
 */
export const isITIN = (ssn: string): boolean => {
  // remove all non-number values in ssn input
  ssn = ssn.replace(/\D/g, '');

  const itinRanges = [
    [50, 65],
    [70, 88],
    [90, 92],
    [94, 99],
  ];

  if (ssn[0] === '9') {
    const fourthAndFifth = parseInt(ssn.slice(3, 5));
    for (const range of itinRanges) {
      if (inRange(fourthAndFifth, range[0], range[1])) {
        return true;
      }
    }
  }
  return false;
};
