import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { Modules } from 'store';
import { StateMapper } from 'easy-peasy';

interface P {
  phone: string | undefined;
  callRequested: boolean | undefined;
  setErrorModalOpen: Dispatch<SetStateAction<boolean>>;
  setVerificationId: Dispatch<SetStateAction<string | undefined>>;
  resentAttemptsRemaining: number;
  setResentAttemptsRemaining: Dispatch<SetStateAction<number>>;
  setResentModalOpen: Dispatch<SetStateAction<boolean>>;
  setAttemptsExceeded: Dispatch<SetStateAction<boolean>>;
  globalState: StateMapper<Modules>;
}

interface GenerateVerificationCodeBody {
  phoneNumber: string;
  channel?: 'sms' | 'call';
}

interface ResponseOk {
  status: string;
  verificationId: string;
  dateCreated: string;
}

export const resendVerificationCode = async (props: P) => {
  let {
    phone,
    callRequested,
    setErrorModalOpen,
    setVerificationId,
    resentAttemptsRemaining,
    setResentAttemptsRemaining,
    setResentModalOpen,
    setAttemptsExceeded,
    globalState,
  } = props;

  const formattedPhoneNumber = phone!.replaceAll('-', '');

  const axiosClient = globalState.auth.client;

  // call with code
  return axiosClient
    .post('/one-time-password/generate', {
      phoneNumber: formattedPhoneNumber,
      channel: callRequested || resentAttemptsRemaining <= 2 ? 'call' : 'sms',
    } as GenerateVerificationCodeBody)
    .then((response: AxiosResponse) => {
      resentAttemptsRemaining--;
      const responseBody = response.data as ResponseOk;
      if (responseBody.status === 'pending') {
        setResentModalOpen(true);
        setResentAttemptsRemaining(resentAttemptsRemaining);
        setVerificationId(responseBody.verificationId);
      } else {
        setResentAttemptsRemaining(resentAttemptsRemaining);
        throw new Error(
          `unprocessable response status: ${responseBody.status}`,
        );
      }
    })
    .catch((error) => {
      resentAttemptsRemaining--;
      switch (error.response.status) {
        case 422:
          setResentAttemptsRemaining(resentAttemptsRemaining);
          return;
        case 429:
          setAttemptsExceeded(true);
          setResentAttemptsRemaining(-1);
          return;
        default:
          setErrorModalOpen(true);
      }
    });
};
