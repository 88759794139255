/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormWrapper } from 'components/shared';
import './electronic-payments.scss';

const ElectronicPayments = () => {
  return (
    <FormWrapper>
      <div className={'electronic-payments'}>
        <h2>Authorization Agreement For Direct Payments (ACH Debits)</h2>
        <p>
          <strong>
            This Authorization Agreement for Direct Payments (ACH Debits) is a
            material part of (and incorporated by reference into) the Loan
            Agreement (the "Agreement"). Borrower should keep this important
            legal document for their records. Capitalized terms used but not
            defined herein shall have the meanings given them in the Agreement.
          </strong>
        </p>
        <h6>Automatic Payment Plan</h6>
        <p>
          {`By consenting to electronic payments, Borrower agrees to enroll in the
            Automatic Payment Plan and authorizes West Creek Financial dba Koalafi 
            (and its successors and assigns) to collect Payments required under the 
            terms of the Agreement by initiating ACH debit entries to the Checking or
            Savings Account designated below (the “Designated Account”) in the
            amounts and on the dates provided in the payment schedule set forth in
            the accompanying Agreement. Borrower authorizes Koalafi
            to increase the amount of any scheduled ACH debit entry or assess
            multiple ACH debits for the amount of any previously scheduled
            payment(s) that was not paid as provided in the Payment Schedule and
            any unpaid Fees. If ACH debits are returned unpaid, Borrower
            authorizes Koalafi to charge the debit card provided on
            the application for any returned payments. After two consecutive ACH
            debits are returned unpaid, Borrower agrees Koalafi may
            charge the debit card provided on the application in the amounts and
            on the dates provided in the Payment Schedule to collect payments
            required under the terms of the Agreement without first attempting to
            collect payments by ACH debit. Borrower agrees that if Koalafi
            opts to charge Borrower’s debit card in lieu of ACH debits,
            Koalafi may resume ACH debits at any time. Borrower
            agrees that Koalafi has the ability to modify dates and
            amounts of electronic payments based on written or electronic notice
            to Borrower. This authorization is to remain in full force and effect
            until Koalafi has received written notification from the
            Borrower of its termination in such time and in such manner as to
            afford Koalafi and Borrower’s depository bank a
            reasonable opportunity to act on it. Koalafi will inform Borrower
            when an electronic fund transfer will vary in amount from the previous
            transfer, if requested to do so, and otherwise will notify Borrower if
            a transfer will exceed twice the amount of the previous transfer. In
            addition to any rights or remedies of Koalafi under the
            Agreement, Koalafi may suspend or terminate Borrower’s
            enrollment in the Automatic Payment Plan immediately if Borrower fails
            to keep Borrower’s Designated Account in good standing or if there are
            insufficient funds in Borrower’s Designated Account to process any
            payment. Borrower further agrees not to dispute the recurring
            collection of payments by Koalafi with Borrower’s bank so
            long as the transaction corresponds to the terms of the Agreement.`}
        </p>
        <p>
          <strong>
            {`If Borrower revokes the authorization or Koalafi
              suspends or terminates Lessee's enrollment in the Automatic Payment
              Plan, Borrower still will be responsible for making timely payments
              through another ACH debit account. Borrower may opt to repay Koalafi
              by a method other than electronic fund transfer by contacting (844) 937-8275.`}
          </strong>
        </p>
        <h6>Account Changes</h6>
        <p>
          {`Borrower agrees to notify Koalafi promptly (and in any
            event at least 10 days prior to the next payment transaction) if there
            are any changes to the account and routing numbers of the Designated
            Account.`}
        </p>
        <h6>Miscellaneous</h6>
        <p>
          {`Koalafi is not responsible for any fees charged by
            Borrower's bank as the result of credits or debits initiated under
            this authorization.`}
        </p>
      </div>
    </FormWrapper>
  );
};

export default ElectronicPayments;
