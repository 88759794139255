/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormWrapper } from 'components/shared';

const TermsAndConditions = () => {
  return (
    <FormWrapper>
      <h2 id="terms-and-conditions--header">Terms and Conditions</h2>
      <p>
        {`I authorize West Creek Financial, Inc. dba Koalafi (“Koalafi”) to
        consider me for the range of financing options offered by this merchant,
        which may include loans, lease-to-own financing, or both. In the event
        you reside in a state that does not allow lease-to-own financing, you
        will not be considered for that financing option. I agree to and/or
        certify the following:`}
        <br />
        <br />
        <h6>Application Information:</h6>
        {`All information provided on this application is accurate and complete.
        West Creek Financial, Inc. dba Koalafi (“Koalafi”) may share my information,
        including the application decision, with the merchant whose merchandise I
        seek to purchase.`}
        <br />
        <br />
        <h6>Texts and Communications:</h6>
        {`Koalafi may communicate
        with me by mail, telephone, text message or other legal means,
        including an automated telephone dialing system, and may monitor or
        record my conversation or other communications.`}
        <br />
        <br />
        <h6>Credit Report:</h6>
        {`Koalafi may obtain information from
        Consumer Reporting Agencies (including credit bureau reports) for use in
        considering my application and administering my account.`}
        <br />
        <br />
        <h6>Bank Account/Debit Card Authorization:</h6>
        {`I authorize Koalafi to validate and debit reoccurring payments from the
        provided payment methods.`}
        <br />
        <br />
        <h6>Arbitration:</h6>
        {` I understand an arbitration provision is
        included in the lease agreement.`}
        <br />
        <br />
        {`By proceeding, I acknowledge that I have reviewed and agree to Koalafi’s
        disclosures. I understand that by proceeding I will be considered for
        the range of financing options offered by this merchant, which may include loans,
        lease-to-own financing, or both.`}
      </p>
    </FormWrapper>
  );
};

export default TermsAndConditions;
