import { ActionMapper, StateMapper } from 'easy-peasy';
import { FormikErrors } from 'formik';
import moment from 'moment';
import React from 'react';
import { ActionsMapperType, Modules } from 'store';
import { CustomerFlowType, EligibilityResultType } from 'store/models/flowType';
import { CustomerRecord } from 'types/customers';
import { IFinanceApplication } from 'utils/form-schemas';
import { formatDate } from 'utils/formatting';
import decisionEligibility from '../../../../processors/eligibility';
import { EligibilityDecisionResult } from '../../../../processors/eligibility-redirect';

export function checkExistingCustomer(
  values: IFinanceApplication,
  setValues: (values: IFinanceApplication) => void,
  errors: FormikErrors<IFinanceApplication>,
  globalState: StateMapper<Modules>,
  globalActions: ActionMapper<Modules, ActionsMapperType>,
  setOTBCustomerModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setReturningCustomerModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIneligibleCustomerModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setEligibilityErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCheckingEligibility: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const axiosClient = globalState.auth.client;
  const currentCustomerDetails = globalState.customers.customer;
  const verifiedPhone = globalState.auth.verifiedPhone;

  if (!errors.dateOfBirth && !errors.ssn && values.ssn && values.dateOfBirth) {
    axiosClient
      .post('/customers/search', {
        ssn: values.ssn,
        dateOfBirth: values.dateOfBirth
          ? moment.utc(values.dateOfBirth).format('YYYY-MM-DD')
          : null,
      })
      .then(async (response) => {
        const customer = response.data as CustomerRecord;
        let eligibilityDecisionResult: EligibilityDecisionResult;

        try {
          setIsCheckingEligibility(true);
          eligibilityDecisionResult = await decisionEligibility({
            customerId: customer.customerId,
            dealerId: globalState.stores.store!.dealerId, // useDealerInformation will throw error modal if can't get dealer
          });
        } catch (e) {
          // reset SSN/DOB so we can try to get eligibility again
          setValues({
            ...values,
            ssn: '',
            dateOfBirth: '',
          });
          setEligibilityErrorModalOpen(true);
          return;
        } finally {
          setIsCheckingEligibility(false);
        }

        const address1 = customer.homeAddress?.addressLine1 || '';
        const address2 = customer.homeAddress?.addressLine2 || '';
        const city = customer.homeAddress?.city || '';
        const state = customer.homeAddress?.state || '';
        const zip = customer.homeAddress?.zip || '';
        const dateOfBirth = formatDate(customer.dateOfBirth);
        const monthlyIncome = currentCustomerDetails?.monthlyIncome || 0;
        const mobilePhone = verifiedPhone || customer.mobilePhone;

        // set customer after eligibility request so that UI adjustments happen inline with modal pop-ups
        globalActions.customers.setCustomer({
          ...customer,
          dateOfBirth: dateOfBirth,
          address1: address1,
          address2: address2,
          city: city,
          state: state,
          zip: zip,
          ssn: values.ssn.slice(-4),
          // if this is a resubmit after soft decline, monthly income and lpCustomerId may already be set,
          // and lpCustomerId is a required field on resubmit so we don't want to lose it
          monthlyIncome: monthlyIncome,
          lpCustomerId: currentCustomerDetails?.lpCustomerId,
          // We should be setting the mobile phone to the one that was verified if present which can patch customer on submission
          mobilePhone: mobilePhone,
        });

        globalActions.flowType.setCustomerFlow(
          CustomerFlowType.VerifiedCustomer,
        );

        const {
          eligibilityResult,
          processWaterfall,
        } = eligibilityDecisionResult;

        switch (eligibilityResult) {
          case EligibilityResultType.OTBEligible:
            setOTBCustomerModalOpen(true);
            break;
          case EligibilityResultType.GenerallyEligible:
            // prefill form values
            setValues({
              ...values,
              firstName: customer.firstName,
              lastName: customer.lastName,
              address1: address1,
              address2: address2,
              city: city,
              state: state,
              zip: zip,
              email: customer.email,
              monthlyIncome: `${monthlyIncome}`,
              dateOfBirth: dateOfBirth,
              mobilePhone: mobilePhone,
            });
            setReturningCustomerModalOpen(true);
            break;
          case EligibilityResultType.Ineligible:
            if (processWaterfall) {
              globalActions.applications.setProcessWaterfall(true);
              return;
            }
            setIneligibleCustomerModalOpen(true);
            break;
          case EligibilityResultType.Failure:
            setEligibilityErrorModalOpen(true);
            break;
        }
      })
      .catch((error) => {
        // customer simply didn't exist, do nothing
        if (error.response && error.response.status === 404) {
          return;
        }
        console.error(error);
      });
  }
}
