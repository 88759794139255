import React from 'react';
import { Modal } from 'components/shared';
import { IconType } from 'components/shared/modal';

interface IState {
  hasError: boolean;
}

// App-wide error boundary that displays a simple error interface
class ErrorBoundary extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Modal
          isOpen={true}
          message="Something went wrong."
          iconType={IconType.Warning}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
