import { AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { VerifyCodeVals } from 'utils/form-schemas';
import { Modules } from 'store';
import { StateMapper } from 'easy-peasy';
import { track } from 'utils/segment';

interface P {
  values: VerifyCodeVals;
  actions: FormikHelpers<VerifyCodeVals>;
  setErrorModalOpen: Dispatch<SetStateAction<boolean>>;
  verificationId: string | undefined;
  attemptsRemaining: number;
  setAttemptsRemaining: Dispatch<SetStateAction<number>>;
  setStatus: Dispatch<SetStateAction<string | undefined>>;
  setAttemptsExceeded: Dispatch<SetStateAction<boolean>>;
  globalState: StateMapper<Modules>;
}

interface GenerateVerifyCodeBody {
  code: string;
  verificationId: string;
}

export const verifyCode = async (props: P) => {
  let {
    values,
    actions,
    setErrorModalOpen,
    verificationId,
    attemptsRemaining,
    setAttemptsRemaining,
    setStatus,
    setAttemptsExceeded,
    globalState,
  } = props;
  const axiosClient = globalState.auth.client;

  return axiosClient
    .post('/one-time-password/verify', {
      code: values.code,
      verificationId,
    } as GenerateVerifyCodeBody)
    .then((response: AxiosResponse) => {
      actions.setSubmitting(false);
      attemptsRemaining--;
      switch (response.data.status) {
        case 'approved':
        case 'pending':
          track('Mobile Number Confirmed', {
            additionalProperties: { status: response.data.status },
          });

          setStatus(response.data.status);
          setAttemptsRemaining(attemptsRemaining);
          break;
        default:
          setAttemptsRemaining(attemptsRemaining);
          track('Phone Number Error', {});
          throw new Error(
            `unprocessable response status: ${response.data.status}`,
          );
      }
    })
    .catch((error) => {
      actions.setSubmitting(false);
      attemptsRemaining--;
      actions.resetForm();
      switch (error.response.status) {
        case 404:
          setAttemptsRemaining(attemptsRemaining);
          break;
        case 422:
          setAttemptsRemaining(attemptsRemaining);
          setErrorModalOpen(true);
          break;
        case 429:
          setAttemptsExceeded(true);
          setAttemptsRemaining(0);
          break;
        default:
          setAttemptsRemaining(attemptsRemaining);
          setErrorModalOpen(true);
          throw error;
      }
    });
};
