import { createStore, createTypedHooks } from 'easy-peasy';
import { AuthModel, initAuthModel } from './models/auth';
import { ConfigModel, initConfigModel } from './models/config';
import { initStoresModel, StoresModel } from './models/stores';
import { FlowTypeModel, initFlowTypeModel } from './models/flowType';
import {
  ApplicationsModel,
  initApplicationsModel,
} from './models/applications';
import { CustomersModel, initCustomersModel } from './models/customers';
import { initLoansModel, LoansModel } from './models/loans';
import { initProductsModel, ProductsModel } from './models/products';
import { initProjectTypeModel, ProjectTypeModel } from './models/projectType';

// import { gimme } from 'gimme-state';

export interface Modules {
  auth: AuthModel;
  configs: ConfigModel;
  stores: StoresModel;
  applications: ApplicationsModel;
  customers: CustomersModel;
  loans: LoansModel;
  products: ProductsModel;
  flowType: FlowTypeModel;
  projectType: ProjectTypeModel;
}

export type ActionsMapperType =
  | 'auth'
  | 'configs'
  | 'stores'
  | 'applications'
  | 'customers'
  | 'loans'
  | 'products'
  | 'flowType'
  | 'projectType';

const modules: Modules = {
  auth: initAuthModel(),
  configs: initConfigModel(),
  stores: initStoresModel(),
  applications: initApplicationsModel(),
  customers: initCustomersModel(),
  loans: initLoansModel(),
  products: initProductsModel(),
  flowType: initFlowTypeModel(),
  projectType: initProjectTypeModel(),
};

const typedHooks = createTypedHooks<Modules>();

export const typedActions = typedHooks.useStoreActions;
export const typedState = typedHooks.useStoreState;
export const store = createStore(modules, {
  // middleware: [gimme], // uncomment this line for easy-peasy logs
});
