import querystring from 'querystring';
import { store } from '../store';
import { EligibilityResponse } from '../types/customers';
import getEligibilityRedirectPath, {
  EligibilityDecisionResult,
} from './eligibility-redirect';
import { EligibilityResultType } from '../store/models/flowType';

export interface fetchEligibilityRequest {
  [index: string]: string | boolean | number | undefined;
  customerId: number;
  dealerId: number;
}

const decisionEligibility = async (
  payload: fetchEligibilityRequest,
): Promise<EligibilityDecisionResult> => {
  const globalActions = store.getActions();
  const globalState = store.getState();
  try {
    // format eligibility request
    const qs = querystring.stringify(payload);

    // fetch eligibility
    const {
      data: eligibilityRes,
    }: { data: EligibilityResponse } = await globalState.auth.client.get(
      `/eligibility?${qs}`,
    );

    // update global state
    // only concerned with loan products, set here to use purchased-amount.tsx
    await globalActions.products.setProducts(
      eligibilityRes.otbProducts.loanOptions,
    );
    // set approved to true if OTB eligible so we can re-use approved.tsx component
    await globalActions.applications.setApproved(
      eligibilityRes.isOtbEligible as boolean,
    );
    // set eligibility response to global state
    await globalActions.customers.setEligibility(eligibilityRes);

    // check eligibility response
    const eligibilityDecision = getEligibilityRedirectPath(
      globalState.customers.customers!,
      eligibilityRes,
      globalState.flowType.customerFlow!,
      globalState.stores.store!,
      globalState.projectType.projectType,
    );

    await globalActions.flowType.setEligibilityResult(
      eligibilityDecision.eligibilityResult,
    );

    return eligibilityDecision;
  } catch (e) {
    await globalActions.flowType.setEligibilityResult(
      EligibilityResultType.Failure,
    );
    throw e;
  }
};

export default decisionEligibility;
