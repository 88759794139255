import { AxiosError } from 'axios';
import { FormikHelpers } from 'formik';
import { track } from 'utils/segment';
import { ErrorCode, instanceOfWcError } from 'utils/errors';
import { ActionsMapperType, Modules } from 'store';
import { Dispatch, SetStateAction } from 'react';
import { ActionMapper, StateMapper } from 'easy-peasy';

export function agreeToLoan(
  actions: FormikHelpers<any>,
  marketingConsent: boolean,
  setSuccess: Dispatch<SetStateAction<boolean>>,
  setModalMessage: Dispatch<SetStateAction<string>>,
  globalState: StateMapper<Modules>,
  globalActions: ActionMapper<Modules, ActionsMapperType>,
) {
  actions.setSubmitting(true);
  const { client: axiosClient, role } = globalState.auth;
  const { applicationId, acknowledgedBy } = globalState.applications;
  const { customer } = globalState.customers;
  const { loan } = globalState.loans;

  axiosClient
    .post(`/loans/${loan?.id}/agreement`, {
      customerId: customer?.customerId,
      lpCustomerId: customer?.lpCustomerId,
      marketingConsent,
      applicationId: applicationId,
      loanAmount: loan?.loanAmount,
    })
    .then(() => {
      if (!acknowledgedBy) {
        globalActions.applications.setAcknowledgedBy(role!);
        track('OOBA Acknowledged', {
          additionalProperties: {
            acknowledgedBy: role,
          },
        });
      }
      setSuccess(true);
    })
    .catch((err: AxiosError) => {
      actions.setSubmitting(false);
      const errBody = err.response?.data;
      if (errBody && instanceOfWcError(errBody)) {
        switch (errBody.errorCode) {
          case ErrorCode.InsufficientCredit:
            setModalMessage(
              'There is insufficient remaining credit for this loan.',
            );
            break;
          default:
            setModalMessage('Something went wrong.');
            console.error(err);
        }
      } else {
        setModalMessage('Something went wrong.');
        console.error(err);
      }
    })
    .finally(() => {
      track('Agreement Accepted', {
        additionalProperties: {
          marketingAccepted: marketingConsent.toString(),
        },
      });
      globalActions.applications.setRefresh(true);
      actions.setSubmitting(false);
    });
}
