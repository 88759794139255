/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Field, Form, Formik } from 'formik';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { FormRadio, FormWrapper, Modal } from 'components/shared';
import { Button, Colors, TextField } from '@westcreek/react';
import { AutopayDetailsSchema, IAutopayDetails } from 'utils/form-schemas';
import './autopay-details.scss';
import { IconType } from 'components/shared/modal';
import { DefaultError, WcError } from 'utils/errors';
import { formatDate } from 'utils/formatting';
import { track } from 'utils/segment';
import { PropagateLoader } from 'react-spinners';
import FadeLoadSpinner from 'components/shared/spinner';
import { Modules, store, typedState } from 'store';
import { StateMapper } from 'easy-peasy';
import {
  dupPaymentProfileMessage,
  processError,
  setBankName,
} from 'components/application-flow/autopay-details/helpers/helper';
import qs from 'querystring';
import { Queries } from '../../../types/queries';
import {
  CustomerPaymentProfile,
  GetCustomerPaymentProfilesResponse,
} from 'types/loans';
import ReactModal from 'react-modal';

const AutopayDetails = () => {
  const history = useHistory();

  const loan = typedState((state) => state.loans.loan);
  const lpCustomerId = typedState(
    (state) => state.customers.customer?.lpCustomerId,
  );
  const [redirect, setRedirect] = useState(false);
  const [isFetching, setisFetching] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [dupModalMessage, setDupModalMessage] = useState('');
  const customer = typedState((state) => state.customers);
  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  useEffect(() => {
    // Get customer payment profile info
    (async () => {
      try {
        // This should be set in redux prior to the page load. It isn't an oversight
        if (!lpCustomerId) {
          history.replace(`/${qs.stringify(queries)}`);
        }
        const axiosClient = store.getState().auth.client;
        setisFetching(true);
        await axiosClient
          .get(`/customers/${lpCustomerId!.toString()}/payment-profiles`)
          .then(
            (response: AxiosResponse<GetCustomerPaymentProfilesResponse>) => {
              if (
                response !== null &&
                response.data?.paymentAccounts?.results
              ) {
                const profiles: CustomerPaymentProfile[] =
                  response.data?.paymentAccounts?.results;
                if (profiles != null && profiles.length > 0) {
                  setDupModalMessage(dupPaymentProfileMessage);
                }
              }
              setisFetching(false);
            },
          )
          .catch((error) => {
            setisFetching(false);
            throw error;
          });
      } catch (error) {
        setisFetching(false);
        console.error(error);
        return;
      }
    })();
    // If they already have info, show the modal
    // Otherwise, proceed as normal
  }, []);

  if (redirect) {
    return <Redirect to={`/ready-for-delivery?${qs.stringify(queries)}`} />;
  }

  return (
    <FormWrapper className={'autopay-details'}>
      <Modal
        isOpen={!!modalMessage}
        message={modalMessage}
        dismissBtnName="OK"
        dismissAction={() => setModalMessage('')}
        iconType={IconType.Warning}
      />
      <ReactModal isOpen={isFetching} className="koalafi-modal loading-modal">
        <div className="loader">
          <PropagateLoader color={'#A3A3A3'} />
        </div>
      </ReactModal>

      <Modal
        isOpen={!!dupModalMessage}
        message={dupModalMessage}
        actionBtnName="CONTINUE"
        action={() => {
          setRedirect(true);
          setDupModalMessage('');
        }}
      />

      <Formik
        initialValues={
          {
            autoPay: '',
            routingNumber: customer.customer?.routingNumber || '',
            accountNumber: customer.customer?.accountNumber || '',
            bankName: '', // fetch when selected
          } as IAutopayDetails
        }
        validationSchema={AutopayDetailsSchema}
        onSubmit={(values, actions) => {
          if (values.autoPay === 'autopayDetails--autoPay-yes') {
            actions.setSubmitting(true);
            createPaymentProfile({
              values,
              callback: (error: WcError) => {
                actions.setSubmitting(false);
                if (!error) {
                  track('Autopay Registered', {});
                  setRedirect(true);
                } else {
                  track('Autopay Error', {});
                  processError(
                    error,
                    setModalMessage,
                    setDupModalMessage,
                    actions,
                  );
                }
              },
              globalState: store.getState(),
            });
          } else {
            track('Autopay Bypassed', {});
            setRedirect(true);
          }
        }}
      >
        {({ values, setValues, errors, isSubmitting }) => (
          <Form
            noValidate
            autoComplete={process.env.REACT_APP_AUTOCOMPLETE || 'off'}
          >
            <span>
              <h2>
                Your payment setup is{' '}
                <span id="autopay-red-font">incomplete</span>
              </h2>
              <h3>Set up AutoPay today and never miss a payment!</h3>
            </span>
            {loan ? (
              <div className="estimates">
                <p>Estimated Payment Amount: </p>
                <p>
                  <strong id="autopay--estimated-payment-amt">
                    ${loan.paymentAmount}
                  </strong>
                </p>
                <p>Estimated First Due Date: </p>
                <p>
                  <strong id="autopay--estimated-first-payment-date">
                    {formatDate(loan.firstPaymentDue)}
                  </strong>
                </p>
              </div>
            ) : null}
            {loan && <hr />}
            <div className={'option-grp'}>
              <Field
                component={FormRadio}
                name="autoPay"
                id="autopayDetails--autoPay-yes"
                label={`I opt in to auto-pay. By selecting this option, I agree to, have read, and consent to:`}
                error={errors.autoPay}
                ariaLabel="Autopay Radio"
                onChange={() => {
                  if (values.autoPay === 'autopayDetails--autoPay-yes') {
                    setBankName(values, setValues, store.getState());
                  } else {
                    setValues({
                      routingNumber: '',
                      accountNumber: '',
                      bankName: '',
                    } as IAutopayDetails);
                  }
                }}
              />
              <Link to={'/electronic-payments'} target="_blank">
                Electronic Payment Authorization Form
              </Link>
            </div>
            <div className={'option-grp'}>
              <Field
                component={FormRadio}
                name="autoPay"
                id="autopayDetails--autoPay-no"
                label="I opt out of auto-pay at this time."
                error={errors.autoPay}
              />
            </div>
            {values.autoPay === 'autopayDetails--autoPay-yes' ? (
              <>
                <hr />
                <h3>Bank Details</h3>
                <Field
                  className="fs-exclude text-field"
                  id="autopayDetails--routingNumber"
                  name="routingNumber"
                  component={TextField}
                  label="Routing Number"
                  autoFocus={true}
                  cleaveOptions={{
                    blocks: [9],
                    numericOnly: true,
                  }}
                  onBlur={() => {
                    setBankName(values, setValues, store.getState());
                  }}
                />
                <Field
                  className="fs-exclude text-field"
                  id="autopayDetails--accountNumber"
                  name="accountNumber"
                  type="tel"
                  component={TextField}
                  mask={true}
                  label="Account Number"
                  cleaveOptions={{
                    blocks: [20],
                  }}
                />
                <Field
                  className="text-field"
                  id="autopayDetails--bankName"
                  name="bankName"
                  component={TextField}
                  label="Banking Institution"
                  cleaveOptions={{ blocks: [30], delimiter: '' }}
                />
              </>
            ) : null}
            <Button
              id="autopayDetails--submit"
              color={Colors.royalBlue}
              type="submit"
              dataLoading={isSubmitting}
            >
              {isSubmitting ? <FadeLoadSpinner /> : 'Next Step'}
            </Button>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
};

export const createPaymentProfile = ({
  values,
  callback,
  globalState,
}: {
  values: any;
  callback: any;
  globalState: StateMapper<Modules>;
}) => {
  const customer = globalState.customers.customer;
  const loan = globalState.loans.loan;
  const axiosClient = globalState.auth.client;
  const applicationId = globalState.applications.applicationId;

  if (!customer || !loan) {
    callback('Something went wrong.');
    return;
  }
  // set autopay to true for application
  axiosClient
    .post(
      `/applications/${applicationId}/autopay`,
      { autopay: true },
      {
        validateStatus: (status) => {
          return status < 500;
        },
      },
    )
    .then((response: AxiosResponse<string>) => {
      switch (response.status) {
        case 204:
          break;
        case 400:
          return callback(response.data);
        default:
          console.error(response.status);
          callback(DefaultError);
      }
    })
    .catch((err) => {
      console.error(err);
      callback(DefaultError);
    });

  const req = {
    address:
      customer.address1 +
      (customer.address2 && customer!.address2 !== ''
        ? ' ' + customer.address2
        : ''),
    city: customer.city,
    state: customer.state,
    zip: customer.zip,
    firstName: customer.firstName,
    lastName: customer.lastName,
    bankName: values.bankName,
    routingNumber: values.routingNumber,
    accountNumber: values.accountNumber,
  };
  // create payment profile in loan pro
  axiosClient
    .post(`/customers/${loan.borrower.id}/payment-profile`, req, {
      validateStatus: (status) => {
        return status < 500;
      },
    })
    .then((response: AxiosResponse<string>) => {
      switch (response.status) {
        case 200:
          return callback(null);
        case 400:
          return callback(response.data);
        default:
          console.error(response.status);
          callback(DefaultError);
      }
    })
    .catch((err) => {
      console.error(err);
      callback(DefaultError);
    });
};

export default AutopayDetails;
