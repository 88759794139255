import React from 'react';
import './form-wrapper.scss';

interface IProps {
  children: any;
  className?: string;
  style?: object;
}

const FormWrapper = (props: IProps) => {
  const { className, children, style } = props;
  return (
    <div
      className={`koalafi-form-wrapper ${!!className ? className : ''}`}
      style={style}
    >
      {children}
    </div>
  );
};

export default FormWrapper;
