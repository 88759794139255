import React, { useState } from 'react';
import Select from 'react-select';
import { FieldProps } from 'formik';
import classnames from 'classnames';
import './form-select.scss';
import { styles } from './form-select-styles';

interface IProps {
  placeholder: string;
  id: string;
  isSearchable: boolean;
  options: any;
  onInputChange?: () => {};
}

const FormSelect: React.FC<FieldProps & IProps> = ({
  field,
  form: { touched, errors, setFieldValue, setTouched, setFieldTouched },
  ...props
}) => {
  const { placeholder, id, isSearchable, onInputChange, options } = props;
  const [optionLabel, setOptionLabel] = useState('');
  const handleOnChange = (v: any, fieldName: string) => {
    setFieldValue(fieldName, v.value);
    setOptionLabel(v.label);
  };
  return (
    <div
      className={classnames(field.name, 'form-select', {
        'select-required':
          field.name !== 'projectType' &&
          errors[field.name] &&
          touched[field.name],
        'select-valid': field.name === 'state' && field.value.length === 2,
      })}
    >
      <Select
        id={id}
        styles={styles({
          required: !!errors[field.name] && !!touched[field.name],
        })}
        value={
          field.value
            ? {
                value: field.value,
                label: optionLabel ? optionLabel : field.value,
              }
            : null
        }
        placeholder={placeholder}
        options={options}
        onChange={(v: any) => handleOnChange(v, field.name)}
        isSearchable={isSearchable}
        onInputChange={onInputChange}
      />
      {errors[field.name] && <span>{errors[field.name]}</span>}
    </div>
  );
};

export default FormSelect;
