import { Product } from './products';

export enum ApplicationStatus {
  Draft = 'draft',
  ErrorsFound = 'errors_found',
  DataRequested = 'data_requested',
  Declined = 'declined',
  Approved = 'approved',
  DocsReady = 'docs_ready',
  Accepted = 'accepted',
  Delivered = 'delivered',
  Funded = 'funded',
  Expired = 'expired',
}

export interface LTOTransition {
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  state: string;
  zip: string;
  city: string;
  dateOfBirth: string;
  monthlyIncome: number;
  email: string;
  mobilePhone: string;
  publicStoreId: string;
  applicationId: string;
  sessionId: string | null;
  socureDeviceSessionId: string | null;
  dealerPortalUrl: string;
  ssn: string;
  tokenizedTaxId?: string;
  routingNumber?: string;
  accountNumber?: string;
}

export interface Application {
  application: {
    address: string;
    city: string;
    dateOfBirth: string;
    lpCustomerId: number;
    dealerId: number;
    vertical?: string;
    subverticals?: string[];
    email: string;
    firstName: string;
    lastName: string;
    mobilePhone: string;
    monthlyIncome: 10000;
    state: string;
    zip: string;
    consent: boolean;
    autopay: boolean;
    accountNumber?: string;
    routingNumber?: string;
    hiProjectType?: HIProjectType;
    homeOwnership?: string;
    HashedSSN?: string;
    tokenizedSsn: string;
    isOtb: boolean;
    id: number;
    publicId?: string;
    customerId?: number;
    dateCreated: string;
    dateUpdated: string;
    loanId?: number;
    orderNumber: string;
    salesPerson: string;
    selectedProductId: number;
    status: ApplicationStatus;
    socureDeviceSessionId?: string;
    tmxSessionId?: string;
    label?: ApplicationLabel;
    acknowledgedBy?: string;
    acknowledgedAt?: string;
    debtFacility?: string;
    decisioningAttempt: number;
    decisionReason?: string;
    preapprovalLine?: PreapprovalLine;
    campaignMedium?: CampaignMedium;
  };
  offeredProducts: Product[];
  statusHistory: StatusHistoryRecord[];
  creditInformation?: CreditInformation;
}

interface StatusHistoryRecord {
  status: ApplicationStatus;
  dateUpdated: string;
}

interface CreditInformation {
  creditScore: string;
  creditSource: string;
  creditBureau: string;
  dateCreated: string;
}

interface PreapprovalLine {
  lineAmount: number;
  lineType: string;
  dateOpened: string;
}

export enum ApplicationLabel {
  TTA = 'tta',
  BM = 'b&m',
  ECommerce = 'e-commerce',
}

export enum CampaignMedium {
  Text = 'text',
  Email = 'email',
  Landing = 'landing',
  Store = 'store',
  Copy = 'copy',
  Storis = 'storis',
  Web = 'web',
  SMS = 'sms',
  ECommerce = 'ecommerce',
  QR = 'qr',
}

enum HIProjectType {
  NewOrReplace = 'new_or_replace',
  ServiceOrRepair = 'service_or_repair',
  CustomerUnsure = 'customer_unsure',
  MiscHIProject = 'misc_hi_project',
}
