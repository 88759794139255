/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormWrapper } from 'components/shared';

const CreditPullAuthCA = () => {
  return (
    <FormWrapper>
      <h2 id="credit-pull-auth-ca--header">Credit Pull Authorization Notice</h2>
      <p>
        {`You understand that by proceeding you authorize 
        West Creek Financial, Inc. dba Koalafi on its own behalf,
        and/or on behalf of The Bank of Missouri to obtain information
        from your credit report or other personal information from one or
        more consumer credit agencies to conduct a pre-qualification for
        our loan credit product.`}
        <br />
        <br />
        {`If you are being considered for our lease-to-own product continuing
        with the application process, you authorize West Creek Financial, Inc.
        dba Koalafi on behalf of banking partners to obtain consumer reports
        and related information about you, now and periodically for as long as
        you remain a customer of Koalafi (or its assignee), from one or more
        consumer reporting agencies.`}
        <br />
        <br />
        {`Pursuant to this authorization, you authorize Koalafi to use your
        consumer reports for any lawful purpose, including but not limited
        to: (1) authenticating your identity; (2) making credit decisions;
        (3) marketing products and services from Koalafi; (4) assisting with
        internal modeling and analysis; (5) servicing your loan; and (6)
        facilitating the sale of your loan or interests associated with your
        loan (or portions thereof) to third party investors, and for related
        purposes. In connection with these purposes, you authorize Koalafi
        to share all or parts of your consumer reports with agents, partners,
        counterparties, or affiliates.`}
        <br />
        <br />
        {`You also authorize Koalafi to verify information in your application,
        and you agree that Koalafi may contact third parties to verify any such
        information.`}
        <br />
        <br />
        {`You authorize and agree that Koalafi may transfer or otherwise provide
        your credit report or any information contained therein to any
        successor-in-interest of Koalafi or any purchaser or servicer of your
        issued loan.`}
      </p>
    </FormWrapper>
  );
};

export default CreditPullAuthCA;
