interface P {
  required: boolean;
}

export const styles = ({ required }: P) => ({
  option: (provided: Object) => ({
    ...provided,
    color: '#2D2926',
    fontSize: '1.375rem',
  }),
  control: (provided: Object) => ({
    ...provided,
    maxHeight: '3.4rem',
    borderRadius: '5px',
    fontSize: '1.375rem',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#0d4066',
    },
    border: `0.16rem solid ${required ? '#ce2543' : '#dddddd'}`,
  }),
  placeholder: (provided: Object) => ({
    ...provided,
    color: '#a3a3a3',
    fontSize: '20px',
  }),
  singleValue: (provided: Object) => ({
    ...provided,
    fontSize: '1.375rem',
    top: '2rem',
    color: '#2D2926',
  }),
  valueContainer: (provided: Object) => ({
    ...provided,
    height: '3.2rem',
    padding: '0 0.5rem',
  }),
});
