import React from 'react';
import './declined.scss';
import { Redirect, useHistory } from 'react-router-dom';
import { ReactComponent as DeclinedRed } from 'assets/icons/declined-red.svg';
import { typedState } from 'store';
import { FormWrapper, Hero } from 'components/shared';
import qs from 'querystring';
import { Queries } from '../../../types/queries';

const Declined = () => {
  const history = useHistory();

  const apps = typedState((state) => state.applications);

  const queries = qs.parse(history.location.search.slice(1)) as Queries;
  if (apps.isApproved) return <Redirect to={`/?${qs.stringify(queries)}`} />;

  return (
    <>
      <Hero />
      <FormWrapper>
        <div className="declined--container">
          <div className="declined--icon">
            <DeclinedRed />
          </div>
          <h2>We're Sorry.</h2>
          <h3>We are unable to pre-qualify you at this time.</h3>
          <p>
            Thank you for considering Koalafi. Unfortunately, we are unable to
            pre-qualify you for our financing options at this time.
            <br />
            <br />
            You may receive a separate notice with more information.
          </p>
        </div>
      </FormWrapper>
    </>
  );
};

export default Declined;
