/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useHistory,
} from 'react-router-dom';
import Approved from 'components/application-flow/approved';
import Declined from 'components/application-flow/declined';
import Ineligible from 'components/application-flow/ineligible';
import ProceedWithDealer from 'components/application-flow/proceed-with-dealer';
import PurchasedAmount from 'components/application-flow/purchased-amount';
import PaymentSchedule from 'components/application-flow/payment-schedule';
import AutopayDetails from 'components/application-flow/autopay-details';
import BankInformation from 'components/application-flow/banking-information';
import ReadyForDelivery from 'components/application-flow/ready-for-delivery';
import TransitionLTO from 'components/application-flow/transition-lto';
import CustomerIdProgram from 'components/disclosures/customer-id-program';
import CreditPullAuth from 'components/disclosures/credit-pull-auth';
import CreditPullAuthCA from 'components/disclosures/credit-pull-auth-ca';
import PreQualificationAuth from 'components/disclosures/prequalification-auth';
import ElectronicCommunications from 'components/disclosures/electronic-communications';
import ElectronicPayments from 'components/disclosures/electronic-payments';
import TermsAndConditions from 'components/disclosures/terms-and-conditions';
import Landing from 'components/application-flow/landing';
import SendPhoneVerification from 'components/application-flow/send-verification-code';
import VerifyPhoneCode from 'components/application-flow/verify-phone-code';
import ProjectType from 'components/application-flow/project-type';
import IdentityConfirmation from 'components/application-flow/identity-confirmation';
import { Modal } from 'components/shared';
import { typedActions, typedState } from 'store';
import { AuthStatus, OOBA } from 'store/models/auth';
import CustomerDetails from 'components/application-flow/customer-details';
import { identify, track } from '../../utils/segment';
import Numeral from 'numeral';
import ReactModal from 'react-modal';
import { PropagateLoader } from 'react-spinners';
import { AxiosError } from 'axios';
import * as querystring from 'querystring';
import qs from 'querystring';
import {
  useCustomerInfo,
  useDealerInformation,
  useExistingApplicantInformation,
} from 'hooks';
import { Queries } from '../../types/queries';
import ErrorModal from '../shared/error-modal';
import { ApplicationFlowType } from '../../store/models/flowType';
import FinanceApplication from '../application-flow/finance-application';
import moment from 'moment';
import PendingHomeownership from 'components/application-flow/pending-homeownership';

const Routes = () => {
  const history = useHistory();
  // state that is responsible for registering handler that prevents go back for specified routes
  const [goback, setGoBack] = useState<boolean>(true);
  // state to handle a single waterfall transition
  const [LTOTransitionSent, setLTOTransitionSent] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const auth = typedState((state) => state.auth);
  const cfg = typedState((state) => state.configs);
  const application = typedState((state) => state.applications);
  const customers = typedState((state) => state.customers);
  const stores = typedState((state) => state.stores);

  // read URL to extract app and dealer IDs
  const queries = qs.parse(history.location.search.slice(1)) as Queries;
  // if populated from unified transfer, use it
  if (application.applicationId) {
    queries.applicationId = `${application.applicationId}`;
  }
  const { dealerId: dealerPublicId, applicationId } = queries;

  // Custom hooks. Fetch all the necessary info for refresh page
  const [appLoading, applicationErrorInfo] = useExistingApplicantInformation(
    applicationId,
  );
  const [dealerInfoLoading, dealerErrorInfo] = useDealerInformation(
    dealerPublicId,
  );
  const [fetchCustomersErr] = useCustomerInfo();

  const { postTransitionLTO } = typedActions((actions) => actions.applications);
  const { setApplicationFlow } = typedActions((actions) => actions.flowType);

  const isDisclosureRoute = [
    '/electronic-payments',
    '/electronic-communications',
    '/credit-pull-auth',
    '/chargeafter-credit-pull-auth',
    '/prequalification-auth',
    '/customer-id-program',
    '/terms-and-conditions',
  ].includes(history.location.pathname);

  // Fire segment event on initial load
  useEffect(() => {
    if (!isDisclosureRoute) track('Application Initiated', {});
  }, []);

  // prevent from going back to phone verification screen if passed verification
  useEffect(() => {
    if (goback) {
      history.listen((newLocation, action) => {
        if (action === 'POP' && newLocation.pathname.includes('/verify')) {
          history.go(1);
        }
      });
    }
    setGoBack(false);
  }, [goback, history]);

  // handle non-submit app waterfall
  useEffect(() => {
    if (!LTOTransitionSent && application.processWaterfall) {
      (async () => {
        const searchParams: {
          [index: string]: string | number;
        } = {
          order: 'desc',
          limit: 1,
        };
        if (customers.customer?.customerId)
          searchParams['customerId'] = customers.customer?.customerId;
        if (customers.customer?.lpCustomerId)
          searchParams['lpCustomerId'] = customers.customer?.lpCustomerId;

        await auth.client
          .get(`/applications/search?${querystring.stringify(searchParams)}`)
          .then(async (applicationResponse) => {
            try {
              let monthlyIncome =
                Math.floor(
                  Numeral(customers.customer?.monthlyIncome).value(),
                ) || 0;
              if (!monthlyIncome && applicationResponse.data.length) {
                monthlyIncome = Math.floor(
                  Numeral(
                    applicationResponse.data[0].applicant.monthlyIncome,
                  ).value(),
                );
              }
              await postTransitionLTO({
                firstName: customers.customer?.firstName || '',
                lastName: customers.customer?.lastName || '',
                address: customers.customer?.address1 || '',
                address2: customers.customer?.address2 || '',
                city: customers.customer?.city || '',
                state: customers.customer?.state || '',
                zip: customers.customer?.zip || '',
                email: customers.customer?.email || '',
                ssn: customers.customer?.ssn || '',
                mobilePhone:
                  customers.customer?.mobilePhone.replace(/\D/g, '') || '',
                monthlyIncome: monthlyIncome,
                dateOfBirth: moment
                  .utc(customers.customer?.dateOfBirth || '')
                  .format('YYYY-MM-DD'),
                dealerPortalUrl: auth.dealerPortalUrl || '',
                publicStoreId: stores.store?.publicId || '',
                applicationId: application.applicationId
                  ? `${application.applicationId}`
                  : '',
                sessionId: auth.tmxSessionId as string | null,
                socureDeviceSessionId: auth.socureDeviceSessionId as
                  | string
                  | null,
                tokenizedTaxId: customers.customer?.tokenizedTaxId || '',
                accountNumber: customers.customer?.accountNumber, // nullable
                routingNumber: customers.customer?.routingNumber, // nullable
              });
              setLTOTransitionSent(true);
            } catch (e) {
              track('Waterfall Error', {});
              console.error(e);
            }
          })
          .catch((e: AxiosError) => {
            console.error(e);
          });
      })();
    }
  }, [application.processWaterfall]);

  // wait for app and dealer data to load
  useEffect(() => {
    if (initialLoad && !dealerInfoLoading && !appLoading) {
      // set up AppFlow before Landing render
      if (!!applicationId) setApplicationFlow(ApplicationFlowType.Resumed);
      else setApplicationFlow(ApplicationFlowType.New);

      // no dependency for auth needed as routes.tsx is only rendered once auth is complete.
      // customer must exist if they are at OOBA stage (will be retrieved by fetchApplication
      // in useExistingApplicantInformation). identify() only needed for OOBA as it only is
      // needed for CFE (Customer First Experience) as dealers already have their own identify()
      // call on the dealer portal
      if (auth.source === OOBA) {
        identify(customers.customer!);
      }

      setInitialLoad(false);
    }
  }, [dealerInfoLoading, appLoading]);

  /* Render loader on:
      1. config load
      2. initial app/dealer info load
      3. waterfall flow
  */
  if (
    !cfg.loaded ||
    (!isDisclosureRoute && initialLoad) ||
    application.processWaterfall
  ) {
    return (
      <ReactModal
        className="koalafi-modal loading-modal"
        closeTimeoutMS={500}
        isOpen={true}
        role="dialog"
        ariaHideApp={false}
      >
        <div className="loader">
          <PropagateLoader color={'#A3A3A3'} />
        </div>
      </ReactModal>
    );
  }

  return (
    <>
      {/* Error modal responsible for displaying fetch dealer info err */}
      {!isDisclosureRoute && <ErrorModal info={dealerErrorInfo} />}
      {/* Error modal responsible for displaying fetch app info err */}
      {!isDisclosureRoute && <ErrorModal info={applicationErrorInfo} />}
      {/* General error modal*/}
      {fetchCustomersErr && (
        <ErrorModal
          info={{
            message: 'Something went wrong.',
            buttonText: 'OK',
            redirectUrl: `https://${auth.dealerPortalUrl}`,
          }}
        />
      )}

      {/* Routes */}
      <Route component={ScrollToTop} />
      <Switch>
        <TrackedRoute exact path="/" component={Landing} />

        <TrackedRoute exact path="/apply" component={FinanceApplication} />
        <TrackedRoute exact path="/approved" component={Approved} />
        <TrackedRoute exact path="/declined" component={Declined} />
        <TrackedRoute exact path="/ineligible" component={Ineligible} />
        <TrackedRoute
          exact
          path="/proceed-with-dealer"
          component={ProceedWithDealer}
        />
        <TrackedRoute exact path="/transition-lto" component={TransitionLTO} />
        <TrackedRoute
          exact
          path="/verification"
          component={SendPhoneVerification}
        />
        <TrackedRoute exact path="/verify" component={VerifyPhoneCode} />
        <TrackedRoute exact path="/project-type" component={ProjectType} />
        <TrackedRoute
          exact
          path="/identity-confirmation"
          component={IdentityConfirmation}
        />
        <TrackedRoute
          exact
          path="/customer-details"
          component={CustomerDetails}
        />
        <TrackedRoute
          exact
          path="/bank-information"
          component={BankInformation}
        />

        <DealerOnlyRoute
          exact
          path="/purchased-amount"
          component={PurchasedAmount}
        />
        <TrackedRoute
          exact
          path="/payment-schedule"
          component={PaymentSchedule}
        />
        <DealerOnlyRoute
          exact
          path="/autopay-details"
          component={AutopayDetails}
        />
        <DealerOnlyRoute
          exact
          path="/ready-for-delivery"
          component={ReadyForDelivery}
        />

        <TrackedRoute
          exact
          path="/customer-id-program"
          component={CustomerIdProgram}
        />
        <TrackedRoute
          exact
          path="/credit-pull-auth"
          component={CreditPullAuth}
        />
        <TrackedRoute
          exact
          path="/chargeafter-credit-pull-auth"
          component={CreditPullAuthCA}
        />
        <TrackedRoute
          exact
          path="/prequalification-auth"
          component={PreQualificationAuth}
        />
        <TrackedRoute
          exact
          path="/electronic-communications"
          component={ElectronicCommunications}
        />
        <TrackedRoute
          exact
          path="/electronic-payments"
          component={ElectronicPayments}
        />
        <TrackedRoute
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <TrackedRoute
          exact
          path="/pending-homeownership"
          component={PendingHomeownership}
        />

        {/* Default Route - Needs to stay at the bottom of the switch statement */}
        <Route render={() => <Redirect to={{ pathname: '/' }} />} />
      </Switch>
    </>
  );
};

const TrackedRoute = (props: RouteProps) => {
  window.analytics.page(props.path);
  console.log('Tracking page: ', props.path);
  return <Route {...props} />;
};

/**
 * Small wrapper of <TrackedRoute /> that enforces dealer authentication
 */
// TODO: Is this necessary anymore as auth is enforced at the App.tsx level?
export const DealerOnlyRoute = (props: RouteProps) => {
  const { authStatus, dealerPortalUrl } = typedState((state) => state.auth);

  switch (authStatus) {
    case AuthStatus.Loading:
    case AuthStatus.Authenticated:
      return <TrackedRoute {...props} />;
    default:
      return (
        <Modal
          isOpen={true}
          message="Authentication failed. Please log in through the dealer portal and try again."
          dismissBtnName="Dealer Portal"
          dismissAction={() =>
            (window.location.href = `https://${dealerPortalUrl}`)
          }
        />
      );
  }
};

/**
 * A small functional component with the sole
 * purpose of scrolling to the top
 */
const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

export default Routes;
