/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import FormWrapper from 'components/shared/form-wrapper';
import Numeral from 'numeral';
import './ready-for-delivery.scss';
import { track } from 'utils/segment';
import { Button, Colors } from '@westcreek/react';
import { typedState } from 'store';
import { Role } from 'store/models/auth';

const ReadyForDelivery = () => {
  const { dealerPortalUrl, isMobile, role } = typedState((state) => state.auth);
  const loan = typedState((state) => state.loans.loan);
  const store = typedState((state) => state.stores.store);

  useEffect(() => {
    if (!isMobile) {
      document
        .querySelector('body')!
        .classList.add('background-body-koalafi-theme');
    }
    return () =>
      document
        .querySelector('body')!
        .classList.remove('background-body-koalafi-theme');
  }, []);

  track('Delivery Ready', {});

  const { borrower, displayId, loanAmount } = loan!;

  return (
    <FormWrapper className={'ready-for-delivery'}>
      <h2>{role === Role.Customer ? 'Done!' : 'Ready for Delivery!'}</h2>
      <p id="ready-for-Delivery--info" className="intro">
        {role === Role.Customer ? (
          <>
            You're all set to proceed with your loan for{' '}
            <span>{Numeral(loanAmount).format('$0,0.00')}</span>. <br />
            Please check your email for more details.
            <br />
            <br />
            Contact {store?.name} to walk you through next steps.
          </>
        ) : (
          <>
            <span>
              {borrower.firstName} {borrower.lastName}
            </span>{' '}
            has approved the terms for loan <span>#{displayId}</span>, in the
            amount of <span>{Numeral(loanAmount).format('$0,0.00')}</span>.{' '}
            Please notify Koalafi upon delivery of merchandise.
          </>
        )}
      </p>
      {role !== Role.Customer && (
        <Button
          id="ready-for-Delivery--submit"
          color={Colors.royalBlue}
          type="button"
          onClick={() => {
            track('Application Completed', {});
            window.location.replace(`https://${dealerPortalUrl}`);
          }}
        >
          Home
        </Button>
      )}
    </FormWrapper>
  );
};

export default ReadyForDelivery;
