/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import Numeral from 'numeral';
import {
  FormCheckbox,
  FormToggle,
  FormWrapper,
  Modal,
} from 'components/shared';
import { Button, Colors } from '@westcreek/react';
import { PaymentScheduleSchema } from 'utils/form-schemas';
import { Loan } from 'types/loans';
import './payment-schedule.scss';
import { IconType } from 'components/shared/modal';
import { formatDate } from 'utils/formatting';
import { OOBA, Role } from 'store/models/auth';
import { agreeToLoan } from './agree-to-loan/agree-to-loan';
import { track } from 'utils/segment';
import { store, typedActions, typedState } from 'store';
import { Queries } from 'types/queries';
import qs from 'querystring';

const PaymentSchedule = () => {
  const history = useHistory();
  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  const app = typedState((state) => state.applications);
  const loan = typedState((state) => state.loans.loan);
  const auth = typedState((state) => state.auth);

  const { acknowledgeApplication } = typedActions(
    (actions) => actions.applications,
  );

  const [acknowledgementModalOpen, setAcknowledgementModalOpen] = useState(
    false,
  );
  const [success, setSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const {
    id,
    termLength,
    loanAmount,
    financeCharge,
    firstPaymentDue,
    paymentAmount,
    scheduleRollsAmount,
    finalPaymentAmount,
    borrower,
    apr,
    creationDate,
    totalOfPayments,
    dealerName,
    deferredInterestPeriod,
    originationFee,
  }: Loan = loan!;

  const displayOrigFee = !!Numeral(originationFee).value();

  const termLengthInt = parseInt(termLength);
  const minDIPaymentAmount =
    (parseFloat(loanAmount) + parseFloat(originationFee)) /
    deferredInterestPeriod;

  // this page (doc signing), serves as OOBA, thus only allow access to this page if OOBA token is verified,
  // user is a privileged user, or OOBA has already been completed
  useEffect(() => {
    if (auth.source !== OOBA && !auth.hasPrivilegedRole) {
      if (!app.acknowledgedBy || auth.role !== Role.Dealer) {
        return history.replace(`/?${qs.stringify(queries)}`);
      } else {
        // For dealers only, we keep them here and show them
        setModalMessage(
          'Customers should sign their own loan agreements and other forms. Customers sign and acknowledge all loan terms, even if completed using a store device or computer.',
        );
      }
    }
  }, []);

  const openLoanDocument = async (
    loanID: number,
    documentType: 'loan-agreement' | 'tila',
  ) => {
    const path = `/loans/${loanID}/documents/${documentType}`;
    const documentName =
      documentType === 'loan-agreement' ? 'Loan Agreement' : 'TILA';
    track(`${documentName} Opened`, {
      additionalProperties: {
        openedFrom: queries.oobaToken === null ? 'SMS' : 'OOBA',
      },
    });

    await auth.client
      .request({
        method: 'GET',
        responseType: 'blob', //Force to receive data in a Blob Format
        url: path,
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: res.headers['content-type'],
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  };

  // Use ScheduleRolls.Amount for an accurate calculation of monthly payments
  // preceding the final monthly payment (if provided).
  // When using LoanPro's smooth payments feature, the paymentAmount is
  // inaccurate, and finalPaymentAmount can be potentially inaccurate. There
  // is no guarantee that the final payment will be equal to all other previous
  // payments, even after smoothing is applied to a loan (an edge condition).
  const monthlyPaymentAmounts = scheduleRollsAmount?.length
    ? scheduleRollsAmount[0]
    : paymentAmount;

  if (!app.isApproved) {
    return <Redirect to={`/?${qs.stringify(queries)}`} />;
  }

  if (success) {
    history.push(`/autopay-details?${qs.stringify(queries)}`);
  }

  return (
    <div className={`payment-schedule`}>
      <Modal
        isOpen={!!modalMessage}
        message={modalMessage}
        dismissBtnName="OK"
        dismissAction={() => setModalMessage('')}
        iconType={IconType.Warning}
      />

      <Modal
        isOpen={acknowledgementModalOpen}
        message="Terms successfully acknowledged."
        dismissBtnName="OK"
        dismissAction={() =>
          window.location.replace(
            `https://${auth.dealerPortalUrl}/DealerCabinet/LoanDetails?loanApplicationId=${app.applicationId}`,
          )
        }
        iconType={IconType.Success}
      />

      <FormWrapper>
        <h2 id="payment-schedule">Contract Summary</h2>
        <div className="tc-box">
          <div className="tc-text">
            <p className={'bio'}>
              Number of Payments: <span>{termLengthInt}</span>
            </p>
            <p className={'bio'}>
              Amount of Payments 1 through {termLengthInt - 1}:{' '}
              <span>{Numeral(monthlyPaymentAmounts).format('$0,0.00')}</span>
            </p>
            <p className={'bio'}>
              Amount of Payment {termLengthInt}:{' '}
              <span>{Numeral(finalPaymentAmount).format('$0,0.00')}</span>
            </p>
            <p className={'bio'}>
              Due Monthly Starting Date:{' '}
              <span>{formatDate(firstPaymentDue)}*</span>
            </p>
            {displayOrigFee && (
              <>
                <hr />
                <p className="sub-head-grid">
                  Origination Fee
                  <span id="payment-schedule--apr">
                    {Numeral(originationFee).format('$0,0.00', Math.floor)}
                  </span>
                </p>
                <p className="sub-text">
                  The cost to originate your loan, which will be added to your
                  principal
                </p>
              </>
            )}
            <hr />
            <p className="sub-head-grid">
              Annual Percentage Rate
              <span id="payment-schedule--apr">
                {Numeral(apr).format('0.00', Math.floor)}% APR
              </span>
            </p>
            <p className="sub-text">{`The cost of your credit as a yearly rate${
              displayOrigFee
                ? ', including interest and the origination fee'
                : ''
            }`}</p>
            <hr />
            <div className="sub-head-grid">
              Finance Charge
              <span id="payment-schedule--finance-charge">
                {Numeral(financeCharge).format('$0,0.00')}*
              </span>
              <p className="sub-text">
                The dollar amount the credit will cost you
              </p>
            </div>
            <hr />
            <div className="sub-head-grid">
              Amount Financed
              <span id="payment-schedule--amount-financed">
                {Numeral(loanAmount).format('$0,0.00')}
              </span>
              <p className="sub-text">
                The amount of credit provided on your behalf
              </p>
            </div>
            <hr />
            <div className="sub-head-grid">
              Total of Payments
              <span id="payment-schedule--total-payments">
                {Numeral(totalOfPayments).format('$0,0.00')}
              </span>
              <p className="sub-text">
                The amount you will have paid when you have made all scheduled
                payments.
              </p>
            </div>
            <hr />
            <div className="sub-head">
              Late payments:
              <p className="sub-text">
                If we do not receive a scheduled payment in full within fifteen
                (15) days after its scheduled due date, then we may charge a
                late fee equal to the greater of $15 dollars or 5% of the amount
                of the payment due. However in no event will the late charge
                exceed $50.
              </p>
            </div>
            <div className="sub-head">
              Prepayment:
              <p className="sub-text">
                If you pay off early, you will not have to pay a penalty, and
                you may be entitled to a refund of part of the finance charge.
              </p>
              <p className="sub-text">
                See your Loan Agreement below for additional information about
                nonpayment, default, any required repayment in full before the
                schedule start date, and prepayment refunds.
              </p>
              <p className="sub-text">
                * This is an estimate based on the merchant processing your
                order on {formatDate(creationDate)}. Your actual finance charge
                will not be more than the dollar amount shown above.
              </p>
            </div>
            <div className="sub-head">
              Itemization of the Amount Financed:
              <p id="payment-schedule--loan-amount" className="sub-text">
                Bank of Missouri will pay{' '}
                {Numeral(loanAmount).format('$0,0.00')} to {dealerName}.
              </p>
            </div>
            <div className="borrower-creditor">
              <div>
                <div className="sub-head-grid">Borrower</div>
                <div id="payment-schedule--borrower-name">{`${borrower.firstName} ${borrower.lastName}`}</div>
                <div id="payment-schedule--borrower-address">
                  {borrower.address1}
                </div>
                <div id="payment-schedule--borrower-city-state-zip">{`${borrower.city}, ${borrower.state} ${borrower.zip}`}</div>
              </div>
              <div>
                <div className="sub-head-grid">Creditor</div>
                {/* TODO (@dlantz): Need real data here */}
                <div id="payment-schedule--creditor-name">
                  Issued by the Bank of Missouri, serviced by Koalafi
                </div>
                <div id="payment-schedule--creditor-address">
                  {'PO Box 4887'}
                </div>
                <div id="payment-schedule--creditor-city-state-zip">{`${'Glen Allen'}, ${'VA'} ${'23058-4887'}`}</div>
              </div>
            </div>
          </div>
        </div>
      </FormWrapper>
      {/* TODO: get deferred interest from payload */}
      {deferredInterestPeriod ? (
        <FormWrapper>
          <h2>Deferred Interest</h2>
          <div className="tc-box deferred-interest">
            <div className="tc-text">
              <p className={'bio'}>
                Deferred Interest Period:{' '}
                <span>{deferredInterestPeriod} Months</span>
              </p>
              <hr />
              <p className={'sub-text'}>
                To avoid paying interest, you must pay the entire loan amount
                before the promotion period ends.
              </p>
              <p className={'sub-text'}>
                To avoid paying interest,{' '}
                {minDIPaymentAmount < parseFloat(paymentAmount) ? (
                  <>
                    make your monthly payments on time within the{' '}
                    {deferredInterestPeriod} month deferred interest period.{' '}
                  </>
                ) : (
                  <>
                    pay{' '}
                    <span>{Numeral(minDIPaymentAmount).format('$0,0.00')}</span>{' '}
                    per month for {deferredInterestPeriod} months.{' '}
                  </>
                )}
                See the{' '}
                <span
                  className={'document-link'}
                  onClick={() => openLoanDocument(id, 'loan-agreement')}
                >
                  Loan Agreement
                </span>{' '}
                for additional payoff options.
              </p>
            </div>
          </div>
        </FormWrapper>
      ) : null}
      {/* Show OOBA Workaround for privileged roles only, else show normal signing */}
      {auth.hasPrivilegedRole ? (
        <FormWrapper>
          <Formik
            initialValues={{}}
            onSubmit={async (_, actions) => {
              try {
                actions.setSubmitting(true);
                await acknowledgeApplication();
                setAcknowledgementModalOpen(true);
              } catch (err) {
                console.error(err);
                setModalMessage(
                  'Could not perform acknowledgment, please try again.',
                );
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form noValidate>
                <p style={{ textAlign: 'center' }}>
                  Review loan details with the customer and receive verbal
                  consent to the terms of agreement prior to acknowledging terms
                  on the customer's behalf.
                </p>
                <Button
                  id="payment-schedule--acknowledge"
                  color={Colors.royalBlue}
                  type="submit"
                  dataLoading={isSubmitting}
                >
                  Acknowledge Terms
                </Button>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      ) : (
        <FormWrapper>
          <Formik
            initialValues={{ agree: false, marketingConsent: false }}
            validationSchema={PaymentScheduleSchema}
            onSubmit={(values, actions) => {
              agreeToLoan(
                actions,
                values.marketingConsent,
                setSuccess,
                setModalMessage,
                store.getState(),
                store.getActions(),
              );
            }}
          >
            {({ isSubmitting, setFieldValue, values, errors }) => (
              <Form noValidate>
                <div className="checkbox-group">
                  <Field
                    id="payment-schedule--agree-to-terms"
                    name="agree"
                    component={FormCheckbox}
                    ariaLabel={'I agree to the terms and conditions'}
                  />
                  <span> I agree to the following terms and conditions:</span>
                  <div className="tc-link-group">
                    <button
                      type="button"
                      className="document-link"
                      onClick={() => openLoanDocument(id, 'tila')}
                    >
                      Truth in Lending
                    </button>
                    <button
                      type="button"
                      className={'document-link'}
                      onClick={() => openLoanDocument(id, 'loan-agreement')}
                    >
                      Loan Agreement
                    </button>
                    <a
                      className={'document-link'}
                      href={'https://koalafi.com/privacy-policy/'}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        track('Privacy Notice Opened', {
                          additionalProperties: {
                            openedFrom:
                              queries.oobaToken === null ? 'SMS' : 'OOBA',
                          },
                        });
                      }}
                    >
                      Privacy Notice
                    </a>
                    <Link
                      to="/credit-pull-auth"
                      target="_blank"
                      onClick={() => {
                        track('CPA Opened', {
                          additionalProperties: {
                            openedFrom:
                              queries.oobaToken === null ? 'SMS' : 'OOBA',
                          },
                        });
                      }}
                    >
                      Credit Pull Authorization
                    </Link>
                  </div>
                </div>
                <div className="marketing-group">
                  <Field
                    id="payment-schedule--marketing"
                    name="marketingConsent"
                    component={FormToggle}
                    checked={false}
                    onClick={() => {
                      setFieldValue(
                        'marketingConsent',
                        !values.marketingConsent,
                      );
                    }}
                  />
                  <p>
                    {`I would like to receive marketing communications from Koalafi, 
                    its merchants, or its service providers. These parties
                    may contact the phone number(s) listed on my application or in
                    my account using pre-recorded or artificial voice via
                    automatic telephone dialing systems or text messages.`}
                  </p>
                </div>
                <p style={{ marginTop: '2rem' }}>
                  After signing, an email will be sent with instructions to
                  access these documents.
                </p>
                <Button
                  id="payment-schedule--submit"
                  color={Colors.royalBlue}
                  type="submit"
                  dataLoading={isSubmitting}
                >
                  Agree to Loan
                </Button>
                <div id="terms-agreement-error">
                  {errors.agree && (
                    <p style={{ color: Colors.strawberry }}>
                      Please agree to the terms and conditions above
                    </p>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      )}
    </div>
  );
};

export default PaymentSchedule;
