/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Numeral from 'numeral';
import { FormWrapper, Hero, Modal } from 'components/shared';
import { Button, Colors } from '@westcreek/react';
import './approved.scss';
import { Redirect, useHistory } from 'react-router-dom';
import { ReactComponent as Checkmark } from 'assets/icons/checkmark.svg';
import { IconType } from 'components/shared/modal';
import { typedState } from 'store';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { track } from '../../../utils/segment';
import { CustomerFlowType } from '../../../store/models/flowType';

const Approved = () => {
  const history = useHistory();

  const [maxLineAmount, setMaxLineAmount] = useState<number>(Number.MIN_VALUE);

  const customerEligibility = typedState(
    (state) => state.customers.eligibility,
  ); // will be null for new customers as eligibility isn't fetched
  const customers = typedState((state) => state.customers);
  const { dealerPortalUrl, source, isMobile } = typedState(
    (state) => state.auth,
  );
  const apps = typedState((state) => state.applications);
  const offeredProducts = typedState((state) => state.products.products);
  const flowType = typedState((state) => state.flowType);

  function isOTB() {
    return (
      customerEligibility?.isOtbEligible &&
      customerEligibility.otbProducts.loanOptions.length > 0
    );
  }

  useEffect(() => {
    let maxLineAmount = Number.MIN_VALUE;
    offeredProducts?.forEach((product: any) => {
      maxLineAmount = Math.max(maxLineAmount, product.lineAmount);
    });
    isOTB() &&
      track('OTB Approved', {
        additionalProperties: { approvalAmount: maxLineAmount },
      });
    setMaxLineAmount(maxLineAmount);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      document
        .querySelector('body')!
        .classList.add('background-body-koalafi-theme');
    }
    return () =>
      document
        .querySelector('body')!
        .classList.remove('background-body-koalafi-theme');
  }, []);

  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  // update query params with app id to support page refresh
  if (apps.applicationId) {
    queries.applicationId = `${apps.applicationId}`;
  }

  if (!apps.isApproved) {
    return <Redirect to={`/?${qs.stringify(queries)}`} />;
  }

  if (!offeredProducts || offeredProducts.length === 0) {
    return (
      <Modal
        isOpen={true}
        iconType={IconType.Warning}
        message="Something went wrong; there are no offered products to display"
        dismissBtnName="Return to Dealer Portal."
        dismissAction={() =>
          window.location.replace(`https://${dealerPortalUrl}`)
        }
      />
    );
  }

  // handle redirect location based on context
  let nextScreen = `/purchased-amount?${qs.stringify(queries)}`;
  if (isOTB()) {
    if (flowType.customerFlow === CustomerFlowType.VerifiedCustomer) {
      nextScreen = `/customer-details?${qs.stringify(queries)}`;
    } else {
      nextScreen = `/identity-confirmation?${qs.stringify(queries)}`;
    }
  }

  return (
    <>
      <Hero reverse={!isMobile} />
      <FormWrapper className={`approved`}>
        <div className="approved--container">
          <div className={`approved--icon`}>
            <Checkmark />
          </div>
          <h2>Congratulations!</h2>
          <h3>
            {customerEligibility &&
              `Welcome back, ${customers.customer?.firstName}.`}
          </h3>
          <p className="approved--msg intro">
            {customerEligibility && <br />}
            You are pre-qualified for a loan for up to{' '}
            <span>{Numeral(maxLineAmount).format('$0,0.00')}</span>.
          </p>
          {isOTB() || source !== 'tta' ? (
            <>
              <Button
                id="approved--submit"
                color={Colors.royalBlue}
                type="submit"
                onClick={() => {
                  isOTB() && track('OTB Approval Accepted', {});
                  history.push(nextScreen);
                }}
              >
                Proceed
              </Button>
            </>
          ) : (
            <>
              <h3>You can now proceed with your financed purchase!</h3>
              <hr />
              <p className="intro">
                Your sales rep will walk you through the next steps.
              </p>
            </>
          )}
        </div>
      </FormWrapper>
    </>
  );
};

export default Approved;
