import { Action, action } from 'easy-peasy';

export enum ApplicationFlowType {
  New = 'New',
  Resumed = 'Resumed',
}

export enum CustomerFlowType {
  NewCustomer = 'NewCustomer', // Customer not found in customer SoR
  SingleCustomer = 'SingleCustomer', // Customer uniquely found in customer SoR or found as multiple and confirmed with last4 + dob
  MultipleCustomers = 'MultipleCustomers', // Multiple customers found in customer SoR with no confirmation yet or multiple matches of last4 + dob
  VerifiedCustomer = 'VerifiedCustomer', // Customer has passed idv check
}

export enum EligibilityResultType {
  Incomplete = 'Incomplete', // The default case, meaninig eligibility check has not been performed
  GenerallyEligible = 'GenerallyEligible',
  OTBEligible = 'OTBEligible',
  Ineligible = 'Ineligible',
  Failure = 'EligibilityFailed', // Could not get eligibility
}

// TODO: set these
export enum SourceFlowType {
  DealerInitiatedFlow = 'RSA', // Retail sales associate
  CustomerInitiatedFlow = 'TTA', // Text to apply
}

export interface FlowTypeModel {
  applicationFlow?: ApplicationFlowType;
  customerFlow?: CustomerFlowType;
  eligibilityResult: EligibilityResultType;

  setApplicationFlow: Action<FlowTypeModel, ApplicationFlowType>;
  setCustomerFlow: Action<FlowTypeModel, CustomerFlowType>;
  setEligibilityResult: Action<FlowTypeModel, EligibilityResultType>;
}

export const initFlowTypeModel = (): FlowTypeModel => ({
  // default applicationFlow that reflects to applicationFlow hasn't been decisioned yet
  applicationFlow: undefined,
  // Defaulting to undefined to identify we haven't fetched the customers info
  customerFlow: undefined,
  eligibilityResult: EligibilityResultType.Incomplete,

  // actions
  setApplicationFlow: action((state, applicationFlow) => {
    state.applicationFlow = applicationFlow;
  }),

  setCustomerFlow: action((state, customerFlow) => {
    state.customerFlow = customerFlow;
  }),

  setEligibilityResult: action((state, eligibilityResult) => {
    state.eligibilityResult = eligibilityResult;
  }),

  // thunks
});
