/* eslint import/no-webpack-loader-syntax: off*/
import React, { useEffect, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import './customer-details.scss';
import {
  FormCheckbox,
  FormSelect,
  FormWrapper,
  Modal,
} from 'components/shared';
import {
  IOTBCustomerDetails,
  OTBCustomerDetailsSchema,
} from 'utils/form-schemas';
import { store, typedActions, typedState } from 'store';
import { Button, Colors, TextField } from '@westcreek/react';
import { STATES } from 'utils/select-menus';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import FadeLoadSpinner from 'components/shared/spinner';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { IconType } from 'components/shared/modal';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { CustomerRecord } from 'types/customers';
import moment from 'moment';
import { AddressLine1Field } from 'components/shared/address-autocomplete-test-field';
import { track } from '../../../utils/segment';
import { ApplicationLabel, CampaignMedium } from '../../../types/applications';
import { HiProjectType } from 'types/projectType';
import { processEmailValidation } from '../../../hooks';

const CustomerDetailsComponent = () => {
  const history = useHistory();

  const auth = typedState((state) => state.auth);
  const { customer } = typedState((state) => state.customers);
  const stores = typedState((state) => state.stores);
  const projectType = typedState((state) => state.projectType);
  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  const [editMode, setEditMode] = useState<boolean>(false);
  const [generalErrorModalOpen, setGeneralErrorModalOpen] = useState(false);

  const { submitOTBApplication } = typedActions(
    (actions) => actions.applications,
  );

  useEffect(() => {
    if (!customer) history.push(`/?${qs.stringify(queries)}`);
    track('Customer Summary Displayed', {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function handleSubmitOTBApplication(
    updatedCustomer: CustomerRecord,
    actions: FormikHelpers<IOTBCustomerDetails>,
  ) {
    // call submit OTB app BFF
    try {
      await submitOTBApplication({
        customer: updatedCustomer,
        dealerInfo: {
          dealerId: stores.store!.dealerId, // useDealerInformation will throw error modal if can't get dealer/store
          vertical: stores.store!.vertical,
          subverticals: stores.store!.subverticals,
          merchantName: stores.store!.name,
        },
        socureDeviceSessionId: auth.socureDeviceSessionId as string | null,
        tmxSessionId: auth.tmxSessionId as string | null,
        hiProjectType: projectType.projectType as HiProjectType | null,
        label: auth.source as ApplicationLabel,
        campaignMedium: auth.campaignMedium as CampaignMedium,
      });
    } catch (error) {
      console.error();
      setGeneralErrorModalOpen(true);
      actions.setSubmitting(false); // let customer try to submit again on error
      return;
    }

    // update query params with app id to support page refresh
    const apps = store.getState().applications;
    if (apps.applicationId) queries.applicationId = `${apps.applicationId}`;

    if (auth.source === 'tta') {
      history.push(`/proceed-with-dealer?${qs.stringify(queries)}`);
    } else {
      history.push(`/purchased-amount?${qs.stringify(queries)}`);
    }
  }

  return (
    <>
      <FormWrapper className={`customer-details`}>
        <h2 id="customer-details--header">Review Your Information</h2>
        <Formik
          initialValues={
            {
              firstName: customer?.firstName,
              lastName: customer?.lastName,
              ssn: customer?.ssn,
              address1: customer?.address1,
              address2: customer?.address2 ? customer?.address2 : '',
              state: customer?.state,
              city: customer?.city,
              zip: customer?.zip,
              email: customer?.email,
              mobilePhone: auth?.verifiedPhone
                ? `(${auth?.verifiedPhone?.slice(
                    0,
                    3,
                  )}) ${auth?.verifiedPhone?.slice(
                    3,
                    6,
                  )}-${auth?.verifiedPhone?.slice(6)}`
                : '',
              dateOfBirth: customer?.dateOfBirth,
              consent: false,
            } as IOTBCustomerDetails
          }
          validationSchema={OTBCustomerDetailsSchema}
          onSubmit={async (
            values: IOTBCustomerDetails,
            actions: FormikHelpers<IOTBCustomerDetails>,
          ) => {
            if (editMode) {
              track('Customer Info Updated', {});
              actions.setSubmitting(false);
              setEditMode(false);
            } else {
              // validate customer email
              const isEmailPassedValidation = await processEmailValidation(
                actions.setFieldError,
                values.email,
              );
              if (!isEmailPassedValidation) return;

              const updatedCustomer: CustomerRecord = {
                // if we don't have customer, we will redirect home so this is safe
                customerId: customer!.customerId,
                dateOfBirth: moment(customer!.dateOfBirth).format('YYYY-MM-DD'),
                billingAddress: customer!.billingAddress!,
                shippingAddress: customer!.shippingAddress!,
                tokenizedTaxId: customer!.tokenizedTaxId!,
                taxIdLast4: customer!.ssn,
                firstName: values.firstName,
                lastName: values.lastName,
                homeAddress: {
                  addressLine1: values.address1,
                  addressLine2: values.address2,
                  city: values.city,
                  state: values.state,
                  zip: values.zip,
                },
                email: values.email,
                mobilePhone: auth.verifiedPhone!,
              };
              await handleSubmitOTBApplication(updatedCustomer, actions);
            }
          }}
        >
          {({ isSubmitting, values, setValues, errors }) => {
            return (
              <Form
                autoComplete={process.env.REACT_APP_AUTOCOMPLETE || 'off'}
                noValidate
              >
                <div>
                  {!editMode && (
                    <EditIcon
                      id={'customer-details-edit-icon'}
                      onClick={() => setEditMode(true)}
                    />
                  )}
                  <div className="customer-details-bio">
                    <div className="column">
                      <div>
                        <p className="label">SSN OR ITIN</p>
                        <p>{`XXX-XX-${customer?.ssn}`}</p>
                      </div>
                      <div>
                        <p className="label">DATE OF BIRTH</p>
                        <p>{customer?.dateOfBirth}</p>
                      </div>
                      <div>
                        <p className="label">MOBILE PHONE</p>
                        <p>
                          {auth?.verifiedPhone?.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            '$1-$2-$3',
                          )}
                        </p>
                      </div>
                    </div>
                    {!editMode && (
                      <div className="column">
                        <div>
                          <p className="label">LEGAL NAME</p>
                          <p>{`${values.firstName} ${values.lastName}`}</p>
                        </div>
                        <div>
                          <p className="label">ADDRESS</p>
                          <p>{`${values.address1} ${values.address2 &&
                            values.address2} ${values.city}, ${values.state} ${
                            values.zip
                          }`}</p>
                        </div>
                        <div>
                          <p className="label">E-MAIL</p>
                          <p>{values.email}</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {editMode && (
                  <>
                    <p
                      id="customer-details--sub-header-contact"
                      className="intro"
                    >
                      Personal Details
                    </p>
                    <Field
                      id="customer-details--firstName"
                      name="firstName"
                      component={TextField}
                      label="Legal First Name"
                      cleaveOptions={{ blocks: [30], delimiter: '' }}
                    />
                    <Field
                      id="customer-details--lastName"
                      name="lastName"
                      component={TextField}
                      label="Legal Last Name"
                      cleaveOptions={{ blocks: [30], delimiter: '' }}
                    />
                    <p
                      id="customer-details--sub-header-contact"
                      className="intro"
                    >
                      Contact Details
                    </p>
                    <AddressLine1Field
                      line1FieldName={'address1'}
                      line2FieldName={'address2'}
                      stateFieldName={'state'}
                      cityFieldName={'city'}
                      zipFieldName={'zip'}
                    />
                    <Field
                      id="customer-details--address2"
                      name="address2"
                      component={TextField}
                      label="Apt / Suite (If Applicable)"
                      cleaveOptions={{ blocks: [30], delimiter: '' }}
                    />
                    <Field
                      id="customer-details--city"
                      className="city"
                      name="city"
                      component={TextField}
                      label="City"
                      cleaveOptions={{ blocks: [30], delimiter: '' }}
                    />
                    <Field
                      id="customer-details--state"
                      name="state"
                      component={FormSelect}
                      options={STATES}
                      placeholder="State"
                      isSearchable={true}
                      // TODO: someone write a comment explaining what this is doing
                      onInputChange={(val: string) =>
                        val.length === 3
                          ? val.toUpperCase().slice(1)
                          : val.toUpperCase()
                      }
                    />
                    <Field
                      id="customer-details--zip"
                      className="zip"
                      type="tel"
                      name="zip"
                      component={TextField}
                      label="ZIP Code"
                      cleaveOptions={{ numericOnly: true, blocks: [5] }}
                    />
                    <Field
                      id="customer-details--email"
                      name="email"
                      component={TextField}
                      label="Email"
                    />
                  </>
                )}
                <div className="consent">
                  <Field
                    id="personal-details--consent"
                    name="consent"
                    ariaLabel="consent"
                  >
                    {({
                      field,
                      form,
                      meta,
                    }: FieldProps<IOTBCustomerDetails>) => {
                      field.onBlur = () => {
                        field.value && track('Terms Acknowledged', {});
                      };
                      return (
                        <FormCheckbox
                          id="personal-details--consent"
                          field={field}
                          form={form}
                          meta={meta}
                        />
                      );
                    }}
                  </Field>
                  <span>
                    I understand and agree to the language contained in:
                  </span>
                </div>
                <div className="consent-links">
                  <Link to="/electronic-communications" target="_blank">
                    Electronic Communications
                  </Link>
                  <Link to="/credit-pull-auth" target="_blank">
                    Credit Pull Authorization
                  </Link>
                  <Link to="/customer-id-program" target="_blank">
                    Customer Identification Program
                  </Link>
                  <a
                    className="tcpa"
                    href={'https://koalafi.com/privacy-policy/'}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Notice
                  </a>
                </div>
                <Button
                  color={Colors.royalBlue}
                  id="customer-details--submit"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <FadeLoadSpinner />
                  ) : editMode ? (
                    'Confirm'
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
      <Modal
        isOpen={generalErrorModalOpen}
        message="Something went wrong."
        dismissBtnName="OK"
        dismissAction={() => setGeneralErrorModalOpen(false)}
        iconType={IconType.Warning}
      />
    </>
  );
};

export default CustomerDetailsComponent;

// https://maps.googleapis.com/maps/api/place/js/AutocompletionService.GetPredictionsJson?1s123%20Some%20Road%20&4sen-US&15e3&21m1&2e1&callback=_xdc_._z2tzvq&key=AIzaSyAYGLlBY1WAmhzFW6D8OBZZEjVh9B_04D8&token=36066
