import { IBankInformation } from '../../../../utils/form-schemas';
import { Modules } from '../../../../store';
import { StateMapper } from 'easy-peasy';
import { FormikErrors } from 'formik';

export const verifyRoutingNumber = (
  values: IBankInformation,
  setValues: (values: IBankInformation) => void,
  errors: FormikErrors<IBankInformation>,
  globalState: StateMapper<Modules>,
) => {
  const axiosClient = globalState.auth.client;
  if (!errors.routingNumber && values.routingNumber) {
    try {
      axiosClient
        .post('/verify-routing-number', {
          routingNumber: values.routingNumber,
        })
        .then(async (response) => {
          const bankName = response.data.bankName;
          setValues({
            ...values,
            bankInstitution: bankName,
          });
        });
    } catch (error) {
      console.error(error);
      setValues({
        ...values,
        bankInstitution: '',
      });
      return;
    }
  } else {
    setValues({
      ...values,
      bankInstitution: '',
    });
  }
};
