import { Action, action, thunk, Thunk } from 'easy-peasy';
import {
  CustomerDetails,
  CustomerRecord,
  EligibilityResponse,
} from 'types/customers';
import { store } from '../index';
import { formatDate } from '../../utils/formatting';
import * as querystring from 'querystring';

export interface CustomerFilters {
  [index: string]: string | boolean | number | undefined;
  includeTaxIdLast4?: boolean;
  customerId?: number;
  name?: string;
  mobilePhone?: string;
  sort?: 'customerId' | 'dateCreated' | 'dateUpdated' | 'name';
  order?: 'asc' | 'desc';
  offset?: number;
  limit?: number;
}

export interface fetchEligibilityRequest {
  [index: string]: string | boolean | number | undefined;
  customerId: number;
  dealerId: number;
}

export interface CustomersModel {
  customer?: CustomerDetails;
  customers?: CustomerDetails[];
  eligibility?: EligibilityResponse;
  couldNotGetEligibility: boolean; // error from /ROE

  setCustomer: Action<CustomersModel, CustomerDetails | undefined>;
  resetCustomer: Action<CustomersModel>;
  setCustomers: Action<CustomersModel, CustomerDetails[] | undefined>;
  setEligibility: Action<CustomersModel, EligibilityResponse>;
  setCouldNotGetEligibility: Action<CustomersModel, boolean>;

  fetchCustomers: Thunk<CustomersModel, CustomerFilters>;
  fetchEligibility: Thunk<CustomersModel, fetchEligibilityRequest>;
  getCustomerById: Thunk<CustomersModel, number>;
}

const fetchCustomers = thunk<CustomersModel, CustomerFilters>(
  async (actions, filters) => {
    const axiosClient = store.getState().auth.client;

    const qs = querystring.stringify(filters);

    await axiosClient
      .get(`/customers?${qs}`)
      .then((response) => {
        const { data: res }: { data: CustomerRecord[] } = response;

        const formattedCustomers: CustomerDetails[] = [];

        res.forEach((customer) => {
          formattedCustomers.push({
            ...customer,
            dateOfBirth: formatDate(customer.dateOfBirth),
            address1: customer.homeAddress?.addressLine1 || '',
            address2: customer.homeAddress?.addressLine2 || '',
            city: customer.homeAddress?.city || '',
            state: customer.homeAddress?.state || '',
            zip: customer.homeAddress?.zip || '',
            ssn: customer.taxIdLast4,
            monthlyIncome: 0,
            lpCustomerId: 0,
          });
        });

        actions.setCustomers(formattedCustomers);
      })
      .catch((error) => {
        throw error;
      });
  },
);

const fetchEligibility = thunk<CustomersModel, fetchEligibilityRequest>(
  async (actions, eligibilityRequest) => {
    const axiosClient = store.getState().auth.client;
    const globalActions = store.getActions();

    const qs = querystring.stringify(eligibilityRequest);

    await axiosClient
      .get(`/eligibility?${qs}`)
      .then((response) => {
        const {
          data: eligibilityResponse,
        }: { data: EligibilityResponse } = response;

        actions.setEligibility(eligibilityResponse);
        actions.setCouldNotGetEligibility(false);

        // only concerned with loan products, set here to use purchased-amount.tsx
        globalActions.products.setProducts(
          eligibilityResponse.otbProducts.loanOptions,
        );

        // set approved to true if OTB eligible so we can re-use approved.tsx component
        globalActions.applications.setApproved(
          eligibilityResponse.isOtbEligible as boolean,
        );
      })
      .catch((error) => {
        actions.setCouldNotGetEligibility(true);
        throw error;
      });
  },
);

const getCustomerById = thunk<CustomersModel, number>(
  async (actions, customerId) => {
    const axiosClient = store.getState().auth.client;

    await axiosClient
      .get(`/customers/${customerId}`)
      .then((response) => {
        const { data: customer }: { data: CustomerRecord } = response;

        actions.setCustomer({
          ...customer, // grab tokenizedTaxId and preset fields
          dateOfBirth: formatDate(customer.dateOfBirth),
          address1: customer.homeAddress?.addressLine1 || '',
          address2: customer.homeAddress?.addressLine2 || '',
          city: customer.homeAddress?.city || '',
          state: customer.homeAddress?.state || '',
          zip: customer.homeAddress?.zip || '',
          ssn: '', // Don't set ssn
          monthlyIncome: 0,
          lpCustomerId: 0,
        });
      })
      .catch((error) => {
        throw error;
      });
  },
);

export const initCustomersModel = (): CustomersModel => ({
  customer: undefined,
  customers: undefined,
  eligibility: undefined,
  couldNotGetEligibility: false,

  // actions
  setCustomer: action((state, customer) => {
    state.customer = customer;
  }),
  resetCustomer: action((state) => {
    state.customer = undefined;
    state.eligibility = undefined;
  }),
  setCustomers: action((state, customers) => {
    state.customers = customers;
  }),
  setEligibility: action((state, eligibilityResponse) => {
    state.eligibility = eligibilityResponse;
  }),
  setCouldNotGetEligibility: action((state, couldNotGetEligibility) => {
    state.couldNotGetEligibility = couldNotGetEligibility;
  }),

  // thunks
  fetchCustomers,
  fetchEligibility,
  getCustomerById,
});
