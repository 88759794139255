/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormWrapper } from 'components/shared';

const PreQualificationAuth = () => {
  return (
    <FormWrapper>
      <h2 id="prequalification-auth--header">
        Prequalification Authorization Notice
      </h2>
      <p>
        {`You understand that by proceeding you authorize West Creek Financial,
        Inc. dba Koalafi on its own behalf, and/or on behalf of The Bank of
        Missouri to obtain information from your credit report or other personal
        information from one or more consumer credit agencies to conduct a
        pre-qualification for all of our financing options.`}
      </p>
    </FormWrapper>
  );
};

export default PreQualificationAuth;
