import React from 'react';
import { ReactComponent as KoalafiPhone } from 'assets/icons/phone-koalafi.svg';
import './footer.scss';

const Footer = () => {
  const params = new URLSearchParams(window.location.search);
  const support = params.get('support');
  const supportPhone = support === 'lto' ? '(844) 937-8275' : '(800) 677-2705';

  return (
    <footer className={'footer'}>
      <div className={'contact-info exception-link'}>
        <div className="icon-wrapper">
          <KoalafiPhone />
        </div>
        <p>
          Customer Support:{' '}
          <a href={`tel:${supportPhone}`} style={{ display: 'inline-flex' }}>
            {supportPhone}
          </a>
        </p>
      </div>
      <div className={'disclosure'}>
        <p>Issued by The Bank of Missouri, serviced by Koalafi</p>
      </div>
      <div className="info-koalafi">
        <p className={'c-address'}>
          Koalafi | PO Box 4887 | Glen Allen, VA 23058-4887
        </p>
        <p className={'info-box'}>All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
