import { Action, action, Thunk, thunk } from 'easy-peasy';
import { store } from '../index';
import { Store } from 'types/stores';

export interface StoresModel {
  store?: Store;

  // TODO: remove this when merchant team enables getting store info by dealer public id
  dealerId?: number;

  setStore: Action<StoresModel, Store>;

  fetchStore: Thunk<StoresModel, number>;

  setDealerId: Action<StoresModel, number>;

  fetchDealerId: Thunk<StoresModel, string>;
}

const fetchStore = thunk<StoresModel, number>(async (actions, storeId) => {
  const axiosClient = store.getState().auth.client;

  await axiosClient
    .get(`/stores/pricing/${storeId}`)
    .then((response) => {
      const { data: store } = response;
      actions.setStore(store);
    })
    .catch((error) => {
      throw error;
    });
});

// TODO: remove this when merchant team enables getting store info by dealer public id
const fetchDealerId = thunk<StoresModel, string>(
  async (actions, dealerPublicId) => {
    const axiosClient = store.getState().auth.client;

    await axiosClient
      .get(`/dealers/${dealerPublicId}`)
      .then((response) => {
        const { data: dealer } = response;
        actions.setDealerId(dealer.id);
      })
      .catch((error) => {
        throw error;
      });
  },
);

export const initStoresModel = (): StoresModel => ({
  store: undefined,

  // actions
  setStore: action((state, store) => {
    state.store = store;
  }),

  // TODO: remove this when merchant team enables getting store info by dealer public id
  setDealerId: action((state, dealerId) => {
    state.dealerId = dealerId;
  }),

  // thunks
  fetchStore,
  fetchDealerId,
});
