import React, { FC, useState } from 'react';
import { Field, FieldProps } from 'formik';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { IOTBCustomerDetails } from '../../../utils/form-schemas';
import { TextField } from '@westcreek/react';
import './address-autocomplete-text-feild.scss';
import {
  AutocompletePrediction,
  GeocoderAddressComponent,
} from './google-autocomplete-types';
import { typedState } from 'store';

interface P {
  line1FieldName: string;
  line2FieldName: string;
  cityFieldName: string;
  stateFieldName: string;
  zipFieldName: string;
  autofocus?: boolean;
}

const grabAddressValue = (
  addressComponents: GeocoderAddressComponent[],
  selectedTypes: string[],
  long?: boolean,
): string => {
  const search = addressComponents.find((el: any) => {
    for (let type of selectedTypes) {
      if (el.types.includes(type)) return true;
    }
    return false;
  });
  return search ? (long ? search.long_name : search.short_name) : '';
};

const AddressLine1Field: FC<P> = ({
  line1FieldName,
  line2FieldName,
  cityFieldName,
  stateFieldName,
  zipFieldName,
  autofocus = false,
}) => {
  const googleApiKey = typedState((state) => state.auth.googleApiKey);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesService({
    apiKey: googleApiKey,
    options: {
      types: ['geocode'],
      componentRestrictions: { country: 'us' },
    },
  });

  const [focus, setFocus] = useState<boolean>(true);

  const handleSelectAddress = (
    selectedPrediction: AutocompletePrediction,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    // Fetch full address info
    placesService.getDetails(
      {
        placeId: selectedPrediction.place_id,
      },
      (place: any) => {
        if (place.address_components) {
          const streetNumber = grabAddressValue(
            place.address_components,
            ['street_number'],
            true,
          );
          const streetName = grabAddressValue(place.address_components, [
            'route',
          ]);
          const city = grabAddressValue(place.address_components, [
            'locality',
            'sublocality',
          ]);
          const stateAbbr = grabAddressValue(place.address_components, [
            'administrative_area_level_1',
          ]);
          const zip = grabAddressValue(place.address_components, [
            'postal_code',
          ]);

          // There are instances where streetNumber is undefined
          setFieldValue(
            line1FieldName,
            `${streetNumber ? streetNumber + ' ' : ''}${streetName}`,
          );
          setFieldValue(cityFieldName, city || '');
          setFieldValue(stateFieldName, stateAbbr || '');
          setFieldValue(zipFieldName, zip || '');
          // always want to clear line2 when selecting an address
          setFieldValue(line2FieldName, '');

          setFocus(false);
        }
      },
    );
  };

  const RenderPrediction: FC<{
    prediction: AutocompletePrediction;
    handleSelect: (prediction: AutocompletePrediction) => void;
  }> = ({ prediction, handleSelect }) => {
    return (
      <div
        id={prediction.id}
        className={'prediction'}
        onClick={() => handleSelect(prediction)}
      >
        <p>{prediction.description}</p>
      </div>
    );
  };

  return (
    <Field id={line1FieldName} name={line1FieldName}>
      {({
        field,
        form,
        meta,
        form: { setFieldValue },
      }: FieldProps<IOTBCustomerDetails>) => {
        // overwrite native onChange event to fetch address predictions
        field.onChange = (e: React.ChangeEvent<any>) => {
          getPlacePredictions({ input: e.target.value });
          if (!focus) setFocus(true);
        };

        return (
          <div className={'address-line-1'}>
            <TextField
              field={field}
              form={form}
              meta={meta}
              label={'Address (No PO Box)'}
              autoFocus={autofocus}
              cleaveOptions={{ blocks: [30], delimiter: '' }}
            />
            {focus && placePredictions.length && (
              <div className={'address-predictions'}>
                {placePredictions.map((prediction) => (
                  <RenderPrediction
                    key={prediction.place_id}
                    prediction={prediction}
                    handleSelect={() => {
                      handleSelectAddress(prediction, setFieldValue);
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export default AddressLine1Field;
