import Numeral from 'numeral';

export const CalcMinMonthly = (
  val: string,
  useStaticFormula: boolean = false,
  deferredLength: number = 0,
  apr: number = 0.2999,
  termLength: number = 36,
  originationFee?: string,
) => {
  const origFee = originationFee ? Number(originationFee) : 0;
  let loanAmount = Number(val.replace(/[$,]/g, ''));
  loanAmount += origFee;
  if (!loanAmount) return '$0.00';

  const format = '$0,0.00';

  if (useStaticFormula) {
    const divisor_6mo = 30.20970064;
    const divisor_12mo = 25.33457901;
    const divisor_18mo = 21.139529137;
    if (deferredLength === 6) {
      return Numeral(loanAmount / divisor_6mo).format(format);
    } else if (deferredLength === 12) {
      return Numeral(loanAmount / divisor_12mo).format(format);
    } else if (deferredLength === 18) {
      return Numeral(loanAmount / divisor_18mo).format(format);
    }
  }

  if (apr === 0) {
    return Numeral(loanAmount / termLength).format(format);
  }

  return Numeral(
    (apr * loanAmount) / (12 * (1 - Math.pow(1 + apr / 12, -termLength))),
  ).format(format);
};

export const CalcNoInterestMonthly = (val: string, deferredLength: number) => {
  const loanAmount = Number(val.replace(/[$,]/g, ''));
  return Numeral((loanAmount / deferredLength).toString()).format('$0,0.00');
};
