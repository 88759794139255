import React from 'react';
import './ineligible.scss';
import { ReactComponent as DeclinedRed } from 'assets/icons/declined-red.svg';

import { FormWrapper, Hero } from 'components/shared';

const Ineligible = () => {
  return (
    <>
      <Hero />
      <FormWrapper>
        <div className="ineligible--container">
          <div className="ineligible--icon">
            <DeclinedRed />
          </div>
          <h2>We're Sorry.</h2>
          <h3>You are ineligible to apply.</h3>
          <p>
            Thank you for considering Koalafi.
            <br />
            Unfortunately you are ineligible to apply at this time.
          </p>
        </div>
      </FormWrapper>
    </>
  );
};

export default Ineligible;
