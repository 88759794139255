import React from 'react';
import { FieldProps } from 'formik';
import classnames from 'classnames';
import './form-checkbox.scss';

interface IProps {
  disabled?: boolean;
  className?: string;
  toolTipText?: string;
  ariaLabel?: string;
  onClick?: any;
  id: string;
}

const FormCheckbox: React.FC<FieldProps & IProps> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  const {
    ariaLabel,
    className = '',
    disabled = false,
    toolTipText,
    onClick,
    id,
  } = props;

  return (
    <>
      <input
        className={classnames(className, 'koalafi-check-button', {
          'koalafi-check-button--required':
            errors[field.name] && touched[field.name],
          'koalafi-check-button--disabled': disabled,
        })}
        id={id}
        type="checkbox"
        {...field}
        aria-label={ariaLabel}
        disabled={disabled}
        onClick={onClick}
        required
      />
      {toolTipText && disabled ? (
        <span data-testid="test-input" className="tooltip-text">
          {toolTipText}
        </span>
      ) : null}
    </>
  );
};

export default FormCheckbox;
