/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react';
import { FormWrapper, Hero, Modal } from 'components/shared';
import { IconType } from 'components/shared/modal';
import './send-verification-code.scss';
import { Button, Colors, TextField } from '@westcreek/react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import FadeLoadSpinner from 'components/shared/spinner';
import {
  SendVerificationCodeSchema,
  SendVerificationCodeVals,
} from 'utils/form-schemas';
import { sendVerificationCode } from './submit/handle-send-verification-code';
import { useHistory } from 'react-router-dom';
import { store, typedActions, typedState } from 'store';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { track } from '../../../utils/segment';
import {
  ApplicationFlowType,
  CustomerFlowType,
} from '../../../store/models/flowType';
import decisionEligibility from '../../../processors/eligibility';

export interface S {
  verificationId?: string;
  phone?: string;
  callRequested?: boolean;
}

const SendVerificationCode: FC = () => {
  const history = useHistory();

  // global state
  const config = typedState((state) => state.configs);
  const { dealerPortalUrl } = typedState((state) => state.auth);
  const customers = typedState((state) => state.customers);
  const flowType = typedState((state) => state.flowType);
  const stores = typedState((state) => state.stores);

  // global state actions
  const { setVerifiedPhone } = typedActions((actions) => actions.auth);
  const { setProcessWaterfall } = typedActions(
    (actions) => actions.applications,
  );

  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  const [state, setState] = useState<S>({});
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [sendCodeFailed, setSendCodeFailed] = useState<boolean>(false);
  const [landlineNoticeOpen, setLandlineNoticeOpen] = useState<boolean>(false);
  const [numOfAttemptsExceeded, setNumOfAttemptsExceeded] = useState<boolean>(
    false,
  );

  const onSubmitClickHandler = async (
    values: SendVerificationCodeVals,
    { setSubmitting }: FormikHelpers<SendVerificationCodeVals>,
  ) => {
    track('Mobile Number Entered', {
      additionalProperties: { mobileNumber: values.phone },
    });
    localStorage.removeItem('subV');
    const submit = async () => {
      await sendVerificationCode({
        values,
        setLandlineNoticeOpen,
        setErrorModalOpen,
        setSendCodeFailed,
        setNumOfAttemptsExceeded,
        setState,
        otpServiceEnabled: config.configs!.otpServiceEnabled!,
        globalState: store.getState(),
      }).catch((error) => {
        console.error(error);
        setErrorModalOpen(true);
      });
      setSubmitting(false);
    };
    await submit();
  };

  /* sets verified phone on global state in case of OTP is down;
  "setVerifiedPhone" action will trigger "useCustomerInfo" hook in routes.tsx which
  fetches all the customers associated with verified phone number*/
  useEffect(() => {
    if (state.verificationId && state.phone) {
      if (state.verificationId === 'skipped') {
        setVerifiedPhone(state.phone.replace(/[^0-9.]/g, ''));
      } else {
        history.push({
          pathname: '/verify',
          search: `?${qs.stringify(queries)}`,
          state: {
            verificationId: state.verificationId,
            phone: state.phone,
            callRequested: state.callRequested,
          },
        });
      }
    }
  }, [state.verificationId, state.phone]);

  // wait for "useCustomerInfo" hook in routes.tsx response. This hook fires only when OTP is down/turned off
  useEffect(() => {
    if (
      // should always be new app already since we only redirect here for new apps
      flowType.applicationFlow === ApplicationFlowType.New &&
      flowType.customerFlow &&
      customers.customers // wait for customers to be fetched
    ) {
      (async () => {
        switch (flowType.customerFlow) {
          case CustomerFlowType.SingleCustomer:
            await decisionEligibility({
              customerId: customers.customer!.customerId, // it is set by "useCustomerInfo" hook for single customer found
              dealerId: stores.store!.dealerId,
            }).then((eligibilityDecisionResult) => {
              const {
                redirectPath,
                processWaterfall,
              } = eligibilityDecisionResult;

              // process waterfall
              if (processWaterfall) setProcessWaterfall(processWaterfall);
              // redirect to new page
              if (redirectPath != null)
                history.push(`${redirectPath}?${qs.stringify(queries)}`);
            });
            break;
          case CustomerFlowType.MultipleCustomers:
            // if multiple customers returned, figure out which current customer is if any
            history.replace(`/identity-confirmation?${qs.stringify(queries)}`);
            break;
          default:
            history.replace(`/apply?${qs.stringify(queries)}`);
            break;
        }
      })();
    }
  }, [customers.customers, flowType.customerFlow]);

  return (
    <>
      <Hero />
      <FormWrapper className={'send-verification-code'}>
        <Modal
          isOpen={errorModalOpen}
          message="Something went wrong."
          dismissBtnName="OK"
          dismissAction={() => setErrorModalOpen(false)}
          iconType={IconType.Warning}
        />
        <Modal
          isOpen={numOfAttemptsExceeded}
          message="We're sorry, but we were unable to validate your identity. You exceeded resend verification limit. Please wait at least 10 minutes and try again."
          dismissBtnName="OK"
          dismissAction={() => {
            window.location.replace(`https://${dealerPortalUrl}`);
          }}
          iconType={IconType.Warning}
        />
        <Modal
          isOpen={landlineNoticeOpen}
          message="Look's like you are using a landline number. Would you like us to call?"
          actionBtnName="CALL ME"
          action={() => {
            let values = { phone: state.phone as string, callRequested: true };
            sendVerificationCode({
              values,
              setLandlineNoticeOpen,
              setErrorModalOpen,
              setSendCodeFailed,
              setNumOfAttemptsExceeded,
              setState,
              otpServiceEnabled: config.configs!.otpServiceEnabled!,
              globalState: store.getState(),
            }).catch((error) => {
              console.error(error);
              setErrorModalOpen(true);
            });
            setLandlineNoticeOpen(false);
          }}
          dismissBtnName="CANCEL"
          dismissAction={() => setLandlineNoticeOpen(false)}
          iconType={IconType.Warning}
        />
        <h2 id="send-verification-code--header">Customer Phone Verification</h2>
        <p>
          {!sendCodeFailed ? (
            'Hello! Enter your mobile phone number to begin your application.'
          ) : (
            <span className={'warning warning'}>
              It looks like submitted phone number may be incorrect. Please
              double-check your input and resubmit.
            </span>
          )}
        </p>
        <Formik
          initialValues={{
            phone: '',
          }}
          validationSchema={SendVerificationCodeSchema}
          onSubmit={onSubmitClickHandler}
        >
          {({ isSubmitting }) => {
            return (
              <Form
                autoComplete={process.env.REACT_APP_AUTOCOMPLETE || 'off'}
                noValidate
              >
                <Field
                  id="send-verification-code--phone"
                  type="tel"
                  name="phone"
                  component={TextField}
                  label="Customer Phone Number"
                  autoFocus={true}
                  cleaveOptions={{
                    blocks: [3, 3, 4],
                    delimiters: ['-'],
                  }}
                />
                <Button
                  id="send-verification-code--submit"
                  type="submit"
                  color={Colors.royalBlue}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <FadeLoadSpinner /> : 'Next Step'}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </>
  );
};

export default SendVerificationCode;
