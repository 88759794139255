/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FormWrapper, Hero } from 'components/shared';
import './pending-homeownership.scss';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Checkmark } from 'assets/icons/pending-checkmark.svg';
import { typedActions, typedState } from 'store';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { track } from '../../../utils/segment';
import { ApplicationStatus } from '../../../types/applications';
import { ApplicationFlowType } from 'store/models/flowType';

const PendingHomeownership = () => {
  const history = useHistory();
  const { isMobile } = typedState((state) => state.auth);
  const app = typedState((state) => state.applications);
  const { setRefresh } = typedActions((actions) => actions.applications);
  const queries = qs.parse(history.location.search.slice(1)) as Queries;
  const flowType = typedState((state) => state.flowType);
  const { setApplicationFlow } = typedActions((state) => state.flowType);

  // update query params with app id to support page refresh
  if (app.applicationId) {
    queries.applicationId = `${app.applicationId}`;
  }

  const pollApplication = async () => {
    setRefresh(true);
  };

  useEffect(() => {
    track('Dynamic App - Homeownership Loaded', {});

    if (!isMobile) {
      document
        .querySelector('body')!
        .classList.add('background-body-koalafi-theme');
    }
    return () =>
      document
        .querySelector('body')!
        .classList.remove('background-body-koalafi-theme');
  }, []);

  // poll every 30 seconds to check if app status has updated and redirect to landing page if it has
  useEffect(() => {
    const timer = setInterval(pollApplication, 30000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (
      !!app.applicationStatus &&
      app?.applicationStatus !== ApplicationStatus.DataRequested
    ) {
      //setting as a resuming app to make sure the landing redirects to the proper page
      if (flowType.applicationFlow !== ApplicationFlowType.Resumed) {
        setApplicationFlow(ApplicationFlowType.Resumed);
      }
      history.replace(`/?${qs.stringify(queries)}`);
      history.push(`/?${qs.stringify(queries)}`);
    }
  }, [app.applicationStatus]);

  return (
    <>
      <Hero reverse={!isMobile} />
      <FormWrapper className={`pending`}>
        <div className="pending--container">
          <div className={`pending--icon`}>
            <Checkmark />
          </div>
          <h2>Pending Additional Verification</h2>
          <h3>Congratulations, you are eligible for a loan!</h3>
          <p className="intro">
            A Koalafi agent will be in touch via phone or email shortly to
            review next steps.
          </p>
          <p className="intro">Thank you for your patience!</p>
        </div>
      </FormWrapper>
    </>
  );
};

export default PendingHomeownership;
