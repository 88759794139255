import React from 'react';
import { FieldProps } from 'formik';
import classnames from 'classnames';
import './form-radio.scss';

interface IProps {
  id: string;
  label: string;
  error: string;
  ariaLabel: string;
  onClick: any;
  lineAmount: any;
}

const FormRadio: React.FC<FieldProps & IProps> = ({
  field: { name, value, onChange },
  id,
  label,
  error,
  ariaLabel,
  onClick,
  lineAmount,
}) => {
  const checked =
    id === (lineAmount ? value.substr(0, value.indexOf(',')) : value);
  return (
    <div className={'koalafi-radio-button'}>
      <div className="radio-button-box">
        <input
          name={name}
          id={id}
          data-testid={id}
          className={classnames({
            'koalafi-radio-button--required': error,
          })}
          value={lineAmount ? [id, lineAmount] : id}
          type="radio"
          checked={checked}
          onChange={onChange}
          aria-label={ariaLabel}
          onClick={onClick}
        />

        {checked && <div className="checked" />}
      </div>
      {label && (
        <label htmlFor={id} dangerouslySetInnerHTML={{ __html: label }}></label>
      )}
    </div>
  );
};

export default FormRadio;
