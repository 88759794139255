import React from 'react';
import { FadeLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { Colors } from '@westcreek/react';

const FadeLoadSpinner = () => {
  const standard = css`
    left: 27px;
  `;

  return <FadeLoader color={Colors.white} css={standard} width={4} />;
};

export default FadeLoadSpinner;
