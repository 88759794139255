/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { ErrorModalInfo } from 'components/shared/error-modal/error-modal';
import { typedActions, typedState } from '../store';
import { AuthStatus } from '../store/models/auth';

/**
 * Custom hook that retrieves data for a given dealer and saves it to application context
 */
export default function useDealerInformation(
  dealerPublicId: string | null,
): [boolean, ErrorModalInfo | null] {
  const { dealerPortalUrl, authStatus, client: axiosClient } = typedState(
    (state) => state.auth,
  );
  const stores = typedState((state) => state.stores);

  const { fetchStore, fetchDealerId } = typedActions(
    (actions) => actions.stores,
  );

  const [errorModalInfo, setErrorModalInfo]: [
    ErrorModalInfo | null,
    any,
  ] = useState(null);
  const [isLoading, setIsLoading] = useState(!!dealerPublicId);

  const displayFetchDealerErr = () => {
    setErrorModalInfo({
      message: 'There was an issue retrieving Dealer Information.',
      buttonText: 'Dealer Portal',
      redirectUrl: `https://${dealerPortalUrl}`,
    });
    setIsLoading(false);
  };

  // fetch dealer data
  useEffect(() => {
    if (dealerPublicId && !stores.dealerId) {
      (async () =>
        await fetchDealerId(dealerPublicId).catch(() =>
          displayFetchDealerErr(),
        ))();
    } else if (!stores.store?.dealerId) {
      // if failed to load
      setIsLoading(false);
      setErrorModalInfo({
        message: 'Please initiate the application from the Dealer Portal.',
        buttonText: 'Dealer Portal',
        redirectUrl: `https://${dealerPortalUrl}`,
      });
    }
  }, [dealerPublicId, dealerPortalUrl, stores.store]);

  // fetch store data
  useEffect(() => {
    // wait for dealer data to be fetched
    if (isLoading && !!stores.dealerId) {
      if (authStatus === AuthStatus.Authenticated)
        axiosClient.interceptors.request.use((config) => {
          config.headers['segment-user-id'] = `dealer-${stores.dealerId}`;
          return config;
        });

      fetchStore(stores.dealerId)
        .catch(() => displayFetchDealerErr())
        .finally(() => setIsLoading(false));
    }
  }, [isLoading, stores.dealerId]);

  return [isLoading, errorModalInfo];
}
