import React, { FC, useEffect } from 'react';
import { FormWrapper } from 'components/shared';
import './identity-confirmation.scss';
import { Button, Colors, TextField } from '@westcreek/react';
import { Field, Form, Formik } from 'formik';
import FadeLoadSpinner from 'components/shared/spinner';
import {
  IdentityConfirmationSchema,
  IdentityConfirmationVals,
} from 'utils/form-schemas';
import { useHistory } from 'react-router-dom';
import { store, typedActions, typedState } from 'store';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { track } from '../../../utils/segment';
import { CustomerFlowType } from 'store/models/flowType';
import getEligibilityRedirectPath, {
  EligibilityDecisionResult,
} from '../../../processors/eligibility-redirect';
import decisionEligibility from '../../../processors/eligibility';
import { CustomerDetails } from 'types/customers';

const IdentityConfirmation: FC = () => {
  const history = useHistory();

  const customers = typedState((state) => state.customers);
  const flowType = typedState((state) => state.flowType);
  const stores = typedState((state) => state.stores);
  const type = typedState((state) => state.projectType);
  const processingWaterfall = typedState(
    (state) => state.applications.processWaterfall,
  );
  const globalActions = store.getActions();

  const { setCustomer, setCustomers } = typedActions(
    (actions) => actions.customers,
  );
  const { setProcessWaterfall } = typedActions(
    (actions) => actions.applications,
  );
  const { setCustomerFlow } = typedActions((actions) => actions.flowType);

  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  // if we got here by mistake (refresh, send back home), empty dependency array ensures this runs only once
  useEffect(() => {
    if (!customers.customers || !customers.customers.length) {
      history.push(`/?${qs.stringify(queries)}`);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FormWrapper className="identity-confirmation">
        <h2 id="identity-confirmation--header">Identity Confirmation</h2>
        <p> Let's confirm your identity</p>
        <Formik
          initialValues={{
            ssn: '',
            dateOfBirth: '',
          }}
          validationSchema={IdentityConfirmationSchema}
          onSubmit={async (values: IdentityConfirmationVals) => {
            track('SSN & DOB Submitted', {});

            const matchedCustomers = customers.customers!.filter(
              (customer) =>
                customer.ssn === values.ssn &&
                customer.dateOfBirth === values.dateOfBirth,
            );

            if (matchedCustomers.length === 0) {
              globalActions.flowType.setCustomerFlow(
                CustomerFlowType.NewCustomer,
              );
              track('Identity Verification Failed', {});
              // WARNING: do not test for customer existence. Test flowType.customerFlow instead
              setCustomer({
                dateOfBirth: values.dateOfBirth,
              } as CustomerDetails); // set dob to be used to prepopulate.

              history.push(`/apply?${qs.stringify(queries)}`);
              return;
            } else if (matchedCustomers.length > 1) {
              // Could not narrow to distinct customer with last 4, let finance-application to pick customer with full ssn
              globalActions.flowType.setCustomerFlow(
                CustomerFlowType.MultipleCustomers,
              );
              track('Identity Verification Indeterminate', {});
              // WARNING: do not test for customer existence. Test flowType.customerFlow instead
              setCustomer({
                dateOfBirth: values.dateOfBirth,
              } as CustomerDetails); // set dob to be used to prepopulate.

              history.push(`/apply?${qs.stringify(queries)}`);
              return;
            }
            // previous two if conditions return, so rest of code is outdented

            let eligibilityDecisionResult: EligibilityDecisionResult;

            // if the flowType (set previously by fetchCustomers) was only one customer,
            // then their eligiblilty has already been checked and is still valid.
            if (
              customers.customer &&
              customers.eligibility &&
              flowType.customerFlow === CustomerFlowType.SingleCustomer
            ) {
              eligibilityDecisionResult = getEligibilityRedirectPath(
                customers.customers!,
                customers.eligibility,
                CustomerFlowType.VerifiedCustomer,
                stores.store!,
                type.projectType,
              );
              await setCustomerFlow(CustomerFlowType.VerifiedCustomer);

              await globalActions.flowType.setEligibilityResult(
                eligibilityDecisionResult.eligibilityResult,
              );
            } else {
              // Otherwise, if there were multiple customers and was narrowed down to one,
              // get that customer's previously checked eligibility
              await setCustomerFlow(CustomerFlowType.VerifiedCustomer);

              const selectedCustomer = matchedCustomers[0];
              setCustomer(selectedCustomer!);
              eligibilityDecisionResult = await decisionEligibility({
                customerId: selectedCustomer.customerId,
                dealerId: stores.store!.dealerId,
              });
            }

            // don't need to keep all the customer results after selecting the current one
            setCustomers(undefined);

            const {
              redirectPath,
              processWaterfall,
            } = eligibilityDecisionResult;

            // process waterfall
            if (processWaterfall) setProcessWaterfall(processWaterfall);
            // redirect to new page
            if (redirectPath != null)
              history.push(`${redirectPath}?${qs.stringify(queries)}`);
          }}
        >
          {({ isSubmitting, isValid }) => (
            <Form
              autoComplete={process.env.REACT_APP_AUTOCOMPLETE || 'off'}
              noValidate
            >
              <Field
                className="field"
                type={'tel'}
                name="ssn"
                component={TextField}
                label="Last 4 of SSN or ITIN (XXXX)"
                autoFocus={true}
                cleaveOptions={{
                  blocks: [4],
                }}
              />
              <Field
                className="field"
                type="tel"
                name="dateOfBirth"
                component={TextField}
                label="Date of Birth (MM/DD/YYYY)"
                cleaveOptions={{
                  date: true,
                  delimiter: '/',
                  datePattern: ['m', 'd', 'Y'],
                }}
              />
              <Button
                color={Colors.royalBlue}
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting || processingWaterfall ? (
                  <FadeLoadSpinner />
                ) : (
                  'Next Step'
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </>
  );
};

export default IdentityConfirmation;
