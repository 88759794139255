import { Role } from 'store/models/auth';
import { CustomerDetails } from 'types/customers';
import { store } from '../store';

interface Payload {
  [index: string]: string | number | undefined | null;
  dealerID?: number;
  storeID?: number;
  storeName?: string;
  sessionId?: string | null;
  socureDeviceSessionId?: string | null;
  applicationId?: number;
  customerId?: number;
  declineReason?: string;
}

export const track = (
  message: string,
  props: {
    additionalProperties?: {
      [index: string]: string | number | undefined;
      declineReason?: string;
      acknowledgedBy?: Role;
      tokenVerified?: string;
      approvalAmount?: number;
      firstName?: string;
      lastName?: string;
      email?: string;
      decision?: string;
      campaignMedium?: string;
    };
  },
) => {
  const { additionalProperties } = props;
  const globalStore = store.getState();

  const payload: Payload = {
    sessionId: globalStore.auth.tmxSessionId,
    socureDeviceSessionId: globalStore.auth.socureDeviceSessionId,
    applicationId: globalStore.applications?.applicationId,
    dealerID: globalStore.stores.store?.dealerId,
    storeID: globalStore.stores.store?.dealerId,
    storeName: globalStore.stores.store?.name,
    customerId: globalStore.customers.customer?.customerId,
    apptype: 'bmloan',
    vertical:
      globalStore.stores.store?.vertical &&
      globalStore.stores.store?.vertical.toUpperCase(),
    ...additionalProperties,
  };

  window.analytics.track(message, payload);
};

export const identify = (customer: CustomerDetails) => {
  // Do not overwrite the identify from the dealer portal,
  // only identify CFE flow, which can be determined by the presence of sessionId
  const userId = !!customer.customerId
    ? 'customer-' + customer.customerId
    : 'customer-unknown';

  window.analytics.identify(userId, {
    name: customer.firstName + ' ' + customer.lastName,
    email: customer.email,
  });
};
