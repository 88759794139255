import * as Yup from 'yup';
import {
  addressRegex,
  alphanumericRegex,
  bankNameRegex,
  currencyPattern,
  emailRegex,
  nameRegex,
  phonePattern,
  simpleRoutingNumberRegex,
  ssnPattern,
  zipPattern,
} from './formatting';
import { HiProjectType } from 'types/projectType';
import Numeral from 'numeral';

export const validateRoutingNumber = (value: string) => {
  if (!value || value.length !== 9) {
    return false;
  }
  let n = 0;
  for (let i = 0; i < value.length; i += 3) {
    n +=
      parseInt(value.charAt(i), 10) * 3 +
      parseInt(value.charAt(i + 1), 10) * 7 +
      parseInt(value.charAt(i + 2), 10);
  }
  return !(n === 0 || n % 10 !== 0);
};

// RSA-FLOW/AUTOPAY-DETAILS
export interface IAutopayDetails {
  autoPay: string;
  routingNumber: string;
  accountNumber: string;
  bankName: string;
}

export const AutopayDetailsSchema: Yup.ObjectSchema<IAutopayDetails> = Yup.object().shape(
  {
    autoPay: Yup.string().required('A radio option is required'),
    routingNumber: Yup.string().when('autoPay', {
      is: 'autopayDetails--autoPay-yes',
      then: Yup.string()
        .required('Please enter a valid Routing Number')
        .test(
          'Routing number validation',
          'Please enter a valid Routing Number',
          (value) => validateRoutingNumber(value!),
        ),
    }),
    accountNumber: Yup.string().when('autoPay', {
      is: 'autopayDetails--autoPay-yes',
      then: Yup.string().required('Please enter Account Number'),
    }),
    bankName: Yup.string().when('autoPay', {
      is: 'autopayDetails--autoPay-yes',
      then: Yup.string()
        .matches(bankNameRegex, 'Please enter a valid Banking Institution Name')
        .required('Please enter Banking Institution Name'),
    }),
  },
) as Yup.ObjectSchema<IAutopayDetails>;

//RSA-FLOW/BANK-INFORMATION
export interface IBankInformation {
  routingNumber: string;
  accountNumber: string;
  bankInstitution: string;
}

export const IBankInformationSchema: Yup.ObjectSchema<IBankInformation> = Yup.object().shape(
  {
    routingNumber: Yup.string()
      .required('Please enter a valid Routing Number')
      .matches(simpleRoutingNumberRegex, 'Please enter a valid Routing Number'),

    accountNumber: Yup.string().required('Please enter Account Number'),
  },
) as Yup.ObjectSchema<IBankInformation>;

// RSA-FLOW/PAYMENT-SCHEDULE
export const PaymentScheduleSchema = Yup.object().shape({
  agree: Yup.boolean().oneOf([true]),
  marketing: Yup.boolean(),
});

// RSA-FLOW/PERSONAL DETAILS
export interface IFinanceApplication {
  firstName: string;
  lastName: string;
  ssn: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  mobilePhone: string;
  monthlyIncome: string;
  dateOfBirth: Date | string;
  consent: boolean;
}

const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() - 18);

export const FinanceApplicationSchema: Yup.ObjectSchema<IFinanceApplication> = Yup.object().shape(
  {
    firstName: Yup.string()
      .matches(
        nameRegex,
        'This entry can only contain letters, spaces or dashes',
      )
      .required('Please enter Legal First Name'),
    lastName: Yup.string()
      .matches(
        nameRegex,
        'This entry can only contain letters, spaces or dashes',
      )
      .required('Please enter Legal Last Name'),
    ssn: Yup.string()
      .matches(ssnPattern, 'Please enter a valid SSN/ITIN')
      .required('Please enter SSN/ITIN'),
    address1: Yup.string()
      .matches(addressRegex, 'Please enter a valid address')
      .required('Please enter Address'),
    address2: Yup.string().matches(
      addressRegex,
      'Please enter a valid address',
    ),
    city: Yup.string()
      .required('Please enter City')
      .matches(
        nameRegex,
        'This entry can only contain letters, spaces or dashes',
      ),
    state: Yup.string().required('Please enter State'),
    zip: Yup.string()
      .required('Please enter ZIP Code')
      .matches(zipPattern, 'Please enter a valid ZIP'),
    email: Yup.string()
      .required('Please enter Email')
      .matches(emailRegex, 'Please enter a valid Email'),
    mobilePhone: Yup.string()
      .required('Please enter Mobile Phone')
      .matches(phonePattern, 'Please enter a valid Mobile Phone'),
    monthlyIncome: Yup.string()
      .required('Please enter Monthly Income')
      .matches(currencyPattern, 'Please enter a valid Net Monthly Income')
      .test(
        'monthlyIncomeString',
        'Error. You must earn a minimum of $1,000 per month.',
        (value) => !!value && Number(value.replace(/[$,]/g, '')) >= 1000,
      ),
    consent: Yup.boolean().oneOf([true]),
    dateOfBirth: Yup.date()
      .required('Please enter Date of Birth')
      .min(new Date('01/01/1900'), 'Please enter a valid date')
      .max(maxDate, 'You must be at least 18 years old to apply.')
      .typeError('Please enter a valid date')
      .transform((value, originalValue) => {
        // this adds an extra layer of validation to the transformation phase
        // dates like "01/" are parsable dates in chrome but undesirable
        if (originalValue.length !== 10) {
          return '';
        } else {
          return value;
        }
      }),
  },
) as Yup.ObjectSchema<IFinanceApplication>;

// RSA-FLOW/PURCHASED AMOUNT
export interface IPurchasedAmount {
  loanAmount: string;
  productId: string;
  orderNumber?: string;
  salesRep?: string;
  lineAmount?: number;
  minimumSpend?: number;
  deferredInterestPeriod: number;
  deferredPaymentPeriod: number;
  merchantDiscountRate: string;
  rate: string;
  termLength: number;
  originationFee: string;
}

export const LowMinimumLoanAmountMessage =
  'The purchased amount does not meet the minimum. Please, enter a valid purchase amount.';

export const PurchasedAmountSchema: Yup.ObjectSchema<IPurchasedAmount> = Yup.object().shape(
  {
    lineAmount: Yup.number(),
    minimumSpend: Yup.number(),
    deferredInterestPeriod: Yup.number(),
    deferredPaymentPeriod: Yup.number(),
    merchantDiscountRate: Yup.string(),
    rate: Yup.string(),
    termLength: Yup.number(),
    loanAmount: Yup.string()
      .required('Please enter Purchased Amount')
      .test('loanAmount', 'Please enter Purchased Amount', (v) => {
        return !!Numeral(v).value();
      })
      .test(
        'loanAmount',
        'The purchase amount cannot be greater than the total line amount. Please, enter a valid purchase amount.',
        function(v) {
          return Numeral(v).value() <= Numeral(this.parent.lineAmount).value();
        },
      )
      .test('loanAmount', LowMinimumLoanAmountMessage, function(v) {
        return Numeral(v).value() >= Numeral(this.parent.minimumSpend).value();
      }),
    productId: Yup.string().test(
      'productId',
      'Please select a deferred interest period to proceed',
      (v) => !!v,
    ),
    orderNumber: Yup.string().matches(
      alphanumericRegex,
      'Please only enter alphanumeric characters',
    ),
    salesRep: Yup.string()
      .test('salesRep', 'Please enter a valid email', (v) => {
        if (v && v.includes('@')) {
          return emailRegex.test(v);
        }
        return true;
      })
      .test(
        'salesRep',
        'Please only enter letters, spaces, dashes, or a valid email',
        (v) => {
          if (v && !v.includes('@')) return nameRegex.test(v);
          return true;
        },
      ),
    originationFee: Yup.string(),
  },
) as Yup.ObjectSchema<IPurchasedAmount>;

// PHONE VERIFICATION FLOW
export const SendVerificationCodeSchema = Yup.object().shape({
  phone: Yup.string()
    .length(12)
    .required('Please enter a valid Phone Number'),
});

export interface SendVerificationCodeVals {
  phone: string;
  callRequested?: boolean;
}

export const VerifyCodeSchema = Yup.object().shape({
  code: Yup.string()
    .length(4)
    .required('Please enter a 4-digit verification code'),
});

export interface VerifyCodeVals {
  code: string;
}

// Select Customer
export const IdentityConfirmationSchema = Yup.object().shape({
  ssn: Yup.string()
    .length(4)
    .required('Please enter the last 4 digits of your SSN'),
  dateOfBirth: Yup.date()
    .required('Please enter Date of Birth')
    .min(new Date('01/01/1900'), 'Please enter a valid date')
    .max(maxDate, 'You must be at least 18 years old to apply.')
    .typeError('Please enter a valid date')
    .transform((value, originalValue) => {
      // this adds an extra layer of validation to the transformation phase
      // dates like "01/" are parsable dates in chrome but undesirable
      if (originalValue.length !== 10) {
        return '';
      } else {
        return value;
      }
    }),
});

export interface IdentityConfirmationVals {
  ssn: string;
  dateOfBirth: string;
}

export const HIProjectTypeSchema = Yup.object().shape({
  projectType: Yup.string().required('Please select an option'),
});

export interface IHIProjectType {
  projectType: HiProjectType;
}

// OTB Customer Details Schema
export interface IOTBCustomerDetails {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  email: string;
  consent: boolean;
}

export const OTBCustomerDetailsSchema: Yup.ObjectSchema<IOTBCustomerDetails> = Yup.object().shape(
  {
    firstName: Yup.string()
      .matches(
        nameRegex,
        'This entry can only contain letters, spaces or dashes',
      )
      .required('Please enter Legal First Name'),
    lastName: Yup.string()
      .matches(
        nameRegex,
        'This entry can only contain letters, spaces or dashes',
      )
      .required('Please, enter Legal Last Name.'),
    address1: Yup.string()
      .matches(addressRegex, 'Please enter a valid address')
      .required('Please enter Address'),
    address2: Yup.string().matches(
      addressRegex,
      'Please enter a valid address',
    ),
    city: Yup.string()
      .required('Please enter City')
      .matches(
        nameRegex,
        'This entry can only contain letters, spaces or dashes',
      ),
    state: Yup.string().required('Please enter State'),
    zip: Yup.string()
      .required('Please, enter ZIP Code.')
      .matches(zipPattern, 'Please enter a valid ZIP'),
    email: Yup.string()
      .required('Please enter Email')
      .matches(emailRegex, 'Please enter a valid Email'),
    consent: Yup.boolean().oneOf([true]),
  },
) as Yup.ObjectSchema<IOTBCustomerDetails>;
