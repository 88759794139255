/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ApplicationStatus } from 'types/applications';
import { Link, Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import Numeral from 'numeral';
import qs from 'querystring';
import { upperCaseFirst } from 'upper-case-first';
import './finance-application.scss';
import {
  FormCheckbox,
  FormSelect,
  FormWrapper,
  Hero,
  Modal,
} from 'components/shared';
import { Button, Colors, TextField } from '@westcreek/react';
import {
  FinanceApplicationSchema,
  IFinanceApplication,
} from 'utils/form-schemas';
import { IconType } from 'components/shared/modal';
import { handleApplicationSubmit } from 'hooks/submit/handle-application-submit';
import { STATES } from 'utils/select-menus';
import { checkExistingCustomer } from './check-existing-customer/check-existing-customer';
import { track } from 'utils/segment';
import { formatPhoneNumber } from 'utils/formatting';
import FadeLoadSpinner from 'components/shared/spinner';
import { store, typedState } from 'store';
import { Queries } from 'types/queries';
import { CustomerDetails } from 'types/customers';
import { AddressLine1Field } from '../../shared/address-autocomplete-test-field';
import {
  ApplicationFlowType,
  CustomerFlowType,
  EligibilityResultType,
} from 'store/models/flowType';
import moment from 'moment';
import ReactModal from 'react-modal';
import { PropagateLoader } from 'react-spinners';

const FinanceApplication = () => {
  const history = useHistory();

  const auth = typedState((state) => state.auth);
  const customer = typedState((state) => state.customers);
  const applications = typedState((state) => state.applications);
  const flowType = typedState((state) => state.flowType);
  const globalActions = store.getActions();

  const [transition, setTransition] = useState<
    | 'declined'
    | 'approved'
    | 'ineligible'
    | 'bank-information'
    | 'waterfall'
    | 'pending-homeownership'
    | undefined
  >(undefined);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [softDeclineWarning, setSoftDeclineWarning] = useState(false);
  const [otbCustomerModalOpen, setOTBCustomerModalOpen] = useState(false);
  const [returningCustomerModalOpen, setReturningCustomerModalOpen] = useState(
    false,
  );
  const [
    ineligibleCustomerModalOpen,
    setIneligibleCustomerModalOpen,
  ] = useState(false);
  const [eligibilityErrorModalOpen, setEligibilityErrorModalOpen] = useState(
    false,
  );
  const [checkingExistingCustomer, setCheckingExistingCustomer] = useState(
    false,
  );

  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  // redirect to OTP flow in case of page refresh for new app
  useEffect(() => {
    if (
      !applications.applicationId &&
      flowType.applicationFlow === ApplicationFlowType.New &&
      !flowType.customerFlow &&
      !queries.unifiedToken
    ) {
      history.replace(`/?${qs.stringify(queries)}`);
    }
  }, []);

  useEffect(() => {
    track('Application Started', {});
  }, []);

  useEffect(() => {
    if (
      applications.applicationStatus === ApplicationStatus.ErrorsFound &&
      applications.decisionReason &&
      !softDeclineWarning
    ) {
      setSoftDeclineWarning(true);
    }
  }, [applications.applicationStatus, softDeclineWarning]);

  var initSSN = '';
  // use all zeros to pass form validation
  if (
    customer.customer?.ssn ||
    auth.unifiedToken?.applicationId ||
    auth.unifiedToken?.customerId
  ) {
    initSSN = '000-00-0000';
    // except in these cases
    if (
      applications?.applicationStatus === ApplicationStatus.ErrorsFound ||
      customer.couldNotGetEligibility
    ) {
      initSSN = '';
    }
  }

  var initDOB = '';
  if (
    customer.customer?.dateOfBirth ||
    auth.unifiedToken?.applicationId ||
    auth.unifiedToken?.customerId
  ) {
    initDOB = customer.customer?.dateOfBirth || '';
    // except in these cases
    if (
      applications?.applicationStatus === ApplicationStatus.ErrorsFound ||
      customer.couldNotGetEligibility
    ) {
      initDOB = '';
    }
  }
  /* We are not handling "lto" transition here since it's happening in background
   on "processWaterfall" dispatch*/
  switch (transition) {
    case 'approved':
      return <Redirect to={`/approved?${qs.stringify(queries)}`} />;
    case 'bank-information':
      return <Redirect to={`/bank-information?${qs.stringify(queries)}`} />;
    case 'declined':
      return <Redirect to={`/declined?${qs.stringify(queries)}`} />;
    case 'ineligible':
      return <Redirect to={`/ineligible?${qs.stringify(queries)}`} />;
    case 'pending-homeownership':
      return (
        <Redirect to={`/pending-homeownership?${qs.stringify(queries)}`} />
      );
  }

  return (
    <>
      <Hero />
      <FormWrapper className="personal-details">
        <Modal
          isOpen={errorModalOpen}
          message="Something went wrong."
          dismissBtnName="OK"
          dismissAction={() => setErrorModalOpen(false)}
          iconType={IconType.Warning}
        />

        <Modal
          isOpen={otbCustomerModalOpen}
          message={`Welcome back, ${customer.customer?.firstName}. You've been pre-approved for a loan. Proceed to see details.`}
          iconType={IconType.Success}
          dismissBtnName="Proceed"
          dismissAction={() =>
            history.replace(`/approved?${qs.stringify(queries)}`)
          }
        />

        <ReactModal
          isOpen={checkingExistingCustomer}
          className="koalafi-modal loading-modal"
        >
          <div className="loader">
            <PropagateLoader color={'#A3A3A3'} />
          </div>
        </ReactModal>

        <Modal
          isOpen={returningCustomerModalOpen && !applications.processWaterfall}
          message={`Welcome back, ${customer.customer?.firstName}. We've pre-filled your information for you, please confirm it is correct.`}
          iconType={IconType.Success}
          dismissBtnName="OK"
          dismissAction={() => setReturningCustomerModalOpen(false)}
        />

        <Modal
          isOpen={ineligibleCustomerModalOpen}
          message={`Welcome back, ${customer.customer?.firstName}. \nUnfortunately you are ineligible for a new loan at this time.`}
          iconType={IconType.Danger}
          dismissBtnName="OK"
          dismissAction={() => history.replace(`/ineligible`)}
          shouldCloseOnOverlayClick={false}
        />

        <Modal
          isOpen={eligibilityErrorModalOpen}
          message={
            "We're having trouble determining your eligibility, please re-enter your SSN and DOB"
          }
          iconType={IconType.Warning}
          dismissBtnName="OK"
          dismissAction={() => setEligibilityErrorModalOpen(false)}
        />
        <h2>
          {flowType.customerFlow === CustomerFlowType.VerifiedCustomer
            ? 'Welcome back!'
            : 'Tell Us About Yourself'}
        </h2>
        {flowType.customerFlow === CustomerFlowType.VerifiedCustomer && (
          <p>
            Please ensure the following information is still correct before
            proceeding.
          </p>
        )}
        <Formik
          initialValues={
            {
              // use 000-00-0000 for returning customer so that it passes schema validation,
              // this value will not actually be used or shown, instead tokenizedTaxId will be used to retrieve SSN
              ssn: initSSN,
              dateOfBirth: initDOB,
              firstName: customer.customer?.firstName || '',
              lastName: customer.customer?.lastName || '',
              address1: customer.customer?.address1 || '',
              address2: customer.customer?.address2 || '',
              state: customer.customer?.state || '',
              city: customer.customer?.city || '',
              zip: customer.customer?.zip || '',
              email: customer.customer?.email || '',
              mobilePhone: auth.verifiedPhone
                ? formatPhoneNumber(auth.verifiedPhone)
                : applications.applicationId && customer.customer?.mobilePhone // returning app only
                ? formatPhoneNumber(customer.customer.mobilePhone)
                : '',
              monthlyIncome: '',
              consent: false,
            } as IFinanceApplication
          }
          validationSchema={FinanceApplicationSchema}
          onSubmit={async (
            values: IFinanceApplication,
            actions: FormikHelpers<IFinanceApplication>,
          ) => {
            values.firstName = upperCaseFirst(values.firstName);
            values.lastName = upperCaseFirst(values.lastName);

            const currentCustomer = {
              ...customer, // Grab extra preset fields such as tokenizedSSN, lpCustomerId
              firstName: values.firstName,
              lastName: values.lastName,
              address1: values.address1,
              address2: values.address2,
              city: values.city,
              state: values.state,
              zip: values.zip,
              email: values.email,
              ssn: values.ssn, // TODO: make sure this isn't just last 4
              mobilePhone: values.mobilePhone,
              monthlyIncome: Math.floor(Numeral(values.monthlyIncome).value()),
              dateOfBirth: moment.utc(values.dateOfBirth).format('YYYY-MM-DD'),
              customerId: customer.customer?.customerId ?? 0,
            } as CustomerDetails;

            await handleApplicationSubmit({
              customer: currentCustomer,
              setSubmitting: actions.setSubmitting,
              setFieldValue: actions.setFieldValue,
              setFieldError: actions.setFieldError,
              setErrorModalOpen,
              setSoftDeclineWarning,
              setTransition,
              globalState: store.getState(),
              globalActions: globalActions,
            });
          }}
        >
          {({ isSubmitting, values, setValues, errors }) => {
            return (
              <Form
                autoComplete={process.env.REACT_APP_AUTOCOMPLETE || 'off'}
                noValidate
              >
                {softDeclineWarning && (
                  <p className="personal-details-sub-header">
                    <span className={`warning`}>
                      We’re sorry, but we can’t validate your identity with the
                      information provided. Please check your SSN/ITIN, Address,
                      and Date of Birth and try again.
                    </span>
                  </p>
                )}
                <p className={'personal-details-sub-header'}>
                  Personal Information
                </p>
                {/* Only for a new customer, multiple customers (could not narrow down based on last 4 of ssn),
                    resumed application (only possible for soft decline), eligibility error, or
                    errors found retries (customer uses the same ssn/dob combo) */}
                {(flowType.customerFlow === CustomerFlowType.NewCustomer ||
                  flowType.customerFlow ===
                    CustomerFlowType.MultipleCustomers ||
                  flowType.applicationFlow === ApplicationFlowType.Resumed ||
                  flowType.eligibilityResult ===
                    EligibilityResultType.Failure ||
                  applications.applicationStatus ===
                    ApplicationStatus.ErrorsFound) && (
                  <Field
                    id="personal-details--ssn"
                    type="tel"
                    className="fs-exclude"
                    name="ssn"
                    component={TextField}
                    mask={true}
                    label="SSN or ITIN"
                    autoFocus={true}
                    cleaveOptions={{
                      blocks: [3, 2, 4],
                      delimiters: ['-'],
                    }}
                    onBlur={() => {
                      // don't try to check for existing customer if the last application was errors found
                      if (
                        applications.applicationStatus !==
                        ApplicationStatus.ErrorsFound
                      ) {
                        checkExistingCustomer(
                          values,
                          setValues,
                          errors,
                          store.getState(),
                          store.getActions(),
                          setOTBCustomerModalOpen,
                          setReturningCustomerModalOpen,
                          setIneligibleCustomerModalOpen,
                          setEligibilityErrorModalOpen,
                          setCheckingExistingCustomer,
                        );
                      }
                    }}
                  />
                )}
                {/* Only for a new customer, multiple customers (could not narrow down based on last 4 of ssn),
                    resumed application (only possible for soft decline), eligibility error, or
                    errors found retries (customer uses the same ssn/dob combo) */}
                {(flowType.customerFlow === CustomerFlowType.NewCustomer ||
                  flowType.customerFlow ===
                    CustomerFlowType.MultipleCustomers ||
                  flowType.applicationFlow === ApplicationFlowType.Resumed ||
                  flowType.eligibilityResult ===
                    EligibilityResultType.Failure ||
                  applications.applicationStatus ===
                    ApplicationStatus.ErrorsFound) && (
                  <Field
                    id="personal-details--dateOfBirth"
                    type="tel"
                    name="dateOfBirth"
                    component={TextField}
                    label="Date of Birth (MM/DD/YYYY)"
                    cleaveOptions={{
                      date: true,
                      delimiter: '/',
                      datePattern: ['m', 'd', 'Y'],
                    }}
                    onBlur={() => {
                      // don't try to check for existing customer if the last application was errors found
                      if (
                        applications.applicationStatus !==
                        ApplicationStatus.ErrorsFound
                      ) {
                        checkExistingCustomer(
                          values,
                          setValues,
                          errors,
                          store.getState(),
                          store.getActions(),
                          setOTBCustomerModalOpen,
                          setReturningCustomerModalOpen,
                          setIneligibleCustomerModalOpen,
                          setEligibilityErrorModalOpen,
                          setCheckingExistingCustomer,
                        );
                      }
                    }}
                  />
                )}
                <>
                  <Field
                    id="personal-details--firstName"
                    name="firstName"
                    component={TextField}
                    label="Legal First Name"
                    cleaveOptions={{ blocks: [30], delimiter: '' }}
                  />
                  <Field
                    id="personal-details--lastName"
                    name="lastName"
                    component={TextField}
                    label="Legal Last Name"
                    cleaveOptions={{ blocks: [30], delimiter: '' }}
                  />
                  <p className="personal-details-sub-header">Home Address</p>
                  <AddressLine1Field
                    line1FieldName={'address1'}
                    line2FieldName={'address2'}
                    stateFieldName={'state'}
                    cityFieldName={'city'}
                    zipFieldName={'zip'}
                  />
                  <Field
                    id="personal-details--address2"
                    name="address2"
                    component={TextField}
                    label="Apt / Suite (If Applicable)"
                    cleaveOptions={{ blocks: [30], delimiter: '' }}
                  />
                  <Field
                    id="personal-details--city"
                    className="city"
                    name="city"
                    component={TextField}
                    label="City"
                    cleaveOptions={{ blocks: [30], delimiter: '' }}
                  />
                  <Field
                    id="personal-details--state"
                    name="state"
                    component={FormSelect}
                    options={STATES}
                    placeholder="State"
                    isSearchable={true}
                    // TODO: someone write a comment explaining what this is doing
                    onInputChange={(val: string) =>
                      val.length === 3
                        ? val.toUpperCase().slice(1)
                        : val.toUpperCase()
                    }
                  />
                  <Field
                    id="personal-details--zip"
                    className="zip"
                    type="tel"
                    name="zip"
                    component={TextField}
                    label="ZIP Code"
                    cleaveOptions={{ numericOnly: true, blocks: [5] }}
                  />
                  <p className="personal-details-sub-header">Monthly Income</p>
                  <Field
                    id="personal-details--monthlyIncome"
                    type="tel"
                    name="monthlyIncome"
                    component={TextField}
                    label="Gross Monthly Income"
                    cleaveOptions={{
                      numeral: true,
                      numeralIntegerScale: 5,
                      prefix: '$',
                      noImmediatePrefix: true,
                      rawValueTrimPrefix: true,
                    }}
                  />
                  <div className="koalafi-info-box">
                    <p className="alimony-disclosure">
                      You do not have to include alimony, child support or
                      separate maintenance income unless you want it considered
                      as a basis for repaying this loan.
                    </p>
                  </div>
                  <p className="personal-details-sub-header">
                    How can we contact you?
                  </p>
                  <Field
                    id="personal-details--mobilePhone"
                    name="mobilePhone"
                    autoComplete="off"
                    component={TextField}
                    label="Mobile Phone"
                    readOnly={true}
                    disabled={true}
                  />
                  <Field
                    id="personal-details--email"
                    name="email"
                    component={TextField}
                    label="Email"
                  />
                  <div className="consent">
                    <Field
                      id="personal-details--consent"
                      name="consent"
                      component={FormCheckbox}
                      ariaLabel="consent"
                    />
                    <span>
                      I understand and agree to the language contained in:
                    </span>
                  </div>
                  <div className="consent-links">
                    <Link
                      to="/electronic-communications"
                      target="_blank"
                      onClick={() => {
                        track('Electronic Communications Opened', {
                          additionalProperties: {
                            openedFrom:
                              queries.oobaToken === null ? 'SMS' : 'OOBA',
                          },
                        });
                      }}
                    >
                      Electronic Communications
                    </Link>
                    <Link
                      to="/prequalification-auth"
                      target="_blank"
                      onClick={() => {
                        track('Prequalification Notice Opened', {});
                      }}
                    >
                      Prequalification Notice
                    </Link>
                    <Link
                      to="/customer-id-program"
                      target="_blank"
                      onClick={() => {
                        track('CIP Opened', {
                          additionalProperties: {
                            openedFrom:
                              queries.oobaToken === null ? 'SMS' : 'OOBA',
                          },
                        });
                      }}
                    >
                      Customer Identification Program
                    </Link>
                    <a
                      className="tcpa"
                      href={'https://koalafi.com/privacy-policy/'}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        track('Privacy Notice Opened', {
                          additionalProperties: {
                            openedFrom:
                              queries.oobaToken === null ? 'SMS' : 'OOBA',
                          },
                        });
                      }}
                    >
                      Privacy Notice
                    </a>
                  </div>
                  <p className="personal-details-note">
                    This qualification check won’t impact your credit score.
                  </p>
                  <Button
                    id="personal-details--submit"
                    data-testid="application-flow-finance-application-submit"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      applications.processWaterfall ||
                      transition === 'waterfall'
                    }
                    color={Colors.royalBlue}
                  >
                    {isSubmitting ||
                    applications.processWaterfall ||
                    transition === 'waterfall' ? (
                      <FadeLoadSpinner />
                    ) : (
                      'Proceed'
                    )}
                  </Button>
                </>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </>
  );
};

export default FinanceApplication;
