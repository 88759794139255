import { Action, action } from 'easy-peasy';
import { Product } from 'types/products';

export interface ProductsModel {
  products?: Product[];

  setProducts: Action<ProductsModel, Product[]>;
}

const sortProducts = (a: Product, b: Product): number => {
  const aLength = a.termLength + a.deferredPaymentPeriod;
  const bLength = b.termLength + b.deferredPaymentPeriod;

  // treat no-no product rates as 29.99 (to reflect the value shown in UI)
  const aRate =
    a.deferredInterestPeriod && a.deferredPaymentPeriod
      ? 29.99
      : parseFloat(a.rate);
  const bRate =
    b.deferredInterestPeriod && b.deferredPaymentPeriod
      ? 29.99
      : parseFloat(b.rate);

  // sort by MDR from lowest to highest (best loans for dealers at the top)
  // otherwise, sort the rows hierarchically based on the visible columns
  // by rate (desc), then DI period (asc), and then loan length (asc)
  if (
    parseFloat(a.merchantDiscountRate) !== parseFloat(b.merchantDiscountRate)
  ) {
    return (
      parseFloat(a.merchantDiscountRate) - parseFloat(b.merchantDiscountRate)
    );
  } else if (aRate !== bRate) {
    return bRate - aRate; // descending
  } else if (a.deferredInterestPeriod !== b.deferredInterestPeriod) {
    return a.deferredInterestPeriod - b.deferredInterestPeriod; // ascending
  } else {
    return aLength - bLength; // ascending
  }
};

export const initProductsModel = (): ProductsModel => ({
  products: undefined,

  // actions
  setProducts: action((state, products) => {
    if (products) {
      products.sort(sortProducts);
    }
    state.products = products;
  }),
});
