/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorModalInfo } from 'components/shared/error-modal/error-modal';
import { useEffect, useState } from 'react';
import { typedActions, typedState } from '../store';
import { FetchAppErr } from 'store/models/applications';
import { CustomerFlowType } from '../store/models/flowType';
import { ApplicationStatus } from '../types/applications';

/**
 * Retrieves existing application (and maybe loan) data,
 * saving it to application context
 */
export default function useExistingApplicantInformation(
  applicationId: string | null,
): [boolean, ErrorModalInfo | null] {
  const [loading, setLoading] = useState(!!applicationId);
  const [errorModalInfo, setErrorModalInfo]: [
    ErrorModalInfo | null,
    any,
  ] = useState(null);

  const app = typedState((state) => state.applications);
  const auth = typedState((state) => state.auth);
  const customer = typedState((state) => state.customers.customer);
  const { fetchApplication, setRefresh } = typedActions(
    (actions) => actions.applications,
  );
  const { fetchLoan } = typedActions((actions) => actions.loans);
  const { setCustomerFlow } = typedActions((actions) => actions.flowType);

  // fetch application data
  useEffect(() => {
    if (
      (app.refresh && app.applicationId) || // re-fetch app data if app data changed
      (applicationId && !customer) // only run this in absence of customer
    ) {
      setLoading(true);
      (async () => {
        await fetchApplication(
          applicationId ? applicationId : `${app.applicationId}`,
        ).catch((e: Error) => {
          switch (e.message) {
            case FetchAppErr.InsufficientPreapprovalBalance:
              setErrorModalInfo({
                message:
                  'There is insufficient preapproval balance for an additional loan.',
                buttonText: 'Dashboard',
                redirectUrl: `https://${auth.dealerPortalUrl}/DealerCabinet/Dashboard`,
              });
              break;
            case FetchAppErr.Unauthorized:
              setErrorModalInfo({
                message: 'There was an issue retrieving loan information.',
                buttonText: 'Continue',
                redirectUrl: `https://${auth.dealerPortalUrl}`,
              });
              break;
            default:
              setErrorModalInfo({
                message: 'There was an issue retrieving your application.',
                buttonText: 'Dealer Portal',
                redirectUrl: `https://${auth.dealerPortalUrl}`,
              });
              break;
          }
        });
      })();
    }
  }, [applicationId, setLoading, app.refresh]);

  // fetch loan data
  useEffect(() => {
    // wait for application data to load
    if (loading && app.applicationId) {
      if (app.applicationStatus === ApplicationStatus.ErrorsFound) {
        if (customer?.accountNumber && customer?.routingNumber) {
          // set customer flow to customerVerified for resuming a bav requested app (which has a prereq of passing idv)
          setCustomerFlow(CustomerFlowType.VerifiedCustomer);
        } else {
          // have not veried customer yet because errors_found with no bank info is an idv errors_found
          setCustomerFlow(CustomerFlowType.SingleCustomer);
        }
      } else {
        // set customer flow to customerVerified for returning app
        setCustomerFlow(CustomerFlowType.VerifiedCustomer);
      }

      // only fetch loan data if app has an attached loan_id
      if (app.loanId) {
        (async () => {
          await fetchLoan(app.loanId!)
            .catch(() => {
              setErrorModalInfo({
                message: 'There was an issue retrieving loan information.',
                buttonText: 'Continue',
                redirectUrl: `https://${auth.dealerPortalUrl}`,
              });
            })
            .finally(() => {
              app.refresh && setRefresh(false);
              setLoading(false);
            });
        })();
      } else {
        app.refresh && setRefresh(false);
        setLoading(false);
      }
    }
  }, [loading, app.applicationId]);

  return [loading, errorModalInfo];
}
