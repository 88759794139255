import React, { useEffect, useRef } from 'react';
import { FormWrapper } from 'components/shared';
import './transition-lto.scss';
import { ReactComponent as BankIcon } from 'assets/icons/bank.svg';
import { ReactComponent as PhotoIdIcon } from 'assets/icons/photo-id.svg';
import { ReactComponent as EmploymentIcon } from 'assets/icons/employment.svg';
import { ReactComponent as DebitIcon } from 'assets/icons/debit.svg';
import { typedState } from 'store';
import { Button } from '@westcreek/react';

const TransitionLTO = () => {
  const auth = typedState((state) => state.auth);
  const stores = typedState((state) => state.stores);
  const app = typedState((state) => state.applications);
  const customer = typedState((state) => state.customers.customer);
  const ltoForm = useRef<HTMLFormElement>(null);

  useEffect(() => {
    ltoForm.current && ltoForm.current.submit();
  }, []);

  if (customer) {
    return (
      <div className="transition" style={{ display: 'none' }}>
        <FormWrapper>
          <h3>
            Almost there! We just need a few more pieces of information. Have
            the following handy:
          </h3>
          <div className="transition--icon-group">
            <span id="transition-icon--photoId">
              <PhotoIdIcon />
              <span className="transition--icon-text">Government ID</span>
            </span>
            <span id="transition-icon--name-address">
              <EmploymentIcon />
              <span className="transition--icon-text">Employment Info</span>
            </span>
            <span id="transition-icon--bank-details">
              <BankIcon />
              <span className="transition--icon-text">Bank Details</span>
            </span>
            <span id="transition-icon--phone-email">
              <DebitIcon />
              <span className="transition--icon-text">Initial Payment</span>
            </span>
          </div>
          <form
            action={`https://${auth.dealerPortalUrl}/ApplicationForm/Waterfall/`}
            method="post"
            ref={ltoForm}
          >
            <input
              readOnly
              type="text"
              name="firstName"
              value={customer.firstName}
            />
            <input
              readOnly
              type="text"
              name="lastName"
              value={customer.lastName}
            />
            <input readOnly type="text" name="ssn" value={customer.ssn} />
            <input
              readOnly
              type="text"
              name="address"
              value={customer.address1}
            />
            <input
              readOnly
              type="text"
              name="address2"
              value={customer.address2}
            />
            <input readOnly type="text" name="state" value={customer.state} />
            <input readOnly type="text" name="zip" value={customer.zip} />
            <input readOnly type="text" name="city" value={customer.city} />
            <input
              readOnly
              type="text"
              name="dateOfBirth"
              value={customer.dateOfBirth}
            />
            <input
              readOnly
              type="text"
              name="monthlyIncome"
              value={customer.monthlyIncome}
            />
            <input readOnly type="text" name="email" value={customer.email} />
            <input
              readOnly
              type="text"
              name="mobilePhone"
              value={customer?.mobilePhone?.replace(/\D/g, '')}
            />
            <input
              readOnly
              type="text"
              name="publicStoreId"
              value={stores.store?.publicId}
            />
            <input
              readOnly
              type="text"
              name="applicationId"
              value={app.applicationId || ''}
            />
            <input
              readOnly
              type="text"
              name="sessionId"
              value={auth.tmxSessionId || ''}
            />
            <input
              readOnly
              type="text"
              name="socureDeviceSessionId"
              value={auth.socureDeviceSessionId || ''}
            />
            <Button type="submit">Continue</Button>
          </form>
        </FormWrapper>
      </div>
    );
  } else {
    return null;
  }
};

export default TransitionLTO;
