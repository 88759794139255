import { Action, action, Helpers, thunk, Thunk } from 'easy-peasy';
import {
  ApplicationLabel,
  ApplicationStatus,
  CampaignMedium,
  LTOTransition,
} from 'types/applications';
import { store } from '../index';
import { ErrorCode, instanceOfWcError } from 'utils/errors';
import { AxiosError } from 'axios';
import { CustomerRecord, EligibilityResponse } from 'types/customers';
import { track } from '../../utils/segment';
import { Role } from './auth';
import { HiProjectType } from 'types/projectType';
import { formatDate } from 'utils/formatting';

const isApprovedSet = new Set<ApplicationStatus>([
  ApplicationStatus.Approved,
  ApplicationStatus.DocsReady,
  ApplicationStatus.Accepted,
  ApplicationStatus.Delivered,
]);

export interface submitOTBApplicationRequest {
  customer: CustomerRecord;
  dealerInfo: {
    dealerId: number;
    vertical: string;
    subverticals: string[];
    merchantName: string;
  };
  label: ApplicationLabel;
  socureDeviceSessionId: string | null;
  tmxSessionId: string | null;
  hiProjectType?: HiProjectType | null;
  campaignMedium: CampaignMedium;
}

export interface SubmitOTBApplicationResponse {
  applicationId: number;
  lpCustomerId: number;
  eligibility: EligibilityResponse;
}

export interface ApplicationsModel {
  isApproved?: boolean;
  applicationStatus?: ApplicationStatus;
  applicationId?: number;
  loanId?: number;
  refresh: boolean;
  processWaterfall: boolean;
  acknowledgedBy?: Role;
  debtFacility?: string;
  decisionReason?: string | null;
  homeOwnership?: string | null;

  setApproved: Action<ApplicationsModel, boolean>;
  setApplicationStatus: Action<ApplicationsModel, ApplicationStatus>;
  setApplicationId: Action<ApplicationsModel, number>;
  setLoanId: Action<ApplicationsModel, number>;
  setRefresh: Action<ApplicationsModel, boolean>;
  setProcessWaterfall: Action<ApplicationsModel, boolean>;
  setAcknowledgedBy: Action<ApplicationsModel, Role>;
  setDebtFacility: Action<ApplicationsModel, string>;
  setDecisionReason: Action<ApplicationsModel, string | null>;
  setHomeOwnership: Action<ApplicationsModel, string | null>;

  fetchApplication: Thunk<ApplicationsModel, string>;
  submitOTBApplication: Thunk<ApplicationsModel, submitOTBApplicationRequest>;
  postTransitionLTO: Thunk<ApplicationsModel, LTOTransition>;
  transitionUnifiedUI: Thunk<ApplicationsModel, string>;
  acknowledgeApplication: Thunk<
    ApplicationsModel,
    void,
    Helpers<ApplicationsModel, {}, any>
  >;
}

export enum FetchAppErr {
  UnexpectedError = '0',
  UnableToFetchLoan = '1',
  InsufficientPreapprovalBalance = '2',
  Unauthorized = '3',
}

const fetchApplication = thunk<ApplicationsModel, string>(
  async (actions, applicationId) => {
    const axiosClient = store.getState().auth.client;
    const globalActions = store.getActions();

    await axiosClient
      .get(`/applications/${applicationId}`)
      .then(async (applicationResponse) => {
        const isApproved = isApprovedSet.has(
          applicationResponse.data.application.status,
        );

        actions.setApplicationStatus(
          applicationResponse.data.application.status,
        );
        actions.setLoanId(applicationResponse.data.application.loanId);
        actions.setAcknowledgedBy(
          applicationResponse.data.application.acknowledgedBy,
        );
        actions.setDebtFacility(
          applicationResponse.data.application.debtFacility,
        );
        actions.setDecisionReason(
          applicationResponse.data.application.decisionReason,
        );
        actions.setHomeOwnership(
          applicationResponse.data.application.homeOwnership,
        );

        // applicant has a mapping in bff/applications.ts in getApplication()
        globalActions.customers.setCustomer({
          ...applicationResponse.data.customer,
          dateOfBirth: formatDate(
            applicationResponse.data.customer.dateOfBirth,
          ),
          address1:
            applicationResponse.data.customer.homeAddress?.addressLine1 || '',
          address2:
            applicationResponse.data.customer.homeAddress?.addressLine2 || '',
          city: applicationResponse.data.customer.homeAddress?.city || '',
          state: applicationResponse.data.customer.homeAddress?.state || '',
          zip: applicationResponse.data.customer.homeAddress?.zip || '',
          monthlyIncome:
            applicationResponse.data.application.applicant.monthlyIncome,
          lpCustomerId:
            applicationResponse.data.application.applicant.lpCustomerId,
          accountNumber:
            applicationResponse.data.application.applicant.accountNumber,
          routingNumber:
            applicationResponse.data.application.applicant.routingNumber,
        });

        if (applicationResponse.data.application.hiProjectType) {
          globalActions.projectType.setProjectType(
            applicationResponse.data.application.hiProjectType,
          );
        }

        globalActions.products.setProducts(
          applicationResponse.data.application.offeredProducts,
        );
        actions.setApproved(isApproved);

        // this should happen last DO NOT MOVE; setting applicationId triggers
        // "useExistingApplicationInfo" hook to fetch associated loan data if any
        actions.setApplicationId(applicationResponse.data.application.id);
      })
      .catch((e: AxiosError) => {
        const errBody = e.response?.data;
        if (errBody && instanceOfWcError(errBody)) {
          switch (errBody.errorCode) {
            case ErrorCode.InsufficientCredit:
              throw new Error(FetchAppErr.InsufficientPreapprovalBalance);
          }
        }

        switch (e.response?.status) {
          case 403:
            throw new Error(FetchAppErr.Unauthorized);
          default:
            throw new Error(FetchAppErr.UnexpectedError);
        }
      });
  },
);

const submitOTBApplication = thunk<
  ApplicationsModel,
  submitOTBApplicationRequest
>(async (actions, submitOTBAppRequest) => {
  const axiosClient = store.getState().auth.client;
  const globalActions = store.getActions();

  await axiosClient
    .post('/submit-otb-application', submitOTBAppRequest)
    .then((response) => {
      const {
        data: submitOTBAppResponse,
      }: { data: SubmitOTBApplicationResponse } = response;

      globalActions.customers.setEligibility(submitOTBAppResponse.eligibility);

      // only concerned with loan products, set here to use purchased-amount.tsx
      globalActions.products.setProducts(
        submitOTBAppResponse.eligibility.otbProducts.loanOptions,
      );

      // update customer info in store
      let {
        homeAddress,
        firstName,
        lastName,
        email,
      } = submitOTBAppRequest.customer;
      globalActions.customers.setCustomer({
        ...store.getState().customers.customer!,
        ...submitOTBAppRequest.customer,
        address1: homeAddress?.addressLine1 || '',
        address2: homeAddress?.addressLine2 || '',
        city: homeAddress?.city || '',
        state: homeAddress?.state || '',
        zip: homeAddress?.zip || '',
        lpCustomerId: submitOTBAppResponse.lpCustomerId,
      });

      // set approved to true if OTB eligible so we can re-use approved.tsx component
      actions.setApproved(submitOTBAppResponse.eligibility.isOtbEligible!);

      actions.setApplicationStatus(ApplicationStatus.Approved);
      actions.setApplicationId(submitOTBAppResponse.applicationId);
      actions.setDebtFacility('forbright');
      track('Application Submitted', {
        additionalProperties: {
          firstName,
          lastName,
          email,
        },
      });
    })
    .catch((error) => {
      throw error;
    });
});

const postTransitionLTO = thunk<ApplicationsModel, LTOTransition>(
  async (actions, ltoTransition) => {
    const axiosClient = store.getState().auth.client;

    // This is here for the returning customer flow because the customers-service
    // doesn't pass back the full ssn, so we need to detokenize it manually
    // until RAPID can support the detokenization
    if (ltoTransition.tokenizedTaxId) {
      const response = await axiosClient.post('/detokenize-tax-id', {
        tokenizedTaxId: store.getState().customers.customer?.tokenizedTaxId,
      });
      ltoTransition.ssn = response.data.taxId;
    }

    var form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = `https://${ltoTransition.dealerPortalUrl}/ApplicationForm/Waterfall/`;
    Object.keys(ltoTransition).forEach((key) => {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = String(ltoTransition[key as keyof LTOTransition]);
      form.appendChild(input);
    });
    form.submit();
  },
);

const transitionUnifiedUI = thunk<ApplicationsModel, string>(
  async (_, publicDealerId) => {
    window.location.replace(
      `${process.env.REACT_APP_UNIFIED_APP_URL}?dealerPublicId=${publicDealerId}`,
    );
  },
);

const acknowledgeApplication = thunk<
  ApplicationsModel,
  void,
  Helpers<ApplicationsModel, {}, any>
>(async (actions, _, { getState }) => {
  const axiosClient = store.getState().auth.client;

  const role = store.getState().auth.role;
  if (!role) {
    throw Error('role not set');
  }

  await axiosClient
    .post(`/applications/${getState().applicationId}/acknowledgment`, {
      acknowledgedBy: role,
    })
    .then(() => {
      actions.setAcknowledgedBy(role);
      track('OOBA Acknowledged', {
        additionalProperties: {
          acknowledgedBy: role,
        },
      });
    })
    .catch((error) => {
      throw error;
    });
});

export const initApplicationsModel = (): ApplicationsModel => ({
  isApproved: undefined,
  applicationStatus: undefined,
  applicationId: undefined,
  loanId: undefined,
  refresh: false,
  processWaterfall: false,
  debtFacility: undefined,
  decisionReason: undefined,
  homeOwnership: undefined,

  // actions
  setApproved: action((state, isApproved) => {
    state.isApproved = isApproved;
  }),
  setApplicationStatus: action((state, appStatus) => {
    state.applicationStatus = appStatus;
  }),
  setApplicationId: action((state, appId) => {
    state.applicationId = appId;
  }),
  setRefresh: action((state, refresh) => {
    state.refresh = refresh;
  }),
  setLoanId: action((state, loanId) => {
    state.loanId = loanId;
  }),
  setProcessWaterfall: action((state, processWaterfall) => {
    state.processWaterfall = processWaterfall;
  }),
  setAcknowledgedBy: action((state, acknowledgedBy) => {
    state.acknowledgedBy = acknowledgedBy;
  }),
  setDebtFacility: action((state, debtFacility) => {
    state.debtFacility = debtFacility;
  }),
  setDecisionReason: action((state, decisionReason) => {
    state.decisionReason = decisionReason;
  }),
  setHomeOwnership: action((state, homeOwnership) => {
    state.homeOwnership = homeOwnership;
  }),

  // thunks
  fetchApplication,
  submitOTBApplication,
  postTransitionLTO,
  transitionUnifiedUI,
  acknowledgeApplication,
});
