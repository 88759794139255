import React, { FunctionComponent } from 'react';
import Numeral from 'numeral';
import { Product } from 'types/products';
import { CalcMinMonthly } from 'utils/calculations';
import { OriginationsFeesResponse } from '../../../types/request-response';

type ColumnBodyProps = {
  loanAmount?: string;
  ap: Product;
  of?: OriginationsFeesResponse;
};

const NoNoInterestRate = '29.99';

export const DeferredInterestTemplate: FunctionComponent<ColumnBodyProps> = ({
  ap,
}) => {
  return <td>{ap.deferredInterestPeriod}</td>;
};

export const TermLengthTemplate: FunctionComponent<ColumnBodyProps> = ({
  ap,
}) => {
  return <td>{ap.termLength + ap.deferredPaymentPeriod}</td>;
};

export const RateTemplate: FunctionComponent<ColumnBodyProps> = ({ ap }) => {
  const rate =
    ap.deferredInterestPeriod > 0 && ap.deferredPaymentPeriod > 0
      ? NoNoInterestRate
      : ap.rate;
  return <td>{rate}</td>;
};

export const MinimumPaymentBodyTemplate: FunctionComponent<ColumnBodyProps> = ({
  ap,
  loanAmount,
  of,
}) => {
  let columnValue;
  if (ap.deferredPaymentPeriod === 0 && ap.deferredInterestPeriod === 0) {
    columnValue = 'N/A';
  } else if (ap.deferredPaymentPeriod > 0 && ap.deferredInterestPeriod > 0) {
    columnValue = '$0.00';
  } else {
    columnValue = CalcMinMonthly(
      loanAmount || '',
      false,
      ap.deferredPaymentPeriod,
      parseFloat(ap.rate) / 100,
      ap.termLength,
      of && of.originationFee,
    );
  }

  return <td>{columnValue}</td>;
};

export const MaximumPurchaseBodyTemplate: FunctionComponent<ColumnBodyProps> = ({
  ap,
}) => {
  return <td>{Numeral(ap.lineAmount).format('$0,0.00')}</td>;
};

export const OriginationFeeTemplate: FunctionComponent<ColumnBodyProps> = ({
  of,
}) => {
  let columnValue;
  if (!of) columnValue = '-';
  else if (!of.originationFee) columnValue = '$0.00';
  else columnValue = Numeral(of.originationFee).format('$0,0.00');

  return <td>{columnValue}</td>;
};
