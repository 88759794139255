import { IAutopayDetails } from '../../../../utils/form-schemas';
import { Modules } from '../../../../store';
import { StateMapper } from 'easy-peasy';
import { WcError } from 'utils/errors';
import { FormikHelpers } from 'formik';

export const dupPaymentProfileMessage =
  'We already have bank details associated with your customer profile. Click CONTINUE to set-up autopay with the bank account we have on file or call (800) 677-2705 to update your account information';

export const setBankName = (
  values: IAutopayDetails,
  setValues: (values: IAutopayDetails) => void,
  globalState: StateMapper<Modules>,
) => {
  const axiosClient = globalState.auth.client;
  try {
    axiosClient
      .post('/verify-routing-number', {
        routingNumber: values.routingNumber,
      })
      .then(async (response) => {
        const bankName = response.data.bankName;
        setValues({
          ...values,
          bankName: bankName,
        });
      });
  } catch (error) {
    console.error(error);
    setValues({
      ...values,
      bankName: '',
    });
    return;
  }
};

export const processError = (
  error: WcError,
  setModalMessage: any,
  setDupModalMessage: any,
  actions: FormikHelpers<{
    autoPay: string;
    routingNumber: string;
    accountNumber: string;
    bankName: string;
  }>,
) => {
  switch (error.errorCode) {
    case 0:
      setModalMessage(error.userText);
      break;
    case 1:
      actions.setFieldError(
        'routingNumber',
        'Please enter a valid Routing Number',
      );
      break;
    case 2:
      actions.setFieldError(
        'accountNumber',
        'Please enter a valid Account Number',
      );
      break;
    case 21:
      setDupModalMessage(dupPaymentProfileMessage);
      break;
    default:
      console.error(error.developerText);
      setModalMessage('Something went wrong.');
  }
};
