enum DeclineReason {
  IDVSoftDecline = 'IDVSoftDecline',
  BAVSoftDeclineInvalid = 'BAVSoftDeclineInvalid',
  BAVSoftDeclineSavings = 'BAVSoftDeclineSavings',
  AllowLongAppLease = 'AllowLongAppLease',
  BAVDataRequested = 'BAVDataRequested',
  HomeDataRequested = 'HomeDataRequested',
}

export { DeclineReason };
