import axios, { AxiosInstance } from 'axios';
import { v1 as uuidv1 } from 'uuid';

export const createAxios = (
  options: { headers: object } = { headers: {} },
): AxiosInstance => {
  let baseURL = window.location.origin + '/api';

  if (process.env.REACT_APP_ENV === 'local') {
    baseURL = 'http://localhost:8080/api';
  }
  const client = axios.create({ baseURL, headers: options.headers });
  client.interceptors.request.use((config) => {
    config.headers['Correlation-ID'] = uuidv1();
    return config;
  });
  return client;
};
