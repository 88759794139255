import React, { FC } from 'react';
import { ReactComponent as KoalafiLogo } from 'assets/icons/koalafi-logo.svg';
import { ReactComponent as KoalafiLogoReverse } from 'assets/icons/koalafi-logo-reverse.svg';
import './hero.scss';

interface P {
  reverse?: boolean;
}

const Hero: FC<P> = ({ reverse }) => {
  return (
    <div className={`intro-section ${reverse ? 'reverse' : ''}`}>
      {reverse ? <KoalafiLogoReverse /> : <KoalafiLogo />}
      <h3>Fast & Flexible Financing</h3>
    </div>
  );
};

export default Hero;
