export interface WcError {
  developerText: string;
  userText?: string;
  errorCode?: number;
}

export function instanceOfWcError(object: any): object is WcError {
  return object && object.developerText && object.errorCode;
}

export const DefaultError = {
  developerText: 'Something went wrong.',
  userText: 'Something went wrong.',
  errorCode: 0,
} as WcError;

/**
 * DO NOT MODIFY existing error codes.
 */
export enum ErrorCode {
  AgentEmail = 25,
  Identity = 30,
  LoanAlreadyAttached = 32,
  InsufficientCredit = 33,
  CustomerNotEligible = 35,
  InvalidSSN = 36,
  CustomerOnlyLeaseEligible = 37,
  LowMinimumLoanAmount = 38,
}
