import { Action, action, thunk, Thunk } from 'easy-peasy';
import { Loan } from 'types/loans';
import { store } from '../index';

export interface LoansModel {
  loan?: Loan;

  setLoan: Action<LoansModel, Loan>;

  fetchLoan: Thunk<LoansModel, number>;
}

const fetchLoan = thunk<LoansModel, number>(async (actions, loanId) => {
  const axiosClient = store.getState().auth.client;

  await axiosClient
    .get(`/loans/${loanId}`)
    .then((loanResponse) => {
      actions.setLoan(loanResponse.data);
    })
    .catch((error) => {
      throw error;
    });
});

export const initLoansModel = (): LoansModel => ({
  loan: undefined,

  // actions
  setLoan: action((state, loan) => {
    state.loan = loan;
  }),

  //thunks
  fetchLoan,
});
