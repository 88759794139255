import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Modal from 'react-modal';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ErrorBoundary from 'ErrorBoundary';
import { StoreProvider } from 'easy-peasy';
import { store } from './store';

Modal.setAppElement(document.getElementById('root') as HTMLElement);
ReactDOM.render(
  <StoreProvider store={store}>
    <ErrorBoundary>
      <Router>
        <Route component={App} />
      </Router>
    </ErrorBoundary>
  </StoreProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
