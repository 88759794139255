import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { SendVerificationCodeVals } from 'utils/form-schemas';
import { S } from '../send-verification-code';
import { Modules } from 'store';
import { StateMapper } from 'easy-peasy';
import { track } from 'utils/segment';

interface P {
  values: SendVerificationCodeVals;
  setErrorModalOpen: Dispatch<SetStateAction<boolean>>;
  setState: Dispatch<SetStateAction<S>>;
  setSendCodeFailed: Dispatch<SetStateAction<boolean>>;
  setNumOfAttemptsExceeded: Dispatch<SetStateAction<boolean>>;
  setLandlineNoticeOpen: Dispatch<SetStateAction<boolean>>;
  otpServiceEnabled: boolean;
  globalState: StateMapper<Modules>;
}

interface GenerateVerificationCodeBody {
  phoneNumber: string;
  channel?: 'sms' | 'call';
}

interface ResponseOk {
  status: string;
  verificationId: string;
  dateCreated: string;
}

export const sendVerificationCode = async (props: P) => {
  let {
    values,
    setLandlineNoticeOpen,
    setErrorModalOpen,
    setSendCodeFailed,
    setState,
    setNumOfAttemptsExceeded,
    otpServiceEnabled,
    globalState,
  } = props;

  track('Verification Code Sent', {});

  const axiosClient = globalState.auth.client;

  // case when we want to prevent call to otp(service is down)
  if (!otpServiceEnabled) {
    setState({
      verificationId: 'skipped',
      phone: values.phone,
      callRequested: false,
    });
    return;
  }

  const formattedPhoneNumber = values.phone.replaceAll('-', '');
  const channel = values.callRequested ? 'call' : 'sms';

  return await axiosClient
    .post('/one-time-password/generate', {
      phoneNumber: formattedPhoneNumber,
      channel: channel,
    } as GenerateVerificationCodeBody)
    .then((response: AxiosResponse) => {
      const responseBody = response.data as ResponseOk;
      if (responseBody.status === 'pending') {
        track('Verification Code Validated', {});
        setState({
          verificationId: responseBody.verificationId,
          phone: values.phone,
          callRequested: values.callRequested,
        });
      } else {
        track('Verification Code Error', {});
        throw new Error(
          `unprocessable response status: ${responseBody.status}`,
        );
      }
    })
    .catch((error) => {
      switch (error.response.status) {
        case 422:
          if (error.response.data.code === 2002) {
            setState({ phone: values.phone });
            setLandlineNoticeOpen(true);
          } else {
            setSendCodeFailed(true);
          }
          return;
        case 429:
          setNumOfAttemptsExceeded(true);
          return;
        default:
          setErrorModalOpen(true);
      }
    });
};
