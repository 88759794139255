import moment from 'moment';

/**
 * Regex Patterns
 */
export const currencyPattern = /^[$]?[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/;
export const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
export const phonePattern = /^(\(\d{3}\) \d{3}-\d{4}|\d{10})$/;
export const zipPattern = /^\d{5}$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)\S{7,}$/;
export const nameRegex = /^[a-z]+((['-\s][a-z])?[a-z ]*)*$/i;
export const simpleRoutingNumberRegex = /^\d{9}$/;
export const addressRegex = /^[0-9a-z.\- ]+$/i;
export const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const alphanumericRegex = /^[a-zA-Z0-9_]*$/;
export const bankNameRegex = /^[A-Za-z0-9"#&'()*+,./\- ]+/;

/**
 * parses date string
 * output: mm/dd/yyyy
 */
export const formatDate = (dateString: string) => {
  return moment(dateString).format('MM/DD/YYYY');
};

/**
 * formats "0000000000" like phone number to "(000) 000-0000"
 */
export const formatPhoneNumber = (pn: string) => {
  return `(${pn.slice(0, 3)}) ${pn.slice(3, 6)}-${pn.slice(6)}`;
};
