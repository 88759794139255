// Information captured by input fields
// plus the LoanPro customer ID.
// Also aligns with the 'applicant' section

import { Product } from './products';

// represents customer info that we track in the UI. Matches the applicant/application model in app-service
export interface CustomerDetails {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  dateOfBirth: string;
  ssn: string; // this will be last 4 only if found customer by phone #
  mobilePhone: string;
  email: string;
  monthlyIncome: number;
  customerId: number; // customers service
  lpCustomerId?: number; // loans service (loanpro)
  billingAddress?: Address | null; // home address used as main address in forms
  shippingAddress?: Address | null; // home address used as main address in forms
  tokenizedTaxId?: string; // this will only be present if found customer by phone #
  accountNumber?: string; // used for BAV data and autopay autofill if opted in
  routingNumber?: string; // used for BAV data and autopay autofill if opted in
}

export interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
}

// represents a customer record from the customer service
export interface CustomerRecord {
  customerId: number;
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  tokenizedTaxId: string;
  taxIdLast4: string;
  homeAddress?: Address | null;
  billingAddress?: Address | null;
  shippingAddress?: Address | null;
}

export interface EligibilityResponse {
  isEligible: boolean;
  eligibilityCode: EligibilityCode;
  eligibilityReason: string;
  isLeaseEligible: boolean;
  leaseCode?: LeaseCode;
  leaseReason?: string;
  isLoanEligible?: boolean;
  loanCode?: LoanCode;
  loanReason?: string;
  isOtbEligible?: boolean;
  otbCode: OtbCode;
  otbReason: string;
  otbProducts: OtbProducts;
}

export interface OtbProducts {
  loanOptions: Product[];
  leaseOptions: Object[]; // leaving as object until App UI uses Lease Products
}

export enum EligibilityCode {
  newCustomer = 1,
  eligibile,
  appInDraftOrErrorsFound,
  appDOBMismatch,
  appInProgress,
  appDeclinedRecently,
}

export enum OtbCode {
  notApplicable, // 0
  hasOpenLine,
  returningCustomer,
  noCurrentOrRecentProducts,
  openLineTooSmall,
  nonZeroFlagCount,
  lastPaymentNotCleared,
  noOverlappingProducts,
  // TODO add more codes from risk team once they are finished
}

export enum LeaseCode {
  notApplicable = 0,
  eligible = 1,
}

export enum LoanCode {
  notApplicable = 0,
  eligible = 1,
  loanAppHardDeclinedRecently = 2,
}
