import {
  CustomerDetails,
  EligibilityResponse,
  OtbCode,
} from '../types/customers';
import {
  CustomerFlowType,
  EligibilityResultType,
} from '../store/models/flowType';
import { HiProjectType } from '../types/projectType';
import { Store } from '../types/stores';

export interface EligibilityDecisionResult {
  redirectPath: string | null;
  eligibilityResult: EligibilityResultType;
  processWaterfall: boolean;
}

const getEligibilityRedirectPath = (
  customers: CustomerDetails[],
  customerEligibility: EligibilityResponse,
  customerFlow: CustomerFlowType,
  store: Store,
  projectType?: HiProjectType,
): EligibilityDecisionResult => {
  let redirectPath = null;
  let eligibilityResult: EligibilityResultType;
  let processWaterfall: boolean = false;

  if (
    customerEligibility.isOtbEligible &&
    customerEligibility.otbProducts.loanOptions.length > 0
  ) {
    // customer now ready to confirm details before getting OTB loan
    eligibilityResult = EligibilityResultType.OTBEligible;

    /* if single customer, already showed them the approved screen and
    now ready to confirm details before getting OTB loan;
    customers.length check insures that the verifiedCustomer flowType comes from
    MultipleCustomers flow and the customer was matched and verified.*/
    if (
      customerFlow === CustomerFlowType.VerifiedCustomer &&
      customers.length === 1
    ) {
      redirectPath = '/customer-details';
    } else {
      // this will also apply to MultipleCustomers found and customer matched
      redirectPath = '/approved';
    }
  } else if (
    !customerEligibility.isEligible ||
    customerEligibility.otbCode === OtbCode.openLineTooSmall
  ) {
    eligibilityResult = EligibilityResultType.Ineligible;

    // verify the customer before send them to ineligible
    if (customerFlow !== CustomerFlowType.VerifiedCustomer) {
      redirectPath = '/identity-confirmation';
    } else {
      // send to ineligible page if customer is completely ineligible
      redirectPath = '/ineligible';
    }

    // waterfall customer
  } else if (customerEligibility?.isLoanEligible === false) {
    eligibilityResult = EligibilityResultType.Ineligible;

    // waterfall customer if lease eligible but loan ineligible
    if (store.financingType !== 'loan_only') {
      if (
        projectType === HiProjectType.NewOrReplace ||
        store.vertical !== 'home_improvement'
      ) {
        processWaterfall = true;
      } else {
        redirectPath = '/ineligible';
      }
    } else {
      redirectPath = '/ineligible';
    }
  } else {
    eligibilityResult = EligibilityResultType.GenerallyEligible;

    // single customer found and customer generally eligible
    if (customerFlow !== CustomerFlowType.VerifiedCustomer) {
      redirectPath = '/identity-confirmation';
    } else {
      // customer is generally eligible and can apply with a new app
      redirectPath = '/apply';
    }
  }

  return { redirectPath, eligibilityResult, processWaterfall };
};

export default getEligibilityRedirectPath;
