import { AxiosError } from 'axios';
import moment from 'moment';
import {
  IPurchasedAmount,
  LowMinimumLoanAmountMessage,
} from 'utils/form-schemas';
import { FormikHelpers } from 'formik';
import { ErrorCode, instanceOfWcError } from 'utils/errors';
import { ActionsMapperType, Modules } from 'store';
import { Dispatch, SetStateAction } from 'react';
import { Loan } from 'types/loans';
import { ActionMapper, StateMapper } from 'easy-peasy';
import { track } from '../../../../utils/segment';

export interface ICreateLoanProps {
  values: IPurchasedAmount;
  actions: FormikHelpers<IPurchasedAmount>;
  setErrorModal: Dispatch<SetStateAction<boolean>>;
  setGeneralErrorModalOpen: Dispatch<SetStateAction<boolean>>;
  setOobaSentModalOpen: Dispatch<SetStateAction<boolean>>;
  globalState: StateMapper<Modules>;
  globalActions: ActionMapper<Modules, ActionsMapperType>;
}
export function createLoan(props: ICreateLoanProps) {
  const {
    actions,
    values,
    setErrorModal,
    setGeneralErrorModalOpen,
    setOobaSentModalOpen,
    globalState,
    globalActions,
  } = props;

  track('Loan Selected', {
    additionalProperties: {
      promoPeriod: values.deferredInterestPeriod,
      deferredPaymentPeriod: values.deferredPaymentPeriod,
      termLength: values.termLength,
      lineAmount: values.lineAmount,
      apr: values.rate,
      productId: values.productId,
      originationFee: values.originationFee,
    },
  });

  actions.setSubmitting(true);
  const { client: axiosClient } = globalState.auth;
  const { setLoan } = globalActions.loans;

  axiosClient
    .post('/loans', generateLoanRequest(values, globalState))
    .then((res: { data: Loan }) => {
      setLoan(res.data);
      if (globalState.customers?.customer != null) {
        globalState.customers.customer.lpCustomerId = res.data.borrower.id;
      }
      // OOBA links are now sent asynchronously via a lambda whenever a loan app enters docs_ready state
      // after successful creation of a loan, it will be in a docs_ready state and the lambda will be triggered,
      // so we can set the oobaSentModalOpen value to true
      setOobaSentModalOpen(true);
    })
    .catch((error: AxiosError) => {
      const errBody = error.response?.data;
      if (errBody && instanceOfWcError(errBody)) {
        actions.setSubmitting(false);
        switch (errBody.errorCode) {
          case ErrorCode.LoanAlreadyAttached:
            setErrorModal(true);
            break;
          case ErrorCode.InsufficientCredit:
            actions.setFieldError(
              'loanAmount',
              'There is insufficient remaining credit for a loan of this amount.',
            );
            break;
          case ErrorCode.LowMinimumLoanAmount:
            actions.setFieldError('loanAmount', LowMinimumLoanAmountMessage);
            break;
          default:
            setGeneralErrorModalOpen(true);
            actions.setSubmitting(false);
            console.error(error);
        }
      } else {
        setGeneralErrorModalOpen(true);
        actions.setSubmitting(false);
        console.error(error);
      }
    });
}

export const generateLoanRequest = (
  values: IPurchasedAmount,
  globalState: StateMapper<Modules>,
) => {
  const loanAmountString = values.loanAmount.replace(/[$,]/g, '');
  const currentDate = moment.utc();
  const firstPaymentMonths =
    values.deferredPaymentPeriod > 1 ? values.deferredPaymentPeriod : 1;

  const stores = globalState.stores;
  const app = globalState.applications;
  const customer = globalState.customers.customer;

  return {
    loan: {
      loanAmount: loanAmountString,
      apr: values.rate,
      termLength: values.termLength.toString(),
      contractDate: currentDate.format('YYYY-MM-DD'),
      firstPaymentDue: currentDate
        .add(firstPaymentMonths, 'month')
        .format('YYYY-MM-DD'),
      originationFee: values.originationFee,
    },
    productId: parseInt(values.productId),
    salesRep: values.salesRep,
    orderNumber: values.orderNumber,
    lpCustomerId: customer?.lpCustomerId,
    customerId: customer?.customerId,
    applicationId: app.applicationId,
    dealerId: stores.store?.dealerId,
    sourceCompanyId: stores.store?.sourceCompanyId,
    deferredInterestPeriod: values.deferredInterestPeriod,
    deferredPaymentPeriod: values.deferredPaymentPeriod,
    merchantDiscountRate: values.merchantDiscountRate,
    vertical: stores.store?.vertical,
    debtFacility: app.debtFacility,
  };
};
