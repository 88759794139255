/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { FormWrapper } from 'components/shared';
import './customer-id-program.scss';

const CustomerIdProgram = () => {
  return (
    <div className={'customer-id-program'}>
      <FormWrapper>
        <h2>Customer Identification Program Disclosure</h2>
        <h6>
          Important Information About Procedures for Opening a New Account
        </h6>
        <p>
          To help the government fight the funding of terrorism and money
          laundering activities, Federal law requires all financial institutions
          to obtain, verify, and record information that identifies each person
          who opens an account.
          <br />
          <br />
          What this means for you: When you open an account, we will ask for
          your name, address, date of birth, and other information that will
          allow us to identify you. We may also ask to see your driver's license
          or other identifying documents.
        </p>
      </FormWrapper>
    </div>
  );
};

export default CustomerIdProgram;
