/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { WHITELIST_PATHNAMES } from './utils/constants';
import { Footer, Header, Modal } from './components/shared';
import './App.scss';
import { store, typedActions, typedState } from './store';
import { AuthStatus, OOBA, Role } from './store/models/auth';
import Routes from './components/routes';
import { isMobile } from 'react-device-detect';
import qs from 'querystring';
import { Queries } from 'types/queries';
import { useHistory } from 'react-router-dom';
import { setupSession } from './utils/threat-metrix';
import { setupSocureSession } from './utils/socure';
import { identify, track } from './utils/segment';
import { EligibilityResultType } from 'store/models/flowType';

declare global {
  interface Window {
    analytics: any;
  }
}

const App = () => {
  const history = useHistory();

  const auth = typedState((state) => state.auth);
  const cfg = typedState((state) => state.configs);
  const customer = typedState((state) => state.customers);

  const {
    authenticate,
    getAndSetSocureDeviceSessionId,
    setIsMobile,
    setTmxSessionId,
  } = typedActions((actions) => actions.auth);
  const { fetchConfigs } = typedActions((actions) => actions.configs);
  const { transitionUnifiedUI } = typedActions(
    (actions) => actions.applications,
  );

  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  // #1 Check the device type and authenticate
  useEffect(() => {
    isMobile ? setIsMobile(true) : setIsMobile(false);

    const useWcfAuth = window.location.origin.includes('westcreekfin');
    authenticate({
      useWcfAuth,
      queryParams: queries,
    });
  }, []);

  // land from unified app
  useEffect(() => {
    if (auth.authStatus === AuthStatus.Authenticated && auth.unifiedToken) {
      const actions = store.getActions();
      // trigger useExistingApplicantInformation hook
      if (auth.unifiedToken?.applicationId) {
        actions.applications.setApplicationId(auth.unifiedToken.applicationId);
      }

      // trigger useCustomerInfo hook
      if (auth.unifiedToken?.phoneNumber) {
        actions.auth.setVerifiedPhone(auth.unifiedToken.phoneNumber);
      }

      // just in case, not really used for anything
      actions.flowType.setEligibilityResult(
        EligibilityResultType.GenerallyEligible,
      );
    }
  }, [auth.authStatus, auth.unifiedToken]);

  // after the get custoemr by id resolves, identify them
  useEffect(() => {
    if (auth.unifiedToken?.customerId && customer.customer)
      identify(customer.customer);
  }, [customer]);

  // #2 update the background color for mobile devices
  useEffect(() => {
    if (isMobile) {
      document
        .querySelector('body')!
        .classList.add('background-body-mobile-koalafi-theme');
    }
  }, [isMobile]);

  // #3 Fetch configs
  useEffect(() => {
    // wait for authorization to finish creating axios client
    auth.authStatus !== AuthStatus.Loading && !cfg.loaded && fetchConfigs();
  }, [cfg.loaded, auth.authStatus]);

  // #4 Set up device session IDs (threatmetrix & socure) and application source
  useEffect(() => {
    let device;
    switch (auth.role) {
      case Role.Customer:
        getAndSetSocureDeviceSessionId(setupSocureSession);
        setTmxSessionId(setupSession());
        device = 'customer';
        break;
      case Role.Dealer:
        getAndSetSocureDeviceSessionId(null);
        setTmxSessionId(null);
        device = 'dealer';
        break;
      case Role.Admin:
      case Role.CSR:
      case Role.DealerSupportRep:
        getAndSetSocureDeviceSessionId(null);
        setTmxSessionId(null);
        device = 'koalafi employee';
        break;
      default:
    }
    // Fire segment event only if we successfully identified user
    if (device) {
      track('New Session Initiated', {
        additionalProperties: {
          device: device,
          // only add userName attribute if set
          ...(auth.userName && { userName: auth.userName }),
          ...(auth.campaignMedium && { campaignMedium: auth.campaignMedium }),
        },
      });
    }
  }, [auth.role]);

  // track ooba link opened
  useEffect(() => {
    if (auth.source === OOBA) {
      if (
        auth.authStatus === AuthStatus.Authenticated ||
        auth.authStatus === AuthStatus.Failed
      ) {
        track('OOBA Opened', {
          additionalProperties: {
            tokenVerified:
              auth.authStatus === AuthStatus.Authenticated ? 'true' : 'false',
            openedFrom: queries.cm,
          },
        });
      }
    }
  }, [auth.source, auth.authStatus]);

  if (
    (auth.authStatus === AuthStatus.Failed ||
      auth.authStatus === AuthStatus.Unauthenticated) &&
    !WHITELIST_PATHNAMES.includes(window.location.pathname)
  ) {
    let msg = '';
    let dismissBtnName = undefined;
    let dismissAction = undefined;

    // TODO: test that these being mutually exclusive is fine
    if (queries.ttaToken) {
      msg =
        'Authentication failed. Please ask the store for a new link to apply.';
    } else if (queries.oobaToken) {
      msg =
        'This link is no longer valid. Please contact the store to resend a link to continue.';
    } else if (queries.unifiedToken) {
      msg = 'Authentication expired, please click continue to reauthenicate.';
      dismissBtnName = 'Continue';
      dismissAction = () => {
        // read URL to extract app and dealer IDs
        const { dealerId: dealerPublicId } = queries;
        transitionUnifiedUI(dealerPublicId);
      };
    } else {
      msg =
        'Authentication failed. Please log in through the dealer portal and try again.';
      dismissBtnName = 'Dealer Portal';
      dismissAction = () =>
        (window.location.href = `https://${auth.dealerPortalUrl}`);
    }

    return (
      <Modal
        isOpen={true}
        message={msg}
        dismissBtnName={dismissBtnName}
        dismissAction={dismissAction}
      />
    );
  }

  if (auth.authStatus !== AuthStatus.Loading && cfg.loaded) {
    return (
      <div style={{ height: '100%' }}>
        <Header />
        <div className="main">
          <Routes />
        </div>
        <Footer />
      </div>
    );
  }

  return null;
};

export default App;
