import React from 'react';
import ReactModal from 'react-modal';
import './image-modal.scss';

interface IProps {
  title?: string;
  isOpen: boolean;
  sourceImage: string;
  hoverText: string;
  dismissAction?: () => void;
}

const ImageModal = (props: IProps) => {
  const { title, isOpen, sourceImage, hoverText, dismissAction } = props;

  return (
    <ReactModal
      className={'koalafi-modal'}
      closeTimeoutMS={500}
      isOpen={isOpen}
      role="dialog"
      onRequestClose={dismissAction}
      ariaHideApp={false}
    >
      {title ? <h2>{title}</h2> : null}
      <div className="image-group">
        <img src={sourceImage} alt={hoverText} />
      </div>
    </ReactModal>
  );
};

export default ImageModal;
