export const JWT_COOKIE_SUFFIX = '.AspNet.JwtCookies';

export const WHITELIST_PATHNAMES = [
  '/electronic-payments',
  '/electronic-communications',
  '/credit-pull-auth',
  '/chargeafter-credit-pull-auth',
  '/prequalification-auth',
  '/customer-id-program',
  '/terms-and-conditions',
];
