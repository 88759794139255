import { store } from '../../store';

/**
 * validateEmail calls ValidateEmail api and returns verification response
 * @param email
 */
const validateEmail = async (
  email: string,
): Promise<{ passedValidation: boolean; reason?: string }> => {
  try {
    const res = await store.getState().auth.client.post('/email', { email });
    return res.data;
  } catch (e) {
    // @ts-ignore-line
    throw new Error(e.response?.data.errorCode as string | undefined);
  }
};

/**
 * processEmailValidation calls Input Validation Service APIs to validate email content and domain.
 * The helper sets "email" field error in case of invalid email
 * @param setFieldError
 * @param email
 */
const processEmailValidation = async (
  setFieldError: (field: string, message: string | undefined) => void,
  email: string,
): Promise<boolean> => {
  return await validateEmail(email)
    .then((response) => {
      if (response.passedValidation) {
        return true;
      } else {
        setFieldError('email', 'Please enter a valid Email');
        return false;
      }
    })
    .catch((e) => {
      // returning true because Formik Schema Validation has already tested the email against RegEx
      return true;
    });
};

export { processEmailValidation };
