/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Redirect } from 'react-router-dom';
import { FormWrapper, ImageModal, Modal } from 'components/shared';
import { IBankInformation, IBankInformationSchema } from 'utils/form-schemas';
import { Button, Colors, TextField } from '@westcreek/react';
import { FadeLoader } from 'react-spinners';
import './banking-information.scss';
import { store, typedState } from 'store';
import { handleApplicationSubmit } from 'hooks/submit/handle-application-submit';
import { CustomerDetails } from 'types/customers';
import { IconType } from 'components/shared/modal';
import { verifyRoutingNumber } from './helpers/helper';
import { useHistory } from 'react-router';
import { Queries } from 'types/queries';
import qs from 'querystring';
import { track } from '../../../utils/segment';
import { capitalCase } from 'change-case';
import sampleCheckImage from '../../../assets/images/sample-check.jpg';
import { DeclineReason } from '../../../types/decision';

const BankInformation = () => {
  const history = useHistory();
  const customer = typedState((state) => state.customers);
  const applications = typedState((state) => state.applications);

  const [transition, setTransition] = useState<
    | 'declined'
    | 'approved'
    | 'ineligible'
    | 'bank-information'
    | 'waterfall'
    | 'pending-homeownership'
    | undefined // 'bank-information' is here to comply with the ISubmitProp type
  >(undefined);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [softDeclineWarning, setSoftDeclineWarning] = useState(false);
  const [tooltipModalOpen, setTooltipModalOpen] = useState(false);

  const queries = qs.parse(history.location.search.slice(1)) as Queries;

  const formik = useRef<FormikProps<IBankInformation>>(null);

  // send tracking segment event on initial load
  useEffect(() => {
    track('Dynamic App - BAV Loaded', {});
  }, []);

  // render soft decline message on errors_found
  useEffect(() => {
    if (formik && applications.decisionReason && !softDeclineWarning) {
      switch (applications.decisionReason) {
        case DeclineReason.IDVSoftDecline:
          setSoftDeclineWarning(true);
          break;
        case DeclineReason.BAVSoftDeclineSavings:
          formik.current!.setFieldError(
            'accountNumber',
            "We're sorry, but the bank account you entered appears to be a savings account. Please revise your checking account information.",
          );
          setSoftDeclineWarning(true);
          break;
        case DeclineReason.BAVSoftDeclineInvalid:
          setSoftDeclineWarning(true);
          formik.current!.setFieldError(
            'accountNumber',
            "We're sorry, but we were unable to validate your bank account. Please revise your banking information and try again.",
          );
          break;
      }
    }
  }, [applications.applicationStatus, softDeclineWarning]);

  useEffect(() => {
    if (transition) {
      track('Dynamic App - BAV Decisioned', {
        additionalProperties: {
          decision: capitalCase(transition),
        },
      });

      switch (transition) {
        case 'approved':
          history.replace(`/approved?${qs.stringify(queries)}`);
          break;
        case 'declined':
          history.replace(`/declined?${qs.stringify(queries)}`);
          break;
        case 'waterfall':
          /* We are not handling "waterfall" transition here since it's happening
          in background on "processWaterfall" dispatch*/
          break;
        case 'pending-homeownership':
          history.replace(`/pending-homeownership?${qs.stringify(queries)}`);
          break;
        default:
          console.error(`unexpected BAV transition type ${transition}`);
          setErrorModalOpen(true);
          break;
      }
    }
  }, [transition]);

  // Cannot continue processing if customer data is missing
  if (!customer.customer) {
    return <Redirect to={`/?${qs.stringify(queries)}`} />;
  }

  return (
    <FormWrapper className={'bank-information'}>
      <Modal
        isOpen={errorModalOpen}
        message="Something went wrong."
        dismissBtnName="OK"
        dismissAction={() => setErrorModalOpen(false)}
        iconType={IconType.Warning}
      />
      <ImageModal
        isOpen={tooltipModalOpen}
        sourceImage={sampleCheckImage}
        hoverText="Sample Check"
        dismissAction={() => setTooltipModalOpen(false)}
      />
      <h2>One Last Step: Bank Details</h2>
      <h3>
        We only use this information to verify that you have a valid bank
        account
      </h3>
      <Formik<IBankInformation>
        innerRef={formik}
        initialValues={
          {
            routingNumber: '',
            accountNumber: '',
            bankInstitution: '',
          } as IBankInformation
        }
        validationSchema={IBankInformationSchema}
        onSubmit={async (
          values: IBankInformation,
          actions: FormikHelpers<IBankInformation>,
        ) => {
          const newCustomer = {
            ...customer.customer,
            ssn:
              // resumed app: ssn will not be populated
              // if there's a tokenizedTaxId and no ssn, use this dummy value to pass validation
              // ssn will be fetched from tokenizedTaxId
              customer.customer?.tokenizedTaxId && !customer.customer?.ssn
                ? '000-00-0000'
                : customer.customer?.ssn,
            accountNumber: values.accountNumber,
            routingNumber: values.routingNumber,
          } as CustomerDetails;

          await handleApplicationSubmit({
            customer: newCustomer,
            setSubmitting: actions.setSubmitting,
            setFieldValue: actions.setFieldValue,
            setFieldError: actions.setFieldError,
            setErrorModalOpen,
            setSoftDeclineWarning,
            setTransition,
            globalState: store.getState(),
            globalActions: store.getActions(),
          });
        }}
      >
        {({ isSubmitting, values, errors, setValues }) => {
          return (
            <Form noValidate>
              <Field
                className="fs-exclude text-field"
                id="bankInformation--routingNumber"
                name="routingNumber"
                component={TextField}
                label="Routing Number"
                autoFocus={true}
                cleaveOptions={{
                  blocks: [9],
                  numericOnly: true,
                }}
                helper={() => {
                  track('Dynamic App - BAV Tooltip Opened', {});

                  setTooltipModalOpen(true);
                }}
                onBlur={() => {
                  verifyRoutingNumber(
                    values,
                    setValues,
                    errors,
                    store.getState(),
                  );
                }}
              />
              <p id="bank-information--sub-header-name" className="intro">
                {softDeclineWarning && (
                  <span className={`warning`}>
                    It looks like some of the banking information submitted may
                    be incorrect. Please double-check each field, re-enter the
                    Account Number, and resubmit.
                  </span>
                )}
              </p>
              <Field
                className="fs-exclude text-field"
                id="bankInformation--accountNumber"
                name="accountNumber"
                type="tel"
                component={TextField}
                mask={true}
                label="Account Number"
                cleaveOptions={{
                  blocks: [20],
                }}
                helper={() => {
                  track('Dynamic App - BAV Tooltip Opened', {});

                  setTooltipModalOpen(true);
                }}
              />
              <Field
                className="text-field"
                id="bankInformation--bankInstitution"
                name="bankInstitution"
                component={TextField}
                label="Banking Institution"
                readOnly={true}
                disabled={true}
                cleaveOptions={{ blocks: [30], delimiter: '' }}
              />
              <Button
                id="personal-details--submit"
                type="submit"
                disabled={
                  isSubmitting ||
                  applications.processWaterfall ||
                  transition === 'waterfall'
                }
                color={Colors.royalBlue}
              >
                {isSubmitting ||
                applications.processWaterfall ||
                transition === 'waterfall' ? (
                  <FadeLoader color={'#0D4066'} width={4} />
                ) : (
                  'Next Step'
                )}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};

export default BankInformation;
