import React from 'react';
import './proceed-with-dealer.scss';
import { ReactComponent as SuccessIcon } from 'assets/icons/checkmark.svg';

import { FormWrapper, Hero } from 'components/shared';

const Ineligible = () => {
  return (
    <>
      <Hero />
      <FormWrapper>
        <div className="proceed-with-dealer--container">
          <div className="proceed-with-dealer--icon">
            <SuccessIcon />
          </div>
          <h3>You can now proceed with your financed purchase!</h3>
          <p>Your sales rep will walk you through the next steps.</p>
        </div>
      </FormWrapper>
    </>
  );
};

export default Ineligible;
