import React from 'react';
import ReactModal from 'react-modal';
import './modal.scss';
import { ReactComponent as SuccessIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as DangerIcon } from 'assets/icons/declined-red.svg';
import { Button } from '@westcreek/react';
import { ReactComponent as KoalafiWarning } from 'assets/icons/warning-koalafi.svg';

interface IProps {
  title?: string;
  message: string;
  isOpen: boolean;
  iconType?: IconType;
  actionBtnName?: string;
  dismissBtnName?: string;
  shouldCloseOnOverlayClick?: boolean;
  action?: () => void;
  dismissAction?: () => void;
}

export enum IconType {
  Warning,
  Success,
  Danger,
}

const Modal = (props: IProps) => {
  const {
    dismissBtnName,
    actionBtnName,
    title,
    message,
    isOpen,
    action,
    dismissAction,
    iconType,
    shouldCloseOnOverlayClick,
  } = props;

  let icon;
  switch (iconType) {
    case IconType.Warning:
      icon = <KoalafiWarning />;
      break;
    case IconType.Success:
      icon = <SuccessIcon />;
      break;
    case IconType.Danger:
      icon = <DangerIcon />;
      break;
    default:
      icon = null;
      break;
  }

  return (
    <ReactModal
      className={'koalafi-modal'}
      closeTimeoutMS={500}
      isOpen={isOpen}
      role="dialog"
      onRequestClose={dismissAction}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      ariaHideApp={false}
    >
      {icon}
      {title ? <h2>{title}</h2> : null}
      <p>{message}</p>
      <div className="btn-group">
        {dismissBtnName ? (
          <Button
            id={`modal--dismissAction${!actionBtnName ? '-single-button' : ''}`}
            type="button"
            onClick={dismissAction}
            size="small"
          >
            {dismissBtnName}
          </Button>
        ) : null}
        {actionBtnName ? (
          <Button
            id="modal--affirmAction"
            type="button"
            onClick={action}
            size="small"
          >
            {actionBtnName}
          </Button>
        ) : null}
      </div>
    </ReactModal>
  );
};

export default Modal;
