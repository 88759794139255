import { Action, action, Thunk, thunk } from 'easy-peasy';
import { store } from '../index';

interface Config {
  phoneVerificationEnabled?: boolean;
  otpServiceEnabled?: boolean;
}

export interface ConfigModel {
  configs?: Config;
  loaded: boolean;

  setConfigs: Action<ConfigModel, Config>;
  setLoaded: Action<ConfigModel, boolean>;

  fetchConfigs: Thunk<ConfigModel>;
}

const fetchConfigs = thunk<ConfigModel>(async (actions) => {
  const axiosClient = store.getState().auth.client;
  axiosClient
    .get('/features')
    .then((response) => {
      actions.setConfigs({ ...response.data });
      actions.setLoaded(true);
    })
    .catch((error) => {
      console.error(error);
    });
});

export const initConfigModel = (): ConfigModel => ({
  configs: undefined,
  loaded: false,

  // actions
  setLoaded: action((state, loaded) => {
    state.loaded = loaded;
  }),
  setConfigs: action((state, configs) => {
    state.configs = configs;
  }),

  // thunks
  fetchConfigs,
});
